<template>
  <div>
    <input
      type="file"
      @change="change($event)"
      ref="referenceUpload"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    change(e) {
      var file = e.target.files[0];
      this.fileList.push(file);
      this.$refs.referenceUpload.value = null;
    },
  },
};
</script>

<style>
video {
  height: 400px;
  width: 400px;
}
</style>