<template>
  <div class="cva">
    <Tips />
    <div class="mask">
      <img class="kf" src="@/assets/img/kefu.png" alt />
      <div class="header">
        <div class="back">
          <img class="pic" src="@/assets/img/mback.png" @click="back" />
        </div>
        我的设备
      </div>
      <img src="@/assets/img/zw.png" class="show" v-show="show" />
      <!-- 选项卡 -->
      <van-tabs
        @click="onClick"
        title-inactive-color="#999999"
        color="#FCCC00"
        line-width="80px"
        class="xxk"
        v-show="isshow"
      >
        <van-tab title="CVA:价值宝" title-style="font-size:14px" class="xxk1">
          <!-- 选项卡1 -->
          <div class="con1" v-for="(item, index) in cva" :key="index">
            <p class="lh25">设备序列号: {{ item.agreementNo__c }}</p>
            <p class="lh25">设备型号：{{ model }}</p>
            <p class="lh40">CVA名称：{{ item.name }}</p>
            <p class="fz12 lh25">
              距离
              <span class="mlr10 fz18 cfc">CVA</span>结束剩余(hr)
            </p>
            <p class="sj">
              {{ num.toFixed(0) > 0 ? num.toFixed(0) : "已过期" }}
            </p>
          </div>
          <img
            src="../../assets/img/zw.png"
            class="ebcg"
            v-show="cva.length == 0 ? true : false"
          />
        </van-tab>
        <van-tab title="EPP：延保" class="xxk1" title-style="font-size:14px">
          <!-- 选项卡2 -->
          <div class="con1" v-for="(item, index) in epp" :key="index">
            <p class="lh25">设备序列号：{{ xlh }}</p>
            <p class="lh25">设备型号：{{ model }}</p>
            <p class="lh25">EPP保修编号：{{ item.name }}</p>
            <p class="lh25">EPP保修类型：{{ item.EPPRepairType__c }}</p>
            <p class="lh25">EPP保修代码：{{ item.EPPRepairCode__c }}</p>
            <p class="fz12 lh25" v-show="a">
              距离
              <span class="mlr10 fz18 cfc">EPP</span>结束剩余(hr)
            </p>
            <p class="sj" v-show="a">{{}}</p>
            <div class="qisr">
              <p>起始日:</p>
              <p class="fz12 c99">
                {{ item.RepairStartDate__c | dateformat("YYYY/MM/DD") }}
              </p>
            </div>
            <div class="qisr mt10">
              <p>截止日期:</p>
              <p class="fz12 c99">
                {{ item.RepairEndDate__c | dateformat("YYYY/MM/DD") }}
              </p>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import axios from "axios";
import Vue from "vue";
import { Tab, Tabs } from "vant";
import { Toast } from "vant";
import { Step, Steps } from "vant";
import { Loading } from "vant";
import Tips from "@/components/Tips.vue";
Vue.use(Loading);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Tab);
Vue.use(Tabs);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      loding: true,
      active: 2,
      img: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:",
      cva: [],
      epp: [],
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", // "http://10.208.120.107:9100",
      model: "",
      num: "",
      show: false,
      isshow: true,
      a: true,
      xlh: "",
    };
  },
  created() {
    this.xlh = this.$route.query.item.serialNumber;
    this.model = this.$route.query.item.model;
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    this.toDay = this.$moment(new Date()).format("YYYY-MM-DD");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.120.107:9100//app/device/catDiagnostic/FJM00503?page=1&pageSize=10&isRead=2" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    //CVA
    axios({
      headers: {
        "Content-Type": "multipart/form-data;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      url: this.reurl + "/app/device/cva/" + this.$route.query.item.serialNumber,
    })
      .then((res) => {
        this.cva = res.data.item;
        this.num =
          this.cva[0].endHours__c - this.$route.query.item.totalServiceMeter;
        if (res.data.errcode == 40000) {
          this.$router.push("/login");
          Toast("登录失效请重新登陆");
        }
      })
      .catch((error) => {});
    //EPP
    axios({
      headers: {
        "Content-Type": "multipart/form-data;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      url: this.reurl + "/app/device/epp/" + this.$route.query.item.serialNumber,
    })
      .then((res) => {
        this.epp = res.data.item;
        if (this.epp[0].workEndHourNumber__c == 0) {
          this.a = false;
        }
      })
      .catch((error) => {
        // error.data);
      });
  },
  methods: {
    //回到我的设备
    back() {
      this.$router.push("/wdsb");
    },
    //选项卡
    onClick(name, title) {},
  },
  updated() {
    this.loding = false;
  },
};
</script>

<style lang="less" scoped>
.ebcg {
  width: 100%;
  height: 100%;
}
.jzz {
  position: absolute;
  left: 50%;
  top: 50%;
}
.cva {
  .lh40 {
    line-height: 40px;
  }
  .mask {
    .show {
      position: absolute;
      width: 80px;
      margin-top: 50px;
      height: 40px;
    }
    .kf {
      position: fixed;
      bottom: 50px;
      right: 20px;
    }
    .xxk {
      .xxk1 {
        .con1 {
          font-size: 12px;
          margin-bottom: 15px;
          .jhcard {
            margin-top: 20px;
            border-radius: 10px;
          }
          .qisr {
            display: flex;
            justify-content: space-between;
            line-height: 25px;
          }
          .sj {
            line-height: 40px;
            font-weight: bold;
            font-size: 20px;
          }
          padding: 10px;
          background: #f5f5f5;
          border-radius: 10px;
        }
        padding: 15px 10px;
      }
      margin-top: 10px;
    }
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    .header {
      background: #fff;
      line-height: 44px;
      font-weight: bold;
      height: 44px;
      width: 100%;
      text-align: center;
      .back {
        position: absolute;
        font-size: 24px;
        color: #fff;
        left: 15px;
        top: 3px;
        .pic {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
  .mt10 {
    margin-top: 10px;
  }
  .c99 {
    color: #999999;
  }
  .fz14 {
    font-size: 14px;
  }
  .fz12 {
    font-size: 12px;
  }
  .fz18 {
    font-size: 18px;
  }
  .fwb {
    font-weight: bold;
  }
  .mlr10 {
    margin: 0 5px;
  }
  .cfc {
    color: #fccc00;
  }
  .lh25 {
    line-height: 30px;
  }
}
</style>