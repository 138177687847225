<template>
  <div id="app">
    <!-- 
*      
*          ┌─┐       ┌─┐
*       ┌──┘ ┴───────┘ ┴──┐
*       │                 │
*       │       ───       │
*       │  ─┬┘       └┬─  │
*       │                 │
*       │       ─┴─       │
*       │                 │
*       └───┐         ┌───┘
*           │         │
*           │         │
*           │         │
*           │         └──────────────┐
*           │                        │
*           │                        ├─┐
*           │                        ┌─┘    
*           │                        │
*           └─┐  ┐  ┌───────┬──┐  ┌──┘         
*             │ ─┤ ─┤       │ ─┤ ─┤         
*             └──┴──┘       └──┴──┘ 
*                 神兽保佑 
*                 代码无BUG! 

    -->
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      num: 0,
    };
  },
  created() {
    //设置版本号
    //改写物理按键
    var quitTime = null;
    mui.plusReady(function () {
      mui.back = function () {
        //首次按键，提示“再按次退出应用’
        var ac = plus.webview.getWebviewById("shop");
        plus.webview.close(ac);
        var a = plus.webview.getWebviewById("h5");
        plus.webview.close(a);
        if (!quitTime) {
          //记录第次按下回退键的时间
          var ac = plus.webview.getWebviewById("shop");
          plus.webview.close(ac);
          var ab = plus.webview.getWebviewById("shops");
          plus.webview.close(ab);
          var a = plus.webview.getWebviewById("h5");
          plus.webview.close(a);
          var h = plus.webview.getWebviewById("baidu");
          plus.webview.close(h);
          var ad = plus.webview.getWebviewById("md");
          plus.webview.close(ad);
          quitTime = new Date().getTime();
          //回退到上一页面
          window.history.go(-1);
          setTimeout(function () {
            //1s中后清除
            quitTime = null;
          }, 1000);
        } else {
          if (new Date().getTime() - quitTime < 1000) {
            plus.runtime.quit();
            var ac = plus.webview.getWebviewById("shop");
            plus.webview.close(ac);
            var a = plus.webview.getWebviewById("h5");
            plus.webview.close(a);
          }
        }
      };
    });
    var platform = plus.device.vendor;
    plus.push.addEventListener(
      "click",
      function (msgc) {
        //  && msgc.type == 'click'
        if (platform == "Apple") {
          //离线是payload为object , 在线时本地创建的为string(本地创建通知只能传string，否则无法传递payload)
          if (typeof msgc.payload == "string") {
            var paymsg = JSON.parse(msgc.payload);
          } else {
            var paymsg = msgc.payload;
          }
          //执行跳转判断
        } else {
        }
        //这里可以写跳转业务代码
      },
      false
    );
    // 监听在线消息事件
    plus.push.addEventListener(
      "receive",
      function (msg) {
        //这里可以写跳转业务代码
        if (msg.type == "receive") {
          // 这里判断触发的来源，否则一直推送。
          if (platform == "Apple") {
            //  msg.payload)
            plus.push.createMessage(
              msg.payload.content,
              //本地创建通知payload只能传string，否则无法成功传递（为null）
              JSON.stringify(msg.payload),
              {
                cover: false,
                title: msg.title,
              }
            );
          } else {
          }
        }
        // if (platform == 'android') {
        // 	//执行跳转判断
        // }
      },
      false
    );
    // plus.push.addEventListener(
    //   "click",
    //   function (msg) {
    //     //  && msgc.type == 'click'
    //     if (platform == "Apple") {
    //     }
    //   },
    //   false
    // );
    //监听在线消息事件
    // plus.push.addEventListener(
    //   "receive",
    //   function (msg) {
    //     //这里可以写跳转业务代码
    //     if (msg.type == "receive") {
    //       // 这里判断触发的来源，否则一直推送。
    //       if (platform == "Apple") {
    //         alert(1);
    //         plus.push.createMessage(
    //           JSON.stringify(msg.payload.content),
    //           "",
    //           ""
    //         );
    //       }else{
    //         alert(2);
    //       }
    //     }
    //   },
    //   false
    // );
  },
};
</script>

<style lang="less" scoped>
#app {
  height: 100%;
  font-family: "syht";
  //font-family:"Source Han Sans CN";
  // ,Georgia
}
</style>