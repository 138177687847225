<template>
  <div class="sblist">
    <Tips />
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      设备详情
    </div>
    <div class="content">
      <!-- 设备详情 -->
      <div class="name">
        <div class="tit">
          <img :src="pic" class="tleft" />
          <div class="tright">
            <div class="fz18 fwber">{{ sbName }}</div>
            <div class="fz12 c99">
              型号: {{ wdsb.model }}｜设备号：{{ wdsb.serialNumber }}
            </div>
            <div class="bx fz14 fwber">
              <div style="width:100%;text-align:center" @click="bx">一键报修</div>
              <!-- <div @click="by">一键保养</div> -->
            </div>
          </div>
        </div>
        <div class="box mt20">
          <div class="fwber">购买日期</div>
          <div class="c99 fz14">{{ wdsb.purchaseTime }}</div>
        </div>
        <div class="box">
          <div class="fwber">累计运行时间</div>
          <div class="c99 fz14">{{ wdsb.totalServiceMeter }} hrs</div>
        </div>
        <div class="box">
          <div class="fwber">平均油耗</div>
          <!-- .toFixed(2) -->
          <div class="c99 fz14">
            {{
              Math.floor(
                (wdsb.totalOilConsumption / wdsb.totalServiceMeter) * 100
              ) / 100
            }}
            L/hr
          </div>
        </div>
      </div>
      <!-- 故障提示 -->
      <div class="gzts" v-show="isshow">
        <div class="gztop">
          <div class="gzleft fz14 fwber">
            <img src="@/assets/img/js.png" class="gzimg" />
            故障提示
          </div>
          <div class="gzright c99 fz14">3小时间前</div>
        </div>
        <p class="fz14 lh20">
          故障代码ED-1324-SD 设备不明原因于23：18分 ，停止运行。
        </p>
      </div>
      
      <!-- 地址信息 -->
      <div class="jw" v-if="wdsb.longitude!=null">
        <div class="fwb">位置信息</div>
        <p id="md" to="Md" @click="go(wdsb)" class="locationAddress">
          <img src="../../assets/img/nav.png" alt="" />
          {{ wdsb.locationAddress }}
        </p>
      </div>
      <!-- 设备运行信息 -->
      <div class="yx">
        <div class="tit">
          <div class="fwber">设备运行信息</div>
        </div>
        <div style="margin: 10px; font-size: 12px">{{ timeSlot }}</div>
        <!-- 日历 -->
        <van-cell
          title="选择日期"
          :value="date"
          @click="show = true"
          style="display: none"
        />
        <div
          style="text-align: center; font-size: 14px; color: rgb(255, 205, 17)"
          @click="show = true"
        >
          <img
            style="margin-right: 10px; vertical-align: middle"
            src="../../assets/img/ll.png"
            alt=""
          />
          <span v-show="num == 0">{{ toDay }}</span>
          <span v-show="num == 1">{{ toDay2 }}</span>
          <img
            style="margin-right: 10px; vertical-align: middle; height: 18px"
            src="@/assets/img/down.png"
            alt=""
          />
        </div>
        <van-calendar
          v-model="show"
          :min-date="minDate"
          :max-date="maxDate"
          :max-range="1"
          color="rgb(255, 205, 17) "
          @confirm="onConfirm"
          :allow-same-day="true"
        />
        <div class="con">
          <div class="cleft">
            <h3 class="fz14">运行时间</h3>
            <p class="fwber fz20">
              {{ xxxx.runtimeHours == null ? "-" : xxxx.runtimeHours }}hrs
            </p>
          </div>
          <div class="cleft">
            <p class="fz14">运行油耗</p>
            <h2 class="fwber">
              {{xxxx.runtimeFuelBurnedGallons != null ?
                Math.floor(
                  (xxxx.runtimeFuelBurnedGallons / xxxx.runtimeHours) * 100
                ) / 100 :'-'
              }}L/hr
            </h2>
          </div>
        </div>
        <div class="con">
          <div class="cleft">
            <h3 class="fz14">待机时间</h3>
            <p class="fwber fz20">
              {{ xxxx.idleHours != null ? xxxx.idleHours : "-" }}hrs
            </p>
          </div>
          <div class="cleft">
            <p class="fz14">待机油耗</p>
            <h2 class="fwber">
              {{xxxx.idleFuelBurnedGallons != null ?
                Math.floor(
                  (xxxx.idleFuelBurnedGallons / xxxx.idleHours) * 100
                ) / 100 :'-'
              }}L/hr
            </h2>
          </div>
        </div>
        <div class="con">
          <div class="cleft">
            <h3 class="fz14">工作时间</h3>
            <p class="fwber fz20">
              {{ xxxx.workingHours != null ? xxxx.workingHours : "-" }}hrs
            </p>
          </div>
          <div class="cleft">
            <p class="fz14">工作油耗</p>
            <h2 class="fwber">
              {{xxxx.workingFuelBurnedGallons != null ?
                Math.floor(
                  (xxxx.workingFuelBurnedGallons / xxxx.workingHours) * 100
                ) / 100 :'-'
              }}L/hr
            </h2>
          </div>
        </div>
      </div>
    </div>
    <img class="kf" src="@/assets/img/kefu.png" @click="kf" />
    <div class="newmask" v-if="newmask">
      <van-loading type="spinner" color="rgb(255, 205, 17)" class="mmaskpic" />
    </div>
  </div>
</template>

<script>
import tx from "@/assets/img/cs.jpg";
import md5 from "js-md5";
import axios from "axios";
import Vue from "vue";
import { Slider } from "vant";
import { Calendar } from "vant";
import { Toast } from "vant";
import Tips from "@/components/Tips.vue";
import moment from "moment";
Vue.use(Toast);
Vue.use(Calendar);
Vue.use(Slider);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      newmask: true,
      xxxx: "",
      timeSlot: "",
      num: 0,
      toDay2: "",
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2021, 0, 21),
      isshow: false,
      date: "",
      show: false,
      sbName: "通用设备",
      value: 0,
      wdsb: "",
      pic: tx,
      toDay: "",
      runtimeHours: "NaN",
      runtimeFuelBurnedGallons: "NaN",
      workingHours: "NaN",
      workingFuelBurnedGallons: "NaN",
      idleHours: "NaN",
      idleFuelBurnedGallons: "NaN",
    };
  },
  methods: {
    //导航
    go(e) {
      let jwd=e.longitude+','+e.latitude
      localStorage.setItem("jwd", jwd);
      localStorage.setItem("dz", e.locationAddress); 
       this.$router.push("/Md");
      
    },
    //日历
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);

      let startDate = moment(date).format("YYYY-MM-DD");
      let startDate1 = "";
      this.toDay2 = startDate;
      this.num = 1;
      this.wdsb = JSON.parse(localStorage.getItem("wdsb"));

      if (this.wdsb.name !== null) {
        this.sbName =
          (this.wdsb.makeCode != "null" ? "" : this.wdsb.makeCode) +
          this.wdsb.model +
          this.wdsb.name;
      }
      if (this.wdsb.imgUrl != null) {
        this.pic = this.picurl + this.wdsb.imgUrl;
      }
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      this.toDay = this.$moment(new Date()).format("YYYY-MM-DD");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100//app/device/catDiagnostic/FJM00503?page=1&pageSize=10&isRead=2" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "multipart/form-data;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        url:
          this.reurl +
          "/app/device/runningInfoOfDay/" +
          this.wdsb.serialNumber +
          "/" +
          startDate,
      }).then((res) => {
        if (res.data.item.length == 0) {
          Toast("查询期间暂无数据");
        } else {
          this.timeSlot = res.data.item.timeSlot;
          const item = res.data.item;
          this.xxxx = res.data.item;
          res.data.item.idleFuelBurnedGallons == null
            ? (this.idleFuelBurnedGallons = "NaN")
            : (this.idleFuelBurnedGallons =
                res.data.item.idleFuelBurnedGallons);
        }
      });
    },
    //返回上一级
    back() {
      this.$router.push("/wdsb");
    },
    //选择日期
    mon() {},
    //客服
    kf() {
      this.$router.push("/cssc");
    },
    //一件报修
    bx() {
     this.$router.push("/yjbx");
       //Toast("系统连接中，即将开放")
    },
    //保养
    by() {
      // this.$router.push("/yjby");
      // Toast("系统连接中，即将开放")
    },
  },
  updated() {},
  created() {
    var day1 = new Date();
    var s2 = day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000); //减去一天的秒数
    this.maxDate = new Date(
      day1.getFullYear(),
      new Date().getMonth(),
      day1.getDate()
    );
    var s1 =
      day1.getFullYear() + "-" + (day1.getMonth() + 1) + "-" + day1.getDate();
    this.toDay = s1;
    //上个页面发送过来的数据
    let td = moment(new Date()).format("YYYY-MM-DD");

    this.wdsb = JSON.parse(localStorage.getItem("wdsb"));
    if (this.wdsb.name !== null) {
      this.sbName =
        (this.wdsb.makeCode != null ? this.wdsb.makeCode : "") +
        this.wdsb.model +
        this.wdsb.name;
    }
    if (this.wdsb.imgUrl != null) {
      this.pic = this.picurl + this.wdsb.imgUrl;
    }
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    //this.toDay = this.$moment(new Date()).format("YYYY-MM-DD");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.120.107:9100//app/device/catDiagnostic/FJM00503?page=1&pageSize=10&isRead=2" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    //获取故障提示接口
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url:
        this.reurl +
        "/app/device/catDiagnostic/FJM00503?page=1&pageSize=10&isRead=2",
    })
      .then((res) => {
        if (res.data.errcode == 40000) {
          localStorage.clear();
          Toast("登录失效请重新登陆");
          this.$router.push("/login");
        }
        this.data = res.data.item.data;
      })
      .catch((error) => {});

    //获取设备运行信息接口
    axios({
      headers: {
        "Content-Type": "multipart/form-data;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      url:
        this.reurl +
        "/app/device/runningInfoOfDay/" +
        this.wdsb.serialNumber +
        "/" +
        s1,
    })
      .then((res) => {
        this.newmask = false;
        this.timeSlot = res.data.item.timeSlot;
        this.xxxx = res.data.item;
      })
      .catch((error) => {});
  },
};
</script>
<style lang="less" scoped>
#md{
  color: #000;
}
.fwb {
  font-weight: bold;
}
.jw {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff;
  margin-top: 10px;
  padding: 10px;
  .locationAddress {
    font-size: 12px;
    margin-top: 10px;
    line-height: 18px;
  }
}
.newmask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .mmaskpic {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -90%);
  }
}
.cleft {
  text-align: center;
  width: 130px;
  padding: 5px 10px;
  border-radius: 10px;
  background: #fff6ce;
  line-height: 30px;
}
.sblist {
  .ril {
    height: 50px;
    width: 100%;
  }
  .bottom {
    text-align: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .lb {
      line-height: 20px;
      padding: 10px 0;
      background: #fff6ce;
      border-radius: 10px;
      box-sizing: border-box;
      width: 160px;
      .zj {
        margin: 0 10px;
      }
    }
  }
  .kf {
    position: fixed;
    bottom: 50px;
    right: 20px;
  }
  .yx {
    .con {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      flex-wrap: wrap;
    }
    .tit {
      line-height: 20px;
      .jimg {
        vertical-align: bottom;
      }
      display: flex;
      justify-content: space-between;
    }
    margin-top: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
  }
  .gzts {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    .gztop {
      line-height: 22px;
      .gzleft {
        .gzimg {
          width: 22px;
          height: 22px;
          vertical-align: top;
          margin-right: 5px;
        }
      }
      display: flex;
      justify-content: space-between;
    }
  }
  .lh20 {
    line-height: 20px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .c99 {
    color: #999999;
  }
  .fz12 {
    font-size: 12px;
  }
  .fz14 {
    font-size: 14px;
  }
  .fwber {
    font-weight: bolder;
  }
  .fz20 {
    font-size: 20px;
  }
  .fz18 {
    font-size: 18px;
  }
  .content {
    .box {
      display: flex;
      justify-content: space-between;
      margin: 40px 0 10px 0;
    }
    .name {
      border-radius: 10px;
      padding: 10px 20px;
      background: #fff;
      .tit {
        .tright {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .bx {
            div {
              background: rgb(255, 205, 17);
              padding: 10px 15px;
              border-radius: 10px;
            }
            display: flex;
            justify-content: space-between;
          }
        }
        .tleft {
          width: 90px;
          height: 90px;
        }
        display: flex;
        justify-content: space-between;
      }
    }
    background: #f5f5f5;
    padding: 10px;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  .mt20 {
    margin-top: 20px;
  }
  height: 100%;
  background: #f5f5f5;
}
</style>