<template>
  <div>
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" id="close_page" />
      </div>
      <h1 id="close_page"  style="font-weight:bold">在线商城</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.create();
    this.setStorage();
  },
  methods: {
    create() {
      //创建窗口对象
      var shop = plus.webview.create(
        "https://100001527049.retail.n.weimob.com/saas/retail/100001527049/0/shop/index/vwif?storeId=2507353049&appClientId=F485C8EB319A1D768A5212A3DEE30ADA&appTicket=" +
          localStorage.getItem("sxsc"),
        "shop",
        {
          top: "44px",
          bottom: 0,
        }
      );
      //显示窗口对象
      shop.show();
    },
    back() {
      // this.$router.push("/index");
      //获取id对象窗口页面并销毁
      var ac = plus.webview.getWebviewById("shop");
      plus.webview.close(ac);
      //销毁获取到的窗口对象
      setTimeout(() => {
        this.$router.push("/index");
      }, 500);
      //获取所有窗口页面并且给进行销毁处理
    },
    setStorage() {
      plus.storage.setItem("stoken", localStorage.getItem("token"));
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 20px;
  background: #fff;
  line-height: 44px;
  font-weight: 500;
  height: 44px;
  width: 100%;
  text-align: center;
  .back {
    position: absolute;
    font-size: 24px;
    color: #fff;
    left: 15px;
    top: 3px;
    .pic {
      height: 22px;
      width: 22px;
    }
  }
}
</style>