import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
//import Register from '@/views/Register.vue'
import Code from '@/views/Code.vue'
import Index from '@/views/Index.vue'
import Kefu from '@/views/Kefu.vue'
import Ysxy from '@/views/Ysxy.vue'
import Search from '@/views/Search.vue'
import Start from '@/views/Start.vue'
import Zxzx from '@/views/Zxzx.vue'
import Zxxq from '@/views/Zxxq.vue'
import Zxq from '@/views/Zxq.vue'
import Zxqq from '@/views/Zxqq.vue'
import Zxh from '@/views/Zxh.vue'
import Ddzx from '@/views/Ddzx.vue'
import Banner from '@/views/Banner.vue'
import Zskf from '@/views/Zskf.vue'
import Xges from '@/views/Xges.vue'
import Wjzl from '@/views/Wjzl.vue'
import Rmxj from '@/views/Rmxj.vue'
import Vx from '@/views/Vx.vue'
//测试商城
import Cssc from '@/views/Cssc.vue'
//我的设备子文件夹
import Wdsb from '@/views/wdsb/Wdsb.vue'
import Sblist from '@/views/wdsb/Sblist.vue'
import Yjbx from '@/views/wdsb/Yjbx.vue'
import Yjby from '@/views/wdsb/Yjby.vue'
import Tjcg from '@/views/wdsb/Tjcg.vue'
import Yybg from '@/views/wdsb/Yybg.vue'
import Bxjd from '@/views/wdsb/Bxjd.vue'
import Cva from '@/views/wdsb/Cva.vue'
import Yjtx from '@/views/wdsb/Yjtx.vue'
//服务进度子文件夹
import Jdcx from '@/views/fwjd/Jdcx.vue'
import Byjd from '@/views/fwjd/Byjd.vue'
import Fwpj from '@/views/fwjd/Fwpj.vue'
//我的子文件夹
import My from '@/views/my/My.vue'
import Xgzl from '@/views/my/Xgzl.vue'
import Wddy from '@/views/my/Wddy.vue'
import Txxg from '@/views/my/Txxg.vue'
import Nchen from '@/views/my/Nchen.vue'
import Glhm from '@/views/my/Glhm.vue'
import Gxsz from '@/views/my/Gxsz.vue'
//维修视频
import Wxsp from '@/views/wxsp/Wxsp.vue'
import Look from '@/views/wxsp/Look.vue'
import Spxq from '@/views/wxsp/Spxq.vue'
//信贷中心
import Xdzx from '@/views/xdzx/Xdzx.vue'
import Lszd from '@/views/xdzx/Lszd.vue'
//附近门店
import Fjmd from '@/views/fjmd/Fjmd.vue'
import Dhy from '@/views/fjmd/Dhy.vue'
import Md from '@/views/fjmd/Md.vue'
//在线商城
import Zxsc from '@/views/zxsc/Zxsc.vue'
//消息中心
import Xxzx from '@/views/xxzx/Xxzx.vue'


Vue.use(VueRouter)


const routes = [
  {
    path:'/',
    redirect:'/index'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },  {
    path: '/bxjd',
    name: 'Bxjd',
    component: Bxjd
  },
    // {
    //   path: '/register',
    //   name: 'Register',
    //   component: Register
    // },
    {
      path: '/code',
      name: 'Code',
      component: Code
    }
    ,{
      path: '/vx',
      name: 'Vx',
      component: Vx
    }
    ,
    {
      path: '/wjzl',
      name: 'Wjzl',
      component: Wjzl
    }
    ,
    {
      path: '/xges',
      name: 'Xges',
      component: Xges
    } ,
    {
      path: '/rmxj',
      name: 'Rmxj',
      component: Rmxj
    }
    ,
    {
      path: '/index',
      name: 'Index',
      component: Index
    },
    {
      path: '/wdsb',
      name: 'Wdsb',
      component: Wdsb
    },
    {
      path: '/sblist',
      name: 'Sblist',
      component: Sblist
    },
    {
      path: '/yjbx',
      name: 'Yjbx',
      component: Yjbx
    },{
      path: '/yjby',
      name: 'Yjby',
      component: Yjby
    },
    {
      path: '/tjcg',
      name: 'Tjcg',
      component: Tjcg
    },
    {
      path: '/yybg',
      name: 'Yybg',
      component: Yybg
    },
    {
      path: '/cva',
      name: 'Cva',
      component: Cva
    }    ,
    {
      path: '/yjtx',
      name: 'Yjtx',
      component: Yjtx
    }    ,
    {
      path: '/jdcx',
      name: 'Jdcx',
      component: Jdcx
    }  ,
    {
      path: '/my',
      name: 'My',
      component: My
    },
    {
      path: '/kefu',
      name: 'Kefu',
      component: Kefu
    },
    {
      path: '/xgzl',
      name: 'Xgzl',
      component: Xgzl
    },
    {
      path: '/txxg',
      name: 'Txxg',
      component: Txxg
    },
    {
      path: '/nchen',
      name: 'Nchen',
      component: Nchen
    },
    {
      path: '/glhm',
      name: 'Glhm',
      component: Glhm
    },
    {
      path: '/byjd',
      name: 'Byjd',
      component: Byjd
    },
    {
      path: '/search',
      name: 'Search',
      component: Search
    },
    {
      path: '/wxsp',
      name: 'Wxsp',
      component: Wxsp
    },
    {
      path: '/look',
      name: 'Look',
      component: Look
    },
    {
      path: '/xdzx',
      name: 'Xdzx',
      component: Xdzx
    },
    {
      path: '/lszd',
      name: 'Lszd',
      component: Lszd
    },
    {
      path: '/spxq',
      name: 'Spxq',
      component: Spxq
    },
    {
      path: '/fjmd',
      name: 'Fjmd',
      component: Fjmd
    },
    {
      path: '/fwpj',
      name: 'Fwpj',
      component: Fwpj
    },
    {
      path: '/star',
      name: 'Start',
      component: Start
    },
    {
      path: '/wddy',
      name: 'Wddy',
      component: Wddy
    },
    {
      path: '/zxsc',
      name: 'Zxsc',
      component: Zxsc
    },
    {
      path: '/cssc',
      name: 'Cssc',
      component: Cssc
    },
    {
      path: '/zxzx',
      name: 'Zxzx',
      component: Zxzx
    },
    {
      path: '/zxxq',
      name: 'Zxxq',
      component: Zxxq
    },
    {
      path: '/zxq',
      name: 'Zxq',
      component: Zxq
    },{
      path: '/zxqq',
      name: 'Zxqq',
      component: Zxqq
    },
    {
      path: '/dhy',
      name: 'Dhy',
      component: Dhy
    },
    {
      path: '/md',
      name: 'Md',
      component: Md
    },
    {
      path: '/zxh',
      name: 'Zxh',
      component: Zxh
    },
    {
      path: '/ddzx',
      name: 'Ddzx',
      component: Ddzx
    },
    {
      path: '/xxzx',
      name: 'Xxzx',
      component: Xxzx
    },
    {
      path: '/banner',
      name: 'Banner',
      component: Banner
    },
    {
      path: '/zskf',
      name: 'Zskf',
      component: Zskf
    },
    {
      path: '/gxsz',
      name: 'Gxsz',
      component: Gxsz
    },
    {
      path: '/ysxy',
      name: 'Ysxy',
      component: Ysxy
    }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  // 
  routes  
})

export default router
