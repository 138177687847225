

//引入axios
import axios from "axios"
import qs from "qs"
// 设置默认地址
axios.defaults.baseURL = "http://10.208.110.178:9100";




// export default {
//     // 封装get请求
//     get(url, params) {
//         return new Promise((resolve, reject) => {
//             axios.get(url, { params }).then((res) => {
//                 resolve(res.data);
//             }).catch((erro) => {
//                 reject(erro);
//             })
//         })
//     },
//     //post  请求头
//     // 封装post请求
//     post(url, params) {
//         return new Promise((resolve, reject) => {
//             axios.post(url, params).then((res) => {
//                 resolve(res.data);
//             }).catch((erro) => {
//                 reject(erro);
//             })
//         })
//     }
// }
