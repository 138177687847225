<template>
  <div class="txxg">
    <Tips />
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      设置头像
    </div>
    <!-- 上传显示内容 -->

    <div class="photo">
      <img :src="avatar" class="tphoto" />
    </div>
    <!-- 文件上传 -->
    <div class="footer">
      <van-uploader :after-read="afterRead" @oversize="onOversize"
        >头像上传</van-uploader
      >
    </div>
    <!-- <div class="btn" @click="upbtn">相册选择</div> -->
    <div class="newmask" v-if="show">
      <van-loading type="spinner" color="rgb(255, 205, 17)" class="mmaskpic"
        >正在上传中...</van-loading
      >
    </div>
  </div>
</template>

<script>
import tx from "@/assets/img/cat.jpg";
import qs from "qs";
import md5 from "js-md5";
import axios from "axios";
import Vue from "vue";
import { Toast, Uploader } from "vant";
import Tips from "@/components/Tips.vue";
Vue.use(Uploader);
export default {
  components: {
    Tips,
  },
  created() {
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/userInfo" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
        show: true,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/user/userInfo",
    })
      .then((res) => {
        if (res.data.item.avatar.length>2) {
          this.avatar = this.picurl + res.data.item.avatar;
        } else {
        }
      })
      .catch((error) => {});
  },
  data() {
    return {
      avatar: tx,
      img: "",
      show: false,
    };
  },
  methods: {
    //回到上个页面
    back() {
      this.$router.push("/my");
    },
    //图片上传
    afterRead(file) {
      this.show = true;
      // 此时可以自行将文件上传至服务器
      file.status = "uploading";
      file.message = "上传中...";
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/upload/uploadAvatarImg" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));

      const formdata = new FormData();
      formdata.append("file", file.file);
      axios({
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        data: formdata,
        method: "post",
        url: this.reurl + "/app/upload/uploadAvatarImg",
      })
        .then((res) => {
          if (res.data.errcode == 0) {
            this.img = res.data.item;
            let datas = {
              nickName: localStorage.getItem("nickname"),
              avatar: this.img,
            };
            axios({
              headers: {
                "Content-Type": "application/json;",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              body: {
                params: datas,
              },
              method: "post",
              transformRequest: [
                function (data) {
                  // 对 data 进行任意转换处理
                  return JSON.stringify(datas);
                },
              ],
              url: this.reurl + "/user/editUser",
            }).then((res) => {
              if (res.data.errcode == 0) {
                axios({
                  headers: {
                    "Content-Type": "application/json;",
                    token: token,
                    timestamp: timestamp,
                    nonce: nonce,
                    sign: sj,
                  },
                  body: {
                    params: datas,
                  },
                  method: "get",
                  transformRequest: [
                    function (data) {
                      // 对 data 进行任意转换处理
                      return JSON.stringify(datas);
                    },
                  ],
                  url: this.reurl + "/user/userInfo",
                }).then((res) => {
                  if (res.data.errcode == 0) {
                    this.$router.push("/my");
                    Toast("头像更新成功");
                  }
                });
              } else {
                Toast("头像更改失败");
                this.show = false;
              }
            });
          } else {
            Toast("头像更改失败");
             this.show = false;
          }
        })
        .catch((error) => {
           this.show = false;
        });
    },
    //限制上传大小
    onOversize(file) {
      Toast("图片大小不能超过 1M");
    },
  },
};
</script>

<style lang="less" scoped>
.newmask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .mmaskpic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
  }
}
.txxg {
  .footer {
    width: 320px;
    line-height: 48px;
    border-radius: 10px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -160px;
    text-align: center;
    background: rgb(255, 205, 17);
  }
  .photo {
    .tphoto {
      position: absolute;
      bottom: 50%;
      margin-bottom: -100px;
      left: 50%;
      margin-left: -160px;
      width: 320px;
      height: 320px;
    }
    width: 100%;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f5f5f5;
}
</style>