<template>
  <div class="nichen">
    <!-- 返回 -->
    <Tips />
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>个人资料
    </div>
    <!-- 内容页面 -->
    <div class="main">
      <div class="tit">昵称</div>
      <div class="con">
        <div>
          <input type="text" class="ipt" v-model="value" placeholder="请输入昵称" />
        </div>
        <div>
          <img src="@/assets/img/tb.png" class="cpic" @click="btn" />
        </div>
      </div>
    </div>
    <div class="save" @click="sub">保存</div>
  </div>
</template>

<script>
import qs from "qs";
import md5 from "js-md5";
import axios from "axios";
import Vue from "vue";
import { Toast } from "vant";
import Tips from "@/components/Tips.vue";
Vue.use(Toast);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      value: "",
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100"
    };
  },
  methods: {
    //回到资料修改
    back() {
      this.$router.push("/xgzl");
    },
    //清空输入内容
    btn() {
      this.value = "";
    },
    //保存
    sub() {
      if (this.value.length < 1) {
        Toast("昵称不能为空");
      } else {
        let token = localStorage.getItem("token");
        let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
        let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
        let nonce = md5(
          "http://10.208.110.178:9100/user/editUser" +
            timestamp +
            Math.round(Math.random() * 8)
        );
        let sign = [
          "token=" + token,
          "timestamp=" + timestamp,
          "nonce=" + nonce,
          "secret=" + secret,
        ];
        function fn(sign) {
          return Array.from(new Set(sign)).sort();
        }
        let sj = md5(fn(sign).join("&"));
        let datas = {
          nickName: this.value,
          avatar: localStorage.getItem("img"),
        };
        axios({
          headers: {
            "Content-Type": "application/json;",
            token: token,
            timestamp: timestamp,
            nonce: nonce,
            sign: sj,
          },
          body: {
            params: datas,
          },
          method: "post",
          transformRequest: [
            function (data) {
              // 对 data 进行任意转换处理
              return JSON.stringify(datas);
            },
          ],
          url: this.reurl + "/user/editUser",
        })
          .then((res) => {
            if (res.data.errcode == 0) {
              Toast("修改成功");
              this.$router.push("/xgzl");
            } else if (res.data.errcode == 40000) {
              Toast("登录失效请重新登陆");
              this.$router.push("/login");
            }
          })
          .catch((error) => {
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nichen {
  .save {
    line-height: 48px;
    background: #ffe55c;
    border-radius: 10px;
    margin: 10px;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
  }
  .main {
    margin: 10px;
    background: #fff;
    .con {
      .ipt {
        width: 200px;
        margin-top: 10px;
        border: none;
        color: #999999;
        font-size: 14px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      .cimg {
        width: 18px;
        height: 18px;
      }
    }
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f5f5f5;
  .header {
    margin-bottom: 20px;
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
}
</style>