<template>
  <div>
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" id="close_page" />
      </div>
      <h1 id="close_page">零件购买</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.create("http://wapemall.eci-metro.com/");
  },
  methods: {
    create(a) {
      //创建窗口对象
      var h5 = plus.webview.create(a, "h5", {
        top: "44px",
        bottom: 0,
      });
      //显示窗口对象
      h5.show();
      zxzx;
    },
    back() {
      //获取id对象窗口页面并销毁
      var a = plus.webview.getWebviewById("h5");
      plus.webview.close(a);
      //销毁获取到的窗口对象
      setTimeout(() => {
       history.go(-1);
      }, 500);
      //获取所有窗口页面并且给进行销毁处理
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 20px;
  background: #fff;
  line-height: 44px;
  font-weight: bold;
  height: 44px;
  width: 100%;
  text-align: center;
  .back {
    position: absolute;
    font-size: 24px;
    color: #fff;
    left: 15px;
    top: 3px;
    .pic {
      height: 22px;
      width: 22px;
    }
  }
}
</style>