<template>
  <div id="bxjd">
    <!-- 返回 -->
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      服务详情
    </div>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="title fwb">
          <div
            class="tj"
            :style="show == false ? ' position: relative' : ''"
            v-show="height == 'height:100px'"
          >
            <div v-show="values.status == 0">已提交</div>
            <div v-show="values.status == 1">已受理</div>
            <div v-show="values.status == 3">已派工</div>
            <div v-show="values.status == 4">已完成</div>
            <div v-show="values.status == 5">订单取消</div>
            <div v-show="values.status == 6">评价完成</div>
          </div>
          <div id="content" v-if="show" :style="height"></div>
          <div
            class="close"
            v-show="height == 'height:600px'"
            @click="height = 'height:100px'"
          >
            &#10006;
          </div>
        </div>
        <!-- 联系部分 -->
        <header>
          <div class="fz14 fwb lxkh" v-show="values.status == 0">
            预计1-3小时联系您
          </div>
          <div class="fz14 fwb lxkh" v-show="values.status == 3">
            预计{{ time == "" ? "247" : time }}分钟到达
          </div>
          <div class="hbtm">
            <!-- <div class="fwb fz14">联系我们</div> -->
            <div class="call calls" @click="call"  v-show="values.status < 4">
              <span><img src="../../assets/img/qxdd.png" alt="" /></span>
              <span> 取消订单 </span>
            </div>
            <div class="call calls" @click="call">
              <span><img src="../../assets/img/lxecim.png" alt="" /></span>
              <span class="cyello">联系易初明通</span>
            </div>
          </div>
        </header>
      </van-pull-refresh>
      <!-- 维修部分 -->
      <div class="repair">
        <!-- <div>
          <span class="fwb fz14" style="margin-right: 20px">{{
            values.serviceType == 1 ? "维修" : "保养"
          }}</span>
          <span class="fz12">{{ values.createTime }}</span>
        </div> -->
        <div class="sbcon">
          <div>
            <img
              :src="
                values.deviceImageUrl != null
                  ? picurl + values.deviceImageUrl
                  : 'http://customerapptest.eci-metro.cn:7070//deviceImg/default.jpg'
              "
              class="pic"
            />
          </div>
          <div class="conr fz12">
            <div>
            <div  class="fz14 fwb">
              名称:{{
                service.deviceName == null ? "通用设备" : service.deviceName
              }}
            </div>
              <div class="flex fz12 c9b">
                <div class="mr10">型号:{{ service.model }}</div>
                <div class="mr10">|</div>
                <div class="ceee">序列号:{{ service.serialnumber }}</div>
              </div>
            </div>
            <div class="ceee">
              购买时间:{{
                values.devicePurchaseTime == null || " "
                  ? "-"
                  : values.devicePurchaseTime
              }}
            </div>
          </div>
        </div>
      </div>
      <!-- cva -->
      <div class="cva" v-show="values.serviceType == 2">
        <div class="fwb fz12">CVA</div>
        <div class="mt10">
          <van-cell-group>
            <van-field
              input-align="right"
              label="CVA类型"
              size="large"
              :value="values.cvaType == null ? '-' : values.cvaType"
              readonly
            />
            <van-field
              input-align="right"
              label="开始日期"
              size="large"
              :value="
                values.cvaStartTime == null
                  ? '-'
                  : values.cvaStartTime | dateformat('yyyy年MM月DD日')
              "
              readonly
            />
            <van-field
              input-align="right"
              label="结束日期"
              size="large"
              :value="
                values.cvaEndTime == null
                  ? '-'
                  : values.cvaEndTime | dateformat('yyyy年MM月DD日')
              "
              readonly
            />
            <van-field
              input-align="right"
              label="CVA年限"
              size="large"
              :value="values.cvayears == null ? '-' : values.cvayears"
              readonly
            />
            <van-field
              input-align="right"
              label="开始小时数"
              size="large"
              :value="values.cvaStartHours == null ? '-' : values.cvaStartHours"
              readonly
            />
            <van-field
              input-align="right"
              label="结束小时数"
              size="large"
              :value="values.cvaEndHours == null ? '-' : values.cvaEndHours"
              readonly
            />
          </van-cell-group>
        </div>
      </div>

      <!-- 服务信息 -->
      <div class="service">
        <div class="fz14 fwb">服务信息</div>
        <div class="scon">
          <img
            v-for="(value, index) in values.repairOrderPicList"
            :key="index"
            :src="picurl + value.url"
            alt=""
          />
        </div>
        <div class="mt10">
          <van-cell-group>
            <van-field
              input-align="right"
              size="large"
              label="服务类型"
              :value="values.serviceType == 1 ? '维修' : '保养'"
              readonly
            />
            <van-field
              label="联系人"
              size="large"
              input-align="right"
              :value="values.contacts"
              readonly
            />
            <van-field
              label="联系号码"
              size="large"
              input-align="right"
              :value="values.contactNumber"
              readonly
            />
            <van-field
              label="服务地址"
              size="large"
              input-align="right"
              :value="values.serviceAddress"
              readonly
            />
            <van-field
              label="订单备注"
              size="large"
              input-align="right"
              :value="values.remark == null ? '-' : values.remark"
              readonly
            />
          </van-cell-group>
        </div>
      </div>
      <!-- 订单信息 -->
      <div class="order">
        <div class="fz14 fwb">订单信息</div>
        <div class="mt10">
          <van-cell-group>
            <van-field
              label="订单号"
              input-align="right"
              size="large"
              v-clipboard:copy="values.tpsId"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              :value="values.tpsId"
              readonly
            />
            <van-field
              input-align="right"
              size="large"
              label="业务编码"
              :value="values.id"
              readonly
            />
            <van-field
              input-align="right"
              size="large"
              label="下单时间"
              :value="values.createTime"
              readonly
            />
            <van-field
              label="受理时间"
              size="large"
              input-align="right"
              v-show="values.cancelTime != null"
              :value="values.cancelTime"
              readonly
            />
            <!-- 派工循环 -->
            <div
              v-for="(value, index) in values.repairOrderStatusList"
              :key="index"
            >
              <van-field
                input-align="right"
                size="large"
                v-show="value.status == 1 || value.status == 3"
                :label="value.status == 1 ? '已受理' : '已派工'"
                :value="value.operateTime == null ? '-' : value.operateTime"
                readonly
              />
              <van-field
                input-align="right"
                size="large"
                label="维修师傅"
                :value="value.repairman == null ? '-' : value.repairman"
                v-clipboard:copy="value.repairman"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                readonly
                v-show="value.status == 3"
              />
              <van-field
                input-align="right"
                size="large"
                label="师傅号码"
                :value="
                  value.repairmanphone == null ? '-' : value.repairmanphone
                "
                v-clipboard:copy="value.repairmanphone"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                readonly
                v-show="value.status == 3"
              />
              <van-field
                input-align="right"
                size="large"
                label="师傅到达时间"
                v-show="value.status == 3"
                :value="value.arrivalTime == null ? '-' : value.arrivalTime"
                readonly
              />
              <van-field
                input-align="right"
                size="large"
                v-show="value.status == 3"
                :value="value.leaveTime == null ? '-' : value.leaveTime"
                label="师傅离开时间"
                readonly
              />
              <van-field
                input-align="right"
                size="large"
                :label="value.status == 4 ? '订单完成' : '订单取消'"
                v-show="value.status == 4 || value.status == 5"
                :value="value.operateTime == null ? '-' : value.operateTime"
                readonly
              />
              <van-field
                input-align="right"
                size="large"
                label="处理人"
                v-show="value.status == 5"
                :value="value.handlernum"
                readonly
              />

              <div class="tese"></div>
            </div>
          </van-cell-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Field } from "vant";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
export default {
  data() {
    return {
      time: "",
      height: "height:100px",
      isLoading: false,
      show: false,
      values: "",
      service: "",
    };
  },
  methods: {
    // 复制成功时的回调函数
    onCopy(e) {
      Toast("内容已复制到剪切板！");
    },
    // 复制失败时的回调函数
    onError(e) {
      Toast("抱歉，复制失败！");
    },
    //获取详细数据
    getRepairOrderInfo(a) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=10" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        method: "get",
        url: this.reurl + "/app/repair/getRepairOrderInfo?id=" + a,
      }).then((res) => {
        this.values = res.data.item;
        console.log(this.values);
      });
    },
    //下拉
    onRefresh() {
      if (this.show == false) {
        this.isLoading = false;
      } else {
        this.height = "height:600px";
        this.isLoading = false;
      }
    },
    //地图
    map(a, b, c, d) {
      let that = this;
      var map = new AMap.Map("content", {
        resizeEnable: true,
        center: [a, b], //地图中心点
        zoom: 13, //地图显示的缩放级别
      });
      //构造路线导航类
      var driving = new AMap.Driving({
        map: map,
      });
      // 根据起终点经纬度规划驾车导航路线
      driving.search(
        new AMap.LngLat(a, b),
        new AMap.LngLat(c, d),
        function (status, result) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status === "complete") {
            //驾车时间
            that.time = (result.routes[0].time / 60).toFixed(0);
          } else {
          }
        }
      );
    },
    maps() {
      //基本地图加载
      var map = new AMap.Map("content", {
        resizeEnable: true,
        center: [116.397428, 39.90923], //地图中心点
        zoom: 13, //地图显示的缩放级别
      });
      //构造路线导航类
      var driving = new AMap.Driving({
        map: map,
      });
      // 根据起终点经纬度规划驾车导航路线
      driving.search(
        new AMap.LngLat(116.379028, 39.865042),
        new AMap.LngLat(116.427281, 39.903719),
        function (status, result) {
          // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
          if (status === "complete") {
            log.success("绘制驾车路线完成");
          } else {
            log.error("获取驾车数据失败：" + result);
          }
        }
      );
    },
    //打电话
    call() {
      window.location.href = "tel://4008870829";
    },
    back() {
      window.history.go(-1);
    },
  },
  mounted() {
    setTimeout(() => {
      // this.maps();
      this.map(
        // 116.379028, 39.865042,116.427281, 39.903719
        this.values.carGps.lng,
        this.values.carGps.lat,
        this.values.contactsLongitude,
        this.values.contactsLatitude
       
      );
    }, 1000);
  },
  created() {
    this.service = JSON.parse(sessionStorage.getItem("serviceId"));
    this.getRepairOrderInfo(this.service.id);
    this.service.status == 3 ? (this.show = true) : (this.show = false);
  },
};
</script>

<style lang="less" scoped>
.tese {
  // height: 20px;
  border-radius: none;
}
#bxjd {
  display: flex;
  flex-direction: column;
  .main {
    flex: 1;
    overflow: auto;
  }
  .cva {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
  }
  .order {
    background: #fff;
    padding: 10px;
    margin-top: 10px;
  }
  .service {
    .scon {
      img {
        margin-top: 10px;
        height: 80px;
        width: 80px;
      }

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    margin-top: 10px;
    background: #fff;
    padding: 10px;
  }

  .repair {
    .sbcon {
      .conr {
        display: flex;
        line-height: 20px;
        flex-direction: column;
        margin-left: 40px;
      }

      .pic {
        width: 80px;
        height: 80px;
        margin-top: 10px;
      }

      display: flex;
      justify-content: start;
      align-items: center;
    }

    background: #fff;
    margin-top: 10px;
    padding: 10px;
  }

  header {
    .hbtm {
      .call {
        margin-left: 20px;
        font-size: 12px;
        width: 100px;
        text-align: center;
        border-radius: 3px;
      }

      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 10px;
    }

    .lxkh {
      text-indent: 15px;
    }

    background: #fff;
    padding: 10px;
    line-height: 20px;
  }

  .title {
    .close {
      position: absolute;
      z-index: 99;
      right: 20px;
      top: 20px;
      // color: #fccc00;
      color: blue;
    }
    .tj {
      z-index: 99;
      position: absolute;
      top: 40%;
    }
    padding: 10px;
    position: relative;
  }

  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    margin-bottom: 0;
    height: 44px;
    width: 100%;
    text-align: center;

    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;

      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }

  height: 100%;
  background: #f5f5f5;
}

.fwb {
  font-weight: bold;
}

.fz12 {
  font-size: 12px;
}

.ml20 {
  margin-left: 20px;
}

.fz14 {
  font-size: 14px;
}
.mt10 {
  margin-top: 10px;
}

.calls {
  display: flex;
  flex-direction: column;
  img {
    height: 24px;
  }
}
.cyello {
  color: #fccc00;
}
.flex {
  display: flex;
  justify-content: start;
}
.mr10 {
  margin-right: 10px;
}
.c9b{
  color: #9B9B9B;
}
.van-cell::after{
  border: none;
}
</style>