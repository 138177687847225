<template>
  <div id="jdcx">
    <!-- 返回 -->
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      服务进度
    </div>
    <!-- 搜索 -->
    <!-- <div class="search">
      <van-search v-model="value" placeholder="机编号/型号" />
    </div> -->
    <!-- 内容 -->
    <main>
      <div class="title">
        <div @click="all" :class="active == 0 ? 'active' : ''">全部</div>
        <div @click="repair" :class="active == 1 ? 'active' : ''">维修</div>
        <div @click="maintain" :class="active == 2 ? 'active' : ''">保养</div>
        <div @click="evaluate" :class="active == 3 ? 'active' : ''">待评价</div>
      </div>
      <div class="con" v-for="(value, index) in item" :key="index">
        <div class="sb">
          <div class="sbtit">
            <div>
              {{ value.serviceType == 1 ? "维修" : "保养" }}
              {{ value.updateTime }}
            </div>
            <div class="cre" v-show="value.status == 0">已提交</div>
            <div class="cre" v-show="value.status == 1">已受理</div>
            <div class="cre" v-show="value.status == 3">已派工</div>
            <div class="cre" v-show="value.status == 5">已完成</div>
            <div class="cre" v-show="value.status == 4">订单取消</div>
            <div class="cre" v-show="value.status == 6">评价完成</div>
          </div>
          <div class="sbcon" @click="details(value)">
            <div>
              <img
                :src="
                  value.deviceImageUrl != null
                    ? picurl + value.deviceImageUrl
                    : 'http://customerapptest.eci-metro.cn:7070//deviceImg/default.jpg'
                "
                class="pic"
              />
            </div>
            <div class="conr fz12">
              <div class="fwb fz14">
                名称:{{ value.model }} {{ value.deviceName }}
              </div>
              <div class="flex">
                <div class="mr10">机编号:{{ value.serialnumber }}</div>
                <div >|</div>
                <div>型号:{{ value.model }}</div>
              </div>
              <div>
                维修时间: <span class="ceee">{{ value.repairTime }}</span>
              </div>
              <div>
                维修师傅: <span class="ceee">{{ value.repairMan }} </span>
              </div>
            </div>
          </div>
          <div class="mt10 jd">
            <div>
              <van-steps
                :active="value.status >= 3 ? value.status - 1 : value.status"
                style="width: 300px"
                active-color="#fccc00"
              >
                <van-step>已提交</van-step>
                <van-step>已受理</van-step>
                <van-step>已派单</van-step>
                <van-step v-show="value.status == 4">已取消</van-step>
                <van-step v-if="value.status != 4">已完成</van-step>
                <!-- <van-step v-if="value.status == 5">已取消</van-step>
                <van-step v-if="value.status != 5">已完成</van-step> -->
              </van-steps>
            </div>
          </div>
          <div class="tlr">
            <div class="urge" v-show="num.includes(value.status)" @click="urge">
            催促进度
          </div>
          </div>
          <div  class="tlr">
            <div class="urge" v-if="value.status == 5" @click="evaluates(value)">
            服务评价
          </div>
          </div>
        </div>
      </div>
      <van-empty v-show="item.length == 0" description="暂无设备" />
    </main>
    <!-- 遮罩层 -->
    <div class="mask" v-show="show == 1">
      <div class="tips">
        <p>已经帮你催促了，请耐心等待哦~</p>
        <div class="tbtm">
          <div class="cblur" @click="show = 0">耐心等待</div>
          <div class="cblurs" @click="call">联系易初明通</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Search } from "vant";
import { Empty } from "vant";
import { Step } from "vant";
import { Dialog } from "vant";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import { json } from "body-parser";
Vue.use(Dialog);
Vue.use(Step);
Vue.use(Empty);
Vue.use(Search);
export default {
  data() {
    return {
      num1: [5],
      num: [0, 1, 3],
      value: "",
      active: 0,
      actsives: 0,
      show: 0,
      item: [],
    };
  },
  created() {
    this.list("", "");
  },
  methods: {
    //获取列表接口
    list(a, b) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=10" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        method: "get",
        url:
          this.reurl +
          "/app/repair/getRepairOrderPageList?page=1&pageSize=99&status=" +
          a +
          "&serviceType=" +
          b +
          "&keyword=",
      }).then((res) => {
        // let a  = res.data.item.slice(0,-1);
        let a = res.data.item;
        a.forEach((e) => {
          if (e.status == 5) {
            e.status = 4;
          } else if (e.status == 4) {
            e.status = 5;
          }
        });
        this.item = a;
      });
    },
    //打电话
    call() {
      window.location.href = "tel://4008870829";
      this.show = 0;
    },
    //跳往评价
    evaluates(e) {
      sessionStorage.setItem("serviceId", JSON.stringify(e));
      this.$router.push("/fwpj");
    },
    //查看详情
    details(e) {
      sessionStorage.setItem("serviceId", JSON.stringify(e));
      this.$router.push("/bxjd");
    },
    //待评价
    evaluate() {
      this.active = 3;
      this.list(4, "");
    },
    //保养
    maintain() {
      this.active = 2;
      this.list("", 2);
    },
    //维修
    repair() {
      this.active = 1;
      this.list("", 1);
    },
    //全部
    all() {
      this.active = 0;
      this.list("", "");
    },
    //粗崔进度
    urge() {
      this.show = 1;
    },
    back() {
      this.$router.push("/index");
    },
  },
};
</script>

<style lang="less" scoped>
#jdcx {
  .mask {
    .tips {
      padding: 15px;

      .tbtm {
        margin-top: 20px;
        .cblurs {
          background: #fccc00;
        }
        div {
          text-align: center;
          color: #000;
          border: 1px solid #eee;
          line-height: 34px;
          width: 120px;
          border-radius: 5px;
        }
        height: 45px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      background: #fff;
      border-radius: 10px;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, -80%);
      p {
        text-indent: 15px;
        font-size: 14px;
        font-weight: bold;
      }
      width: 260px;
    }
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9;
  }
  main {
    flex: 1;
    .con {
      .sb {
        padding: 10px;
        border-radius: 5px;
        background: #f5f5f5;
        .urge {
          width: 120px;
          box-sizing: border-box;
          background: #fccc00;
          padding: 8px 14px;
          text-align: center;
          font-size: 14px;
          border-radius: 4px;
        }
        .jd {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .sbcon {
          .conr {
            display: flex;
            line-height: 20px;
            flex-direction: column;
            margin-left: 40px;
          }
          .pic {
            width: 80px;
            height: 80px;
            margin-top: 10px;
          }
          display: flex;
          justify-content: start;
          align-items: center;
        }
        .sbtit {
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
        }
      }
      padding: 10px;
    }
    .title {
      div {
        padding-bottom: 5px;
      }
      .active {
        border-bottom: 2px solid #fccc00;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      background: #fff;
    }
    overflow: auto;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    margin-bottom: 0;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  // background: #f5f5f5;
}
.tlr{
  display: flex;
  justify-content: flex-end;
}
.mr10 {
  margin-right: 10px;
}
.flex {
  display: flex;
  justify-content: start;
}
.mt10 {
  margin-top: 10px;
}
.fz12 {
  font-size: 12px;
}
.fz14 {
  font-size: 14px;
}
.ceee {
  color: #999999;
}
.fwb {
  font-weight: bold;
}
.cblur {
  color: #0076ff;
}
.van-steps {
  background: #f5f5f5;
}
/deep/.van-step--horizontal .van-step__circle-container {
  background: #f5f5f5;
}
.cre {
  color: #f25643;
}
</style>