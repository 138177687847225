<template>
  <div id="gxsz">
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      个人设置
    </div>
    <div class="main">
      <div class="box">
        <div class="fltit">
          <h4>添加常用功能</h4>
          <p v-show="num == 0" @click="num = 1" class="fltitgl">管理</p>
          <p v-show="num == 1" @click="complete" class="fltitgl">完成</p>
        </div>
        <div class="content">
          <div
            class="tb"
            v-for="(item, index) in ypz"
            :key="index"
            v-show="!permission.includes(item.name)"
          >
            <img :src="picurl + item.icon" class="img" />
            <span @click="del(item)" v-show="num == 1">&ndash;</span>
            <div style="font-size: 12px">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="box1" v-show="wpz.length != 0">
        <h4>我的功能</h4>
        <div class="bmain">
          <div
            class="tb"
            v-for="(item, index) in wpz"
            :key="index"
            style="text-align: center"
            v-show="!permission.includes(item.name)"
          >
            <img :src="picurl + item.icon" class="img" />
            <span v-show="num == 1" @click="adds(item)">+</span>
            <div style="font-size: 12px">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="newmask" v-if="show" @click="down">
      <van-loading type="spinner" color="rgb(255, 205, 17)" class="mmaskpic" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import { Toast } from "vant";
export default {
  data() {
    return {
      num: 0,
      show: false,
      box: [],
      ypz: [],
      permission: [],
      wpz: [],
    };
  },
  created() {
    this.permission = JSON.parse(localStorage.getItem("permission"));
    this.wdsz();
  },
  methods: {
    //完成
    complete() {
      if (this.ypz.length == 8) {
        let a = [];
        for (let index = 0; index < this.ypz.length; index++) {
          a.push(this.ypz[index].id);
        }
        this.Submit(a);
      } else {
        this.tips("常用功能必须为8个!");
      }
    },
    //提示
    tips(a) {
      Toast(a);
    },
    //提交个性化请求
    Submit(a) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/sendSms" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = a;
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/userPersonalise/saveUserPersonaliseV2",
      }).then((res) => {
        if (res.data.errcode == 0) {
          this.wdsz();
          this.num = 0;
          this.tips("保存成功");
        } else {
          this.tips("保存失败");
        }
      });
    },
    //获取我的个性化设置
    wdsz() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/userPersonalise/getConfigResourceById",
      }).then((res) => {
        if (res.data.errcode != 0) {
          Toast("请求失败，稍后重试");
        } else {
          let b = [];
          let c = [];
          for (let index = 0; index < res.data.item.length; index++) {
            if (res.data.item[index].isCheck == 1) {
              b.push(res.data.item[index]);
            } else {
              c.push(res.data.item[index]);
            }
            //b.push(res.data.item[index])
            // if(res.data.item[index].name=="一键报修" || res.data.item[index].name=="我的服务"){
            // }else{
            //   b.push(res.data.item[index])
            // }
          }
          this.ypz = b;
          this.wpz = c;
        }
      });
    },
    //关闭遮罩层
    down() {
      this.show = false;
    },
    //添加功能
    adds(e) {
      if (this.ypz.length >= 8) {
        this.tips("常用功能不能超过8个!");
      } else {
        this.ypz.push(e);
        this.wpz.some((item, i) => {
          if (item.id === e.id) {
            this.wpz.splice(i, 1);
          }
        });
      }
    },
    //删除功能
    del(e) {
      this.ypz.some((item, i) => {
        if (item.id === e.id) {
          this.ypz.splice(i, 1);
        }
      });
      this.wpz.push(e);
    },
    //返回我的页面
    back() {
      this.$router.push("/my");
    },
  },
};
</script>

<style lang="less" scoped>
.fltit {
  .fltitgl {
    font-weight: bold;
    font-size: 14px;
    color: rgb(80, 197, 255);
  }
  display: flex;
  justify-content: space-between;
}
.none {
  display: none;
}
#gxsz {
  .main {
    .box1 {
      .bmain {
        .tb {
          span {
            color: #fff;
            padding: 0;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            position: absolute;
            text-align: center;
            line-height: 16px;
            margin: 0;
            font-weight: bold;
            right: -5px;
            top: -3px;
            background: rgb(255, 205, 17);
          }
          .img {
            width: 44px;
            height: 44px;
          }
          position: relative;
          margin-top: 10px;
          margin-right: 35px;
        }
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      margin-top: 20px;
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      h4 {
        font-weight: bold;
        font-size: 15px;
      }
    }
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    .box {
      border-radius: 10px;
      background: #fff;
      padding: 10px;
      .content {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .tb {
          span {
            background: red;
            font-weight: bold;
            color: #fff;
            padding: 0;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            position: absolute;
            text-align: center;
            line-height: 16px;
            margin: 0;
            right: -5px;
            top: -3px;
          }
          position: relative;
          margin-right: 35px;
          margin-top: 10px;
          .img {
            width: 44px;
            height: 44px;
          }
        }
      }
      h4 {
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
      }
    }
  }
  .header {
    margin-bottom: 20px;
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  .newmask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .mmaskpic {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -90%);
    }
  }
  background: #f5f5f5;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
</style>