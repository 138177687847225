<template>
  <div class="xgzl">
    <Tips />
    <div class="mask">
      <!-- 拨打客户电话 -->
      <div class="m" v-show="show">
        <div class="tel">
          <a class="t1" @click="qx">400 客服代表</a>
          <div class="t2" @click="show = false">取消</div>
        </div>
      </div>
      <div class="vx" v-show="vxshow">
        <!-- 微信解绑 -->
        <!-- <div class="vbox">
          <p class="fz20 mt10">是否解除微信绑定</p>
          <p class="fz14 c99 ls mt10">解除后无法使用“XXX”微信登录</p>
          <div class="vxbtn fz20">
            <div class="q1 w1" @click="qxjb">取消</div>
            <div class="z1 w1" @click="zyjc">执意解除</div>
          </div>
        </div> -->
      </div>
      <!-- 手机号码解绑 -->
      <div class="vx" v-show="jcshow">
        <div class="vbox">
          <p class="fz20 mt10">是否解除关联手机号码</p>
          <p class="fz14 c99 ls mt10">
            解除后他将无法查询部分信息，是否还要解绑
          </p>
          <div class="vxbtn fz20">
            <div class="q1" @click="qxjb">取消</div>
            <div class="z1" @click="jcbtn">执意解除</div>
          </div>
        </div>
      </div>
      <!-- 返回 -->
      <div class="header">
        <div class="back" @click="back">
          <img class="pic" src="@/assets/img/mback.png" alt />
        </div>
        修改资料
      </div>
      <!-- 修改内容 -->
      <div class="main">
        <!-- 头像修改 -->
        <!-- <div class="tx" @click="txxg">
          <div class="txl">
            <img :src="avatar" class="img" />
            <p>更换头像</p>
          </div>
          <div class="txr">
            <img src="@/assets/img/backr.png" />
          </div>
        </div> -->
        <!-- 个人资料修改 -->
        <div class="xinx">
          <div class="xbox" @click="nichen" style="margin-bottom: 15px">
            <div>昵称</div>
            <div class="c99 fz12 flex">
              <p class="mr15">{{ nickName }}</p>
              <img class="ximg" src="@/assets/img/backr.png" />
            </div>
          </div>
          <!-- <div class="xbox">
            <div>微信</div>
            <div class="c99 fz12 mtb30 flex">
              <p class="mr15" @click="vxjb">解绑</p>
              <img class="ximg" src="@/assets/img/backr.png" />
            </div>
          </div> -->
          <div class="xbox">
            <div>手机号</div>
            <div class="c99 fz12 flex">
              <p class="mr15">
                {{ phone }}
                <span class="ml10" @click="jb">解绑</span>
              </p>
              <img class="ximg" src="@/assets/img/backr.png" />
            </div>
          </div>
          <!-- 点我查话费 -->
          <!-- <a href="tel://10086">点我查话费</a> -->
        </div>
        <!-- 公司信息 -->
        <div class="gs">
          <div class="gbox" style="margin: 0">
            <div>公司</div>
            <div class="c99 fz12">{{ company }}</div>
          </div>
          <div class="gbox" style="margin-top: 15px">
            <div>DBS ID</div>
            <div class="c99 fz12">{{ dbsId }}</div>
          </div>
        </div>
        <!-- 关联号码 -->
        <div class="num">
          <div class="ntit">
            <div>关联号码</div>
            <div @click="glhm">✚</div>
          </div>
          <!-- 号码内容 -->
          <div class="ncon" v-for="(item, index) in linkPhones" :key="index">
            <div>{{ item.linkPhone }}</div>
            <span style="margin-left: 30px">dbsId:{{ item.dbsId }}</span>
            <div class="fz12 c99" @click="bye(item)">解绑</div>
          </div>
        </div>
        <!-- 退出登录 -->
        <div class="clear" @click="auto">退出登录</div>
        <div class="clear" @click="auto">注销账号</div>
      </div>
      <div class="footer">
        <div class="btm">
          <!-- <a @click="ysxy">《用户协议及隐私协议》</a> -->
        </div>
      </div>
      <div class="mask1" v-show="mask1">
        <div class="mtit">
          <div class="mback" @click="mask1 = false">
            <img src="@/assets/img/mback.png" alt />
          </div>
          隐私政策
        </div>
        <div class="content">
          <p>“Custormer APP”隐私政策(例文)</p>
          <p class="ct">更新日期：2020年9月15日</p>
          <p>
            北京微播视界科技有限公司及其关联方（简称“我们”）作为抖音的运营者，深知个人信息对您的重要性，我们将按照法律法规的规定，保护您的个人信息及隐私安全。我们制定本“隐私政策”并特别提示：希望您在使用抖音及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。
          </p>
          <p>本隐私政策将帮助您了解：</p>
          <p>
            •
            我们会遵循隐私政策收集、使用您的信息，但不会仅因您同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。
          </p>
          <p>
            •
            当您使用或开启相关功能或使用服务时，为实现功能、服务所必需，我们会收集、使用相关信息。除非是为实现基本业务功能或根据法律法规要求所必需的必要信息，您均可以拒绝提供且不影响其他功能或服务。我们将在隐私政策中逐项说明哪些是必要信息。
          </p>
          <p>
            •
            如果您未登录帐号，我们会通过设备对应的标识符信息来保障信息推送的基本功能。如果您登录了帐号，我们会根据帐号信息实现信息推送。
          </p>
          <p>• 为了帮您发现更多朋友，我们可能会申请通讯录权限。</p>
          <p>
            •
            我们尊重您的选择权，如果您不希望被推荐给朋友，您可以在“我—设置—隐私设置”中随时关闭。
          </p>
          <p class="last">
            •
            通讯录、精确地理位置、摄像头、麦克风、相册权限，均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。特别需要指出的是，即使经过您的授权，我们获得了这些敏感权限，也不会在相关功能或服务不需要时而收集
          </p>
          <div class="tst"></div>
        </div>
        <div class="gg"></div>
      </div>
      <div class="mask1" v-show="mask2">
        <div class="mtit">
          <div class="mback" @click="mask2 = false">
            <img src="@/assets/img/mback.png" alt />
          </div>
          用户协议
        </div>
        <div class="content">
          <p>用户协议（例文）</p>
          <p class="ct">
            尊敬的用户，欢迎使用由杭州维聚有限公司（下列简称为“维聚”或“白犀牛”）提供的服务。在使用前请您阅读如下服务协议，使用本应用即表示您同意接受本协议，本协议产生法律效力，特别涉及免除或者限制维聚责任的条款，请仔细阅读。如有任何问题，可向维聚咨询。
          </p>

          <p>
            1. 服务条款的确认和接受
            通过访问或使用本应用，表示用户同意接受本协议的所有条件和条款。
          </p>
          <p>
            2. 服务条款的变更和修改
            维聚有权在必要时修改服务条款，服务条款一旦发生变更，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以放弃获得的本应用信息服务。如果用户继续享用本应用的信息服务，则视为接受服务条款的变更。本应用保留随时修改或中断服务而不需要通知用户的权利。本应用行使修改或中断服务的权利，不需对用户或第三方负责。
          </p>
          <p>3. 用户行为</p>
          <p>
            3.1 用户账号、密码和安全
            用户一旦注册成功，便成为白犀牛的合法用户，将得到一个密码和帐号。同时，此账号密码可登录白犀牛的所有网页和APP。因此用户应采取合理措施维护其密码和帐号的安全。用户对利用该密码和帐号所进行的一切活动负全部责任；由该等活动所导致的任何损失或损害由用户承担，维聚不承担任何责任。
            用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户可以立即通知维聚，并且用户在每次连线结束，应结束帐号使用，否则用户可能得不到维聚的安全保护。对于用户长时间未使用的帐号，维聚有权予以关闭并注销其内容。
          </p>
          <p>3.2 账号注册时的禁止行为</p>
          <p>
            （1）请勿以党和国家领导人或其他社会名人的真实姓名、字号、艺名、笔名注册；
          </p>
          <p>
            （2）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关的名称；
          </p>
          <p>（3）请勿注册和其他网友之名相近、相仿的名字；</p>
          <p>
            （4）请勿注册不文明、不健康名字，或包含歧视、侮辱、猥亵类词语的名字；
          </p>
          <p>（5）请勿注册易产生歧义、引起他人误解的名字；</p>
          <p>3.3 用户在本应用上不得发布下列违法信息和照片：</p>
          <p>（1）反对宪法所确定的基本原则的；</p>
          <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
          <p>（3）损害国家荣誉和利益的；</p>
          <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
          <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
          <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
          <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
          <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
          <p>（9）含有法律、行政法规禁止的其他内容的；</p>
          <p>（10）禁止骚扰、毁谤、威胁、仿冒网站其他用户；</p>
          <p>（11）严禁煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
          <p>
            （12）严禁发布可能会妨害第三方权益的文件或者信息，例如（包括但不限于）：病毒代码、黑客程序、软件破解注册信息。
          </p>
          <p>
            （13）禁止上传他人作品。其中包括你从互联网上下载、截图或收集的他人的作品；
          </p>
          <p>（14）禁止上传广告、横幅、标志等网络图片；</p>
          <p>4. 上传或发布的内容</p>
          <p>
            用户上传的内容是指用户在白犀牛上传或发布的视频或其它任何形式的内容包括文字、图片、音频等。除非维聚收到相关通知，否则维聚将用户视为其在本应用上传或发布的内容的版权拥有人。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。维聚不承担任何法律及连带责任。
            对于经由本应用而传送的内容，维聚不保证前述其合法性、正当性、准确性、完整性或品质。用户在使用本应用时，有可能会接触到令人不快、不适当或令人厌恶的内容。在任何情况下，维聚均不对任何内容承担任何责任，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。维聚有权（但无义务）自行拒绝或删除经由本应用提供的任何内容。
            个人或单位如认为维聚存在侵犯自身合法权益的内容，应准备好具有法律效应的证明材料，及时与维聚取得联系，以便维聚迅速作出处理。
          </p>
          <p>隐私条款</p>
          <p>1.用户信息公开情况说明</p>
          <p>
            尊重用户个人隐私是维聚的一项基本政策。所以，维聚不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本应用中的非公开内容，除非有下列情况：
          </p>
          <p>（1）有关法律规定或维聚合法服务程序规定；</p>
          <p>（2）在紧急情况下，为维护用户及公众的权益；</p>
          <p>（3）为维护维聚的商标权、专利权及其他任何合法权益；</p>
          <p>（4）其他需要公开、编辑或透露个人信息的情况；</p>
          <p>在以下（包括但不限于）几种情况下，维聚有权使用用户的个人信息：</p>
          <p>
            （1）在进行促销或抽奖时，维聚可能会与赞助商共享用户的个人信息，在这些情况下维聚会在发送用户信息之前进行提示，并且用户可以通过不参与来终止传送过程；
          </p>
          <p>（2）维聚可以将用户信息与第三方数据匹配；</p>
          <p>
            （3）维聚会通过透露合计用户统计数据，向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述维聚的服务；
          </p>
          <p>2.隐私权政策适用范围</p>
          <p>（1）用户在登录本应用服务器时留下的个人身份信息；</p>
          <p>（2）用户通过本应用服务器与其他用户或非用户之间传送的各种资讯；</p>
          <p>（3）本应用与商业伙伴共享的其他用户或非用户的各种信息；</p>
          <p>3.资讯公开与共享</p>
          <p>
            维聚不会将用户的个人信息和资讯故意透露、出租或出售给任何第三方。但以下情况除外：
          </p>
          <p>（1）用户本人同意与第三方共享信息和资讯;</p>
          <p>
            （2）只有透露用户的个人信息和资讯，才能提供用户所要求的某种产品和服务;
          </p>
          <p>
            （3）应代表本应用提供产品或服务的主体的要求提供（除非我们另行通知，否则该等主体无权将相关用户个人信息和资讯用于提供产品和服务之外的其他用途）：根据法律法规或行政命令的要求提供;因外部审计需要而提供;用户违反了本应用服务条款或任何其他产品及服务的使用规定;经本站评估，用户的帐户存在风险，需要加以保护。
          </p>
          <p>4.Cookies、日志档案和webbeacon</p>
          <p>
            通过使用cookies，本应用向用户提供简单易行并富个性化的网络体验。cookies能帮助我们确定用户连接的页面和内容，并将该等信息储存。我们使用自己的cookies和webbeacon，用于以下用途：
          </p>
          <p>
            （1）记住用户身份。例如：cookies和webbeacon有助于我们辨认用户作为我们的注册用户的身份，或保存用户向我们提供有关用户的喜好或其他信息；
          </p>
          <p>
            （2）分析用户使用我们服务的情况。我们可利用cookies和webbeacon来了解用户使用我们的服务进行什么活动、或哪些网页或服务最受欢迎；
            我们为上述目的使用cookies和webbeacon的同时，可能将通过cookies和webbeacon收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用我们的服务并用于广告服务。用户可以通过浏览器或用户选择机制拒绝或管理cookies或webbeacon。但请用户注意，如果用户停用cookies或webbeacon，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。
          </p>
          <p>
            3）当你使用本站的服务时，我们的主机会自动记录用户的浏览器在访问网站时所发送的信息和资讯。主机日志资讯包括但不限于用户的网路请求、IP地址、浏览器类型、浏览器使用的语言、请求的日期和时间，以及一个或多个可以对用户的浏览器进行辨识的cookie。
          </p>
          <p>5.账户删除申请</p>
          <p>
            用户有权在任何时候编辑用户在维聚的帐户信息和资讯，用户也可以填写相关申请表格，要求删除个人帐户，但是用户无条件同意在你的帐户删除后，该帐户内及与该帐户相关的信息和资讯仍然保留在本网站档案记录中，除上述第三条规定的情况外，维聚将为用户保密。
          </p>
          <div class="tst"></div>
        </div>
        <div class="gg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import tx from "@/assets/img/cat.jpg";
import Vue from "vue";
import { Popup } from "vant";
import { Toast } from "vant";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import Tips from "@/components/Tips.vue";
Vue.use(Toast);
Vue.use(Popup);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      mask1: false,
      mask2: false,
      nickName: "",
      show: false,
      vxshow: false,
      jcshow: false,
      dbsId: "",
      avatar: tx,
      phone: "",
      company: "",
      linkPhones: [],
      linkNum: "",
      imgUrl: "http://customerapp.eci-metro.cn/", //"http://10.208.120.107",
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100"
      relationIds: "",
    };
  },
  created() {
    this.st();
  },
  methods: {
    ysxy() {
      this.$router.push("/ysxy");
    },
    //初始化
    st() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/userInfo/v2",
      })
        .then((res) => {
          if (res.data.errcode == 40000) {
            Toast("登录失效请重新登陆");
            this.$router.push("/login");
          }
          if (res.data.item.nickName !== null) {
            this.nickName = res.data.item.nickName;
            localStorage.setItem("nickName", res.data.item.nickName);
            localStorage.setItem("img", res.data.item.avatar);
          } else {
            this.nickName = res.data.item.phone;
          }
          if (res.data.item.avatar !== null) {
            this.avatar = this.imgUrl + res.data.item.avatar;
            localStorage.setItem("tx", res.data.item.avatar);
          }
          this.phone = res.data.item.phone;
          this.dbsId = res.data.item.dbsId;
          this.company = res.data.item.company;
          this.linkPhones = res.data.item.linkPhones;
          localStorage.setItem("phone", res.data.item.phone);
        })
        .catch((error) => {});
    },
    //返回我的页面
    back() {
      this.$router.push("/my");
    },
    //昵称修改
    nichen() {
      this.$router.push("/nchen");
    },
    //关联号码
    glhm() {
      this.$router.push({
        path: "/glhm",
        query: {
          item: this.dbsId,
        },
      });
    },
    //解除手机绑定
    jb() {
      this.show = true;
      // href="tel//:4008870829"
    },
    //取消拨打电话
    qx() {
      this.show = false;
      window.location.href = "tel://4008870829";
    },
    //微信解绑
    vxjb() {
      this.vxshow = true;
    },
    //取消解绑
    qxjb() {
      this.vxshow = false;
      this.jcshow = false;
    },
    //执意解除
    zyjc() {
      this.vxshow = false;
      Toast("解绑成功");
    },
    //解除子号码绑定
    bye(e) {
      this.relationIds = e.relationId;
      this.linkNum = e.linkPhone;
      this.jcshow = true;
    },
    //执意解除手机号码
    jcbtn() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/delRelationUser/v2" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {
        phone: this.linkNum,
        relationIds: [this.relationIds],
      };
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/delRelationUser/v2",
      }).then((res) => {
        if (res.data.errcode == 0) {
          this.jcshow = false;
          this.st();
          Toast("解绑成功");
        } else if (res.data.errcode == 40000) {
          localStorage.clear();
          Toast("登录失效请重新登陆");
          this.$router.push("/login");
        }
      });
    },
    //退出登录
    auto() {
      let token = localStorage.getItem("token");

      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/loginOut" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/loginOut",
      })
        .then((res) => {})
        .catch((error) => {});
      this.$router.push("/login");
      localStorage.clear();
      Toast("退出成功");
    },
  },
};
</script>

<style lang="less" scoped>
.mask1 {
  .gg {
    background: #f5f5f5;
    height: 40px;
    width: 100%;
    bottom: 0;
    position: absolute;
  }
  height: 100%;
  .content {
    .tst {
      height: 80px;
    }
    .ct {
      margin-bottom: 20px;
    }
    .last {
      padding: 0 0 20px 0;
    }
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    font-weight: bold;
    color: #333333;
    p {
      line-height: 30px;
    }
    padding: 20px;
  }
  .mtit {
    height: 44px;
    line-height: 44px;
    text-align: center;
    position: relative;
    font-weight: bold;
    font-size: 18px;
    background: #fff;
    .mback {
      position: absolute;
      left: 20px;
      img {
        vertical-align: text-bottom;
        width: 22px;
        height: 22px;
      }
    }
  }
  background: #f5f5f5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.footer {
  padding-top: 90px;
  margin: auto;
  width: 100%;
  .vx {
    .img {
      width: 44px;
      height: 44px;
    }
    text-align: center;
  }
  .btm {
    a {
      color: #2676d0;
    }
    width: 100%;
    margin: auto;
    position: absolute;
    left: 50%;
    margin-left: -50%;
    bottom: 40px;
    text-align: center;
    color: #999999;
    font-size: 12px;
  }
}
.xgzl {
  .mask {
    .vx {
      padding-top: 200px;
      .vbox {
        .ls {
          margin-top: 20px;
        }
        padding-top: 30px;
        .vxbtn {
          margin-top: 25px;
          border-top: 1px solid #e5e5e5;
          display: flex;
          justify-content: space-around;
          line-height: 40px;
          .q1 {
            width: 140px;
            border-right: 1px solid #e5e5e5;
          }
          .z1 {
            width: 140px;
            margin-bottom: 20px;
          }
          .w1 {
            margin-bottom: 0;
            margin-top: 10px;
          }
        }
        text-align: center;
        margin: auto;
        background: #fff;
        border-radius: 10px;
        width: 280px;
        height: 150px;
      }
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(216, 216, 216, 0.7);
    }
    .m {
      z-index: 9;
      .tel {
        width: 100%;
        .t2 {
          background: #fff;
          width: 200px;
          margin: auto;
          margin-top: 30px;
          padding: 10px 60px;
          border-radius: 10px;
          color: black;
        }
        .t1 {
          padding: 20px 100px;
          background: #fff;
          border-radius: 10px;
          margin-bottom: 20px;
          color: black;
        }
        position: absolute;
        left: 50%;
        bottom: 30px;
        transform: translate(-50%);
        font-size: 20px;
        line-height: 40px;
        text-align: center;
        color: black;
      }
      padding: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(216, 216, 216, 0.7);
    }
    .main {
      .clear {
        text-align: center;
        background: #fff;
        line-height: 48px;
        border-radius: 10px;
        margin-top: 20px;
      }
      .num {
        .ncon {
          margin-top: 20px;
          padding-left: 30px;
          display: flex;
          justify-content: space-between;
        }
        .ntit {
          display: flex;
          justify-content: space-between;
        }
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        margin-top: 10px;
      }
      .gs {
        .gbox {
          margin-top: 25px;
          display: flex;
          justify-content: space-between;
        }
        margin-top: 10px;
        padding: 10px;
        background: #fff;
        border-radius: 10px;
      }
      .xinx {
        .xbox {
          .ximg {
            vertical-align: center;
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        margin-top: 10px;
      }
      .tx {
        .txl {
          .img {
            margin-right: 15px;
            border: 50%;
            width: 56px;
            height: 56px;
          }
          display: flex;
          align-items: center;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        padding: 10px;
        background: #fff;
        border-radius: 10px;
      }
      padding: 10px;
    }
    .header {
      background: #fff;
      line-height: 44px;
      font-weight: bold;
      height: 44px;
      width: 100%;
      text-align: center;
      .back {
        position: absolute;
        font-size: 24px;
        color: #fff;
        left: 15px;
        top: 3px;
        .pic {
          height: 22px;
          width: 22px;
        }
      }
    }
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f5f5f5;
  }
  .c99 {
    color: #999999;
  }
  .fz12 {
    font-size: 14px;
  }
  .mtb30 {
    margin: 30px 0;
  }
  .fwb {
    font-weight: bold;
  }
  .flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .mr15 {
    margin-right: 15px;
  }
  .ml10 {
    margin-left: 10px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .fz20 {
    font-size: 20px;
  }
}
</style>