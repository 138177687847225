<template>
  <div class="dt">
    <div class="newmask" v-if="newmask">
      <van-loading type="spinner" color="rgb(255, 205, 17)" class="mmaskpic" />
    </div>
    <!-- 头部 -->
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      附近门店
    </div>
    <!-- 地图 -->
    <!-- <img src="../../assets/img/zw.png" alt /> -->
    <div id="container"></div>
    <router-link to="md" id="dh">
      <img src="@/assets/img/gddh.png" v-show="show" />
    </router-link>    
  </div>
</template>

<script>
import sb from "@/assets/img/mysb.png";
import Swiper from "swiper";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import Vue from "vue";
import { Loading } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      loding: true,
      img: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:",
      //获取到的门店地址数据
      md: [],
      show: false,
      newmask:true
    };
  },
  created() {
    this.getMd();
  },
  methods: {
    //返回
    back() {
      this.$router.push("/index");
    },
    getMd() {
      let that = this;
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/services/saveServiceRecord" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        url: this.reurl + "/app/services/allStore",
      }).then((res) => {
        //成功回调赋值
        that.md = res.data.item;
      });
    },
    //附近门店
    fjmd() {
      let that = this;
      //设置定时器，防止获取不到数据
      setTimeout(() => {
        // 初始化地图
        var map = new AMap.Map("container", {
          resizeEnable: true, //精准定位
          zoom: 5, //地图层级，层级越高显示区块越大
          center: [104.213201, 30.561543], //进入地图初始中心点
        });
        map.clearMap();
        var markers = []; //多个标注点数组
        var infoWindow;
        //地图数据
        var data = that.md; //拿到多个左边点数组数据赋值
        //判断拿到数据没有，如果没有拿到数据让他退出重进
        if (data.length == 0) {
          Toast("网络卡顿，请重新加载");
          this.newmask=false
        }
        var marker;
        //定义地图未点击时图标
        var preIcon = new AMap.Icon({
          image: "http://customerapp.eci-metro.cn/22.png",
          size: new AMap.Size(30, 40), //图标大小
          imageSize: new AMap.Size(30, 40),
        });
        //定义地图点击时图标
        var clickIcon = new AMap.Icon({
          image: "http://customerapp.eci-metro.cn/33.png",
          size: new AMap.Size(30, 37), //图标大小
          imageSize: new AMap.Size(30, 37),
        });
        //循环数组里的坐标，标注在地图上
        for (var i = 0; i < data.length; i++) {
          //每个坐标的经纬度
          var jfong = [data[i].longitude, data[i].latitude];
          //在地图上渲染坐标
          marker = new AMap.Marker({
            position: jfong, //位置信息
            zIndex: 101, //忘了
            map: map, //忘了
            icon: preIcon, //图标
          });
          markers.push(marker); //坐标点添加
          marker.setMap(map); //设置地图
          marker.dz = data[i].address; //我请求的数据  定义后即是下方点击事件 e.target.dz
          marker.tel = data[i].storeName; //同上
          marker.jd = data[i].longitude; //同上
          marker.wd = data[i].latitude; //同上
          //点击坐标促发事件
          marker.on("click", function (e) {
            // 遍历更改图标
            for (var i = 0; i < markers.length; i++) {
              //把所有图标更换为相同图标
              markers[i].setIcon(preIcon);
            }
            //更改当前标注点的图标
            e.target.setIcon(clickIcon);
            //自定义内容
            let jwd = e.target.jd + "," + e.target.wd; //获取当前定位经纬度
            localStorage.setItem("jwd", jwd); //自己保存 其他页面使用
            localStorage.setItem("dz", e.target.dz); //同上
            that.show = true; //自己设的导航 可以忽略和地图无关
            //自定义点击时候显示的标注框内容 html结构，只有行类样式有效
            infoWindow.setContent(
              "<div class='dtxx'>" +
                "<ul class='main' style='background:#fccc00; font-size:12px;padding:5px;line-height:16px'><li> 门店名称： <span style=''>" +
                e.target.tel +
                "</span></li>" +
                "<li>  门店地址: <span>" +
                e.target.dz +
                "  </span></li>" +
                "<li>" +
                "</li>" +
                "</ul>" +
                "</div>"
            );
            //我也不知道这个是干什么的必须有这段
            infoWindow.open(map, e.lnglat);
          });
        }
        //我也不知道什么鬼  同上
        infoWindow = new AMap.InfoWindow({
          isCustom: true,
          draggable: true, //是否可拖动
          offset: new AMap.Pixel(0, -31),
          content: "",
        });
        //设置地图
        marker.setMap(map);
        map.clearInfoWindow();
        
        this.newmask=false
      }, 2000);
      
    },
    //打开地图
    open() {
    },
  },
  mounted() {
    // this.getMd();
    //调用附近门店
    this.fjmd();
    setTimeout(() => {
      this.loding = false;
    }, 2000);
  },
};
</script>

<style lang="less" scoped>
.newmask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .mmaskpic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
  }
}
/deep/.dtxx {
  position: fixed;
  bottom: -6px;
  left: 0;
  width: 85%;
  height: 80px;
  background: #fccc00;
}
#dh {
  width: 15%;
  img {
    height: 80px;
    width: 100%;
  }
  position: absolute;
  bottom: -7px;
  right: 0;
  color: #fff;
  font-size: 12px;
}
#pic {
  width: 181px;
  height: 81px;
  position: absolute;
  left: 50%;
  top: 40%;
  margin-left: -90px;
  z-index: 99;
}
.jzz {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  margin-left: -25px;
}
.dt {
  #container {
    width: 100%;
    height: 800px;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>