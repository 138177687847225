<template>
  <div class="glhm">
    <!-- 头部 -->
    <Tips />
    <!-- <van-number-keyboard
      v-model="value"
      :show="showKeyboard"
      @blur="showKeyboard = false"
    /> -->
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      关联号码
    </div>
    <!-- 新手机号 -->
    <div class="new" v-show="show">
      <div class="nleft">
        <div class="tit">新手机号码</div>
        <input
          type="text"
          class="ipt"
          v-model="value"
          maxlength="11"
          placeholder="请输入手机号码"
          @click="showKeyboard = true"
        />
      </div>
      <div class="nright" @click="clear">
        <!-- <img src="@/assets/img/tb.png" @click="clear" /> -->
        <!-- <div style="margin-right: 15px">&times;</div> -->
      </div>
    </div>
    <!-- 获取验证码 -->
    <div class="save" v-show="show" @click="sub">发送验证码</div>
    <!-- 旧手机号 -->
    <div class="new" v-show="isshow">
      <div class="nleft">
        <div class="tit">验证码</div>
        <input
          type="text"
          class="ipt"
          @click="acc = true"
          v-model="old"
          placeholder="请输入验证码"
        />
      </div>
    </div>
    <!-- tips -->
    <div class="mt10" v-show="isshow">
      <span class="fz12">验证码已经通过短信发送至 +86</span>
      <span>{{ value }}</span>
    </div>
    <!-- 完成 -->
    <div class="save" v-show="isshow" @click="sure">完成</div>
  </div>
</template>

<script>
import qs from "qs";
import md5 from "js-md5";
import axios from "axios";
import Vue from "vue";
import { Toast } from "vant";
import Tips from "@/components/Tips.vue";
Vue.use(Toast);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      showKeyboard: false,
      value: "",
      old: "",
      show: true,
      isshow: false,
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100"
    };
  },
  methods: {
    //修改资料
    back() {
      this.$router.push("/xgzl");
    },
    //清除号码
    clear() {
      this.value = "";
    },
    //发送验证码
    sub() {
      var regex = /^1[3|4|5|7|8]\d{9}$/;
      let phone = localStorage.getItem("phone");
      if (regex.test(this.value)) {
        let token = "";
        let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
        let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
        let nonce = md5(
          "http://10.208.110.178:9100/user/sendSms" +
            timestamp +
            Math.round(Math.random() * 8)
        );
        let sign = [
          "token=" + token,
          "timestamp=" + timestamp,
          "nonce=" + nonce,
          "secret=" + secret,
        ];
        function fn(sign) {
          return Array.from(new Set(sign)).sort();
        }
        let sj = md5(fn(sign).join("&"));
        if (this.value.length != 11) {
          Toast("请输入正确的手机号码");
        } else {
          if (parseInt(phone) == parseInt(this.value)) {
            Toast("不能与登录号码相同");
          } else {
            this.show = false;
            let datas = {
              phone: this.value,
            };
            axios({
              headers: {
                "Content-Type": "application/json;",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              body: {
                params: datas,
              },
              method: "post",
              transformRequest: [
                function (data) {
                  // 对 data 进行任意转换处理
                  return JSON.stringify(datas);
                },
              ],
              url: this.reurl + "/user/sendSms",
            }).then((res) => {
              if (res.status == 200) {
                this.isshow = true;
              }
            });
          }
        }
      } else {
        Toast("请输入正确的手机号码");
      }
    },
    //确认验证码返回修改资料
    sure() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/addRelationUser/v2" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {
        phone: this.value,
        smsCode: this.old,
        dbsId: this.$route.query.item,
      };
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/addRelationUser/v2",
      })
        .then((res) => {
          if (res.data.errcode == 42004) {
            Toast(res.data.errmsg);
            this.$router.push("/xgzl");
          } else if (res.data.errcode == 0) {
            Toast("添加成功");
            let ls = setInterval(() => {
              clearInterval(ls);
              this.$router.push("/xgzl");
            }, 1000);
          } else {
            // Toast("短信验证码失效");
            this.$router.push("/xgzl");
            Toast(res.data.errmsg);
          }
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.glhm {
  .new {
    .nright {
      font-size: 30px;
    }
    .nleft {
      width: 100%;
      .ipt {
        margin-top: 5px;
        border: none;
      }
      .tit {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: #fff;
    align-items: center;
    border-radius: 10px;
    margin: 10px;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f5f5f5;
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  .save {
    line-height: 48px;
    background: #ffe55c;
    border-radius: 10px;
    margin: 10px;
    font-weight: bold;
    text-align: center;
    margin-top: 30px;
  }
  .fz12 {
    font-size: 12px;
  }
  .mt10 {
    margin-top: 10px;
    margin-left: 10px;
  }
}
</style>