<template>
  <div class="byjd">
    <Tips />
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      信息详情
    </div>
    <div class="main">
      <div class="tit">{{ sbxq.serviceStatus }}</div>
      <!-- 步骤条 -->
      <div class="box" v-show="show">
        <van-steps
          direction="vertical"
          :active="active"
          active-color="#FCCC00"
          inactive-color="#333333"
        >
          <van-step v-for="(item, i) in data" :key="i" class="jdt">
            <h3>{{ item.status }}</h3>
            <p class="fz12">备注信息：{{ item.statusNotes }}</p>
            <p class="fz12">
              {{ item.statusTime | dateformat("YYYY年/MM月/DD日 HH点MM分") }}
            </p>
          </van-step>
          <!-- <van-step>
            <h3>师傅已经出发，快马加鞭杆来</h3>
            <p class="fz12">备注信息：{{bz2.statusTime}}</p>
            <p class="fz12">{{bz2.statusTime | dateformat('YYYY年/MM月/DD日 HH点MM分')}}</p>
          </van-step>
          <van-step>
            <h3>服务已完成</h3>
            <p class="fz12">备注信息：</p>
            <p class="fz12"></p>
          </van-step>-->
        </van-steps>
      </div>
      <div class="maps" v-if="isReloadData">
        <div id="container"></div>
        <div id="panel"></div>
      </div>
    </div>
    <div class="box2">
      <div class="find"></div>
      <div class="footer">
        <div class="font">
          <!-- <img src="@/assets/img/sl.png" class="sl" @click="sl=1" v-show="sl==0" /> -->
          <div class="lbox">
            <div class="left">
              <div>预计到达时间</div>
              <div class="fz16">{{ sbxq.estimatedTimeOfArrival }}</div>
              <div>
                <img src="@/assets/img/dzg.png" class="jl" />
                距离 {{ (long / 1000).toFixed(2) }}公里
              </div>
            </div>
            <div class="rigth">
              <img src="@/assets/img/wxsf.png" class="wxsf" />
              <p class="fz12">{{ sbxq.repairman }}师傅</p>
            </div>
          </div>
          <div class="tel fz12">
            <div class="ecim" @click="ecim">联系易初明通</div>
            <div class="mt" @click="sf">联系师傅</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import { Step, Steps } from "vant";
import Vue from "vue";
import Tips from "@/components/Tips.vue";
import { Loading } from "vant";
Vue.use(Step);
Vue.use(Steps);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      loding: true,
      long: "",
      sl: 0,
      show: true,
      isshow: false,
      sf1: "",
      sf2: "",
      location1: "",
      location2: "",
      isReloadData: true,
      statusNotes1: {},
      statusNotes2: {},
      statusNotes3: {},
      active: "",
      sbxq: {},
      id: "",
      data: [],
      bz1: {},
      bz2: {},
      bz3: {},
      img: "http://customerapp.eci-metro.cn/ecim", // "http://10.208.120.107:",
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100",
      data2: {},
      yh: "",
      long: "",
      dqwz: "",
      repairmanPhone: "4008870829",
    };
  },
  mounted() {
    //调用地图+轨迹计算
    this.walk();
    //等待中
    setTimeout(() => {
      this.loding = false;
    }, 2000);
  },
  created() {
            let data = {
          key: "f2abb3e2569d214a897f3f2ddba8aa97",
          address:"乐山大佛",
        };
        $.ajax({
          url: "https://restapi.amap.com/v3/geocode/geo",
          type: "get",
          dataType: "jsonp",
          data,
          success: function (data) {
            console.log(data);
            let a  = data.geocodes[0].location.match(/(\S*),/)[1];
            let b  = data.geocodes[0].location.match(/,(\S*)/)[1];
          },
        });
    //获取师傅当前定位请求,和客户坐标转化
    this.dqdw();
  },
  methods: {
    //获取师傅当前定位请求,和客户坐标转化
    dqdw() {
      this.long = localStorage.getItem("long");
      let that = this;
      this.id = this.$route.query.item;
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/sendSms" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        url: this.reurl + "/app/services/serviceRecord/" + this.id,
      }).then(function (res) {
        that.repairmanPhone = res.data.item.repairmanPhone;
        that.sbxq = res.data.item;
        that.bz1 = res.data.item.statusList[0];
        that.bz2 = res.data.item.statusList[1];
        that.bz3 = res.data.item.statusList[2];
        that.active = res.data.item.statusList.length - 1;
        that.data = res.data.item.statusList;
        that.dqwz = res.data.item.serviceAddress;
        that.sf1 = res.data.item.repairmanLocationLongitude;
        that.sf2 = res.data.item.repairmanLocationLatitude;
        //转化坐标

      });
      //获取当前位置
    },
    //地图使用
    walk() {
      setTimeout(() => {
        var that = this;
        //基本地图加载
        var map = new AMap.Map("container", {
          zoom: 11, //放大级别
          resizeEnable: true,
          center: [104.213201, 30.561543], //中心点坐标
          mapStyle: "amap://styles/light", //地图样式
        });
        //构造路线导航类
        var driving = new AMap.Driving({
          map: map,
          //panel: "panel",
        });
        // 根据起终点经纬度规划驾车导航路线
        driving.search(
          new AMap.LngLat(this.sf1, this.sf2),
          new AMap.LngLat(this.location1, this.location2),
          function (status, result) {
            // result 即是对应的驾车导航信息，相关数据结构文档请参考
            if (status === "complete") {
              //log.success("绘制驾车路线完成");
            } else {
              //log.error("获取驾车数据失败：" + result);
            }
          }
        );
        //计算距离
        var arr = new Array(); //经纬度坐标数组
        arr.push(new AMap.LngLat(this.sf1, this.sf2));
        arr.push(new AMap.LngLat(this.location1, this.location2));
        var distance = Math.round(AMap.GeometryUtil.distanceOfLine(arr));
        that.long = distance;
      }, 2000);
    },
    //易初明通
    ecim() {
      window.location.href = "tel://4008870829";
    },
    sf() {
      window.location.href = "tel://"+this.repairmanPhone;
    },
    //强制刷新页面
    // reload() {
    //   this.isReloadData = false;
    //   this.$nextTick(() => {
    //     this.isReloadData = true;
    //   });
    // },
    //回到上个页面
    back() {
      this.$router.push("/jdcx");
    },
  },
};
</script>

<style lang="less" scoped>
.jzz {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  margin-left: -25px;
}
.mtit {
  margin-bottom: 10px;
  font-size: 12px;
}
#container {
  width: 100%;
  height: 400px;
}
#panel {
  position: fixed;
  background-color: white;
  max-height: 90%;
  overflow-y: auto;
  top: 10px;
  right: 10px;
  width: 280px;
}
#panel .amap-call {
  background-color: #009cf9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
#panel .amap-lib-driving {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
}
.byjd {
  display: flex;
  flex-direction: column;
  .main {
    height: 100%;
    flex: 1;
  }
  .footer {
    .tel {
      .mt {
        padding: 6px 10px;
        margin-right: 15px;
        text-align: center;
        border-radius: 5px;
        background: #fccc00;
      }
      .ecim {
        border-radius: 5px;
        border: 1px solid #333333;
        text-align: center;
        margin-right: 15px;
        padding: 4px 6px;
      }
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
    }
    .left {
      font-size: 14px;
      line-height: 30px;
    }
    position: absolute;
    border-radius: 10px;
    bottom: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  .main {
    .maps {
      margin-top: 10px;
    }
    .box {
      overflow: auto;
      margin-top: 10px;
      border-radius: 10px;
    }
    padding: 15px;
    .tit {
      font-size: 18px;
      font-weight: 500;
    }
  }
  background: #f5f5f5;
  height: 100%;
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  .fz12 {
    font-size: 12px;
  }
  .c99 {
    color: #999999;
  }
  .wxsf {
    width: 80px;
    height: 60px;
  }
  .jl {
    width: 26px;
    height: 26px;
    vertical-align: middle;
  }
  .fz16 {
    font-size: 16px;
    font-weight: bold;
  }
  .lbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .font {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
  }
  // .sl {
  //   position: absolute;
  //   right: 50%;
  //   padding: 0px;
  //   width: 30px;
  //   animation: jump 3s ease infinite;
  // }
  // @keyframes jump {
  //   0% {
  //     transform: translateY(0) scale(1, 1);
  //   }
  //   /* 中间状态图片位移并且拉伸 */
  //   50% {
  //     transform: translateY(-10px) scale(0.97, 1.03);
  //   }
  //   100% {
  //     transform: translateY(0) scale(1, 1);
  //   }
  // }
  .jdt {
    line-height: 20px;
  }
  .right {
    margin: auto;
  }
}
</style>