<template>
  <div>
    <div class="header">
      <div class="back2" @click="back">
        <img class="pic" src="@/assets/img/mback.png" />
      </div>
      <span style="font-weight:bold">我的客服</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: "",
    };
  },
  created() {
    this.create();
  },
  methods: {
    back() {
      //获取id对象窗口页面并销毁
      var h = plus.webview.getWebviewById('baidu');
      plus.webview.close(h);
      //销毁获取到的窗口对象
      setTimeout(()=>{
        this.$router.push("/index");
      },500)
      //获取所有窗口页面并且给进行销毁处理
    },
    //创建窗口对象
    create() {
      //创建窗口对象
      var w = plus.webview.create(
        "https://wpa1.qq.com/F4VxOE32?_type=wpa&qidian=true",
        "baidu",
        {
          top: "44px",
          bottom: 0,
        }
      );
      //显示窗口对象
      w.show();
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 20px;
  background: #fff;
  line-height: 44px;
  font-weight: 500;
  height: 44px;
  width: 100%;
  text-align: center;
  .back2 {
    position: absolute;
    font-size: 24px;
    color: #fff;
    left: 15px;
    top: 3px;
    .pic {
      height: 22px;
      width: 22px;
    }
  }
}
</style>