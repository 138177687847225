<template>
  <div class="look">
    <iframe
      :src="picurl + data"
      allowfullscreen allow="autoplay; fullscreen"
      frameborder="0"
      class="sp"
    ></iframe>
    <img src="@/assets/img/tm.png" class="pic" @click="back" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: "",
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100",
      img: "http://customerapp.eci-metro.cn", //"http://10.208.120.107:",
    };
  },
  created() {
    this.data = this.$route.query.item;
  },
  methods: {
    back() {
      this.$router.push("/wxsp");
    },
  },
};
</script>

<style lang="less" scoped>




.look {
  height: 100%;
  width: 100%;
  .sp {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .pic {
    position: fixed;
    top: 15px;
    right: 0;
    bottom: 0;
    left: 15px;
  }
}
</style>