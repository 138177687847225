<template>
  <div id="fwpj">
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      服务评价
    </div>
    <div class="con">
      <img
        :src="
          item.deviceImageUrl != null
            ? picurl + item.deviceImageUrl
            : 'http://customerapptest.eci-metro.cn:7070//deviceImg/default.jpg'
        "
        alt=""
      />
      <div class="ml20">
        <p>机械编号:{{ item.serialnumber }}</p>
        <p>型号:{{ item.model }}</p>
      </div>
    </div>
    <div class="main">
      <p class="fwb fz14">您的评价可以增进我们的改进~</p>
      <div class="nav">
        <div
          @click="ad(index)"
          :class="num == index ? 'active' : ''"
          v-for="(item, index) in pj"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="ys">
        <div>
          <span>响应速度:</span
          ><van-rate
            v-model="value"
            :size="20"
            void-icon="http://customerapptest.eci-metro.cn:7070/image/pjdjh.png"
            icon="http://customerapptest.eci-metro.cn:7070/image/pjdj.png"
          />
        </div>
        <div>
          <span>维修质量:</span
          ><van-rate
            v-model="value1"
            :size="20"
            color="#ffd21e"
            void-icon="http://customerapptest.eci-metro.cn:7070/image/pjdjh.png"
            icon="http://customerapptest.eci-metro.cn:7070/image/pjdj.png"
          />
        </div>
        <div>
          <span>服务态度:</span
          ><van-rate
            v-model="value2"
            :size="20"
            color="#ffd21e"
            void-icon="http://customerapptest.eci-metro.cn:7070/image/pjdjh.png"
            icon="http://customerapptest.eci-metro.cn:7070/image/pjdj.png"
          />
        </div>
      </div>
    </div>
    <footer>
      <p>给易初明通留言</p>
      <div>
        <van-field
          v-model="message"
          rows="4"
          autosize
          input-align="left"
          type="textarea"
          maxlength="140"
          placeholder="请输入留言"
          show-word-limit
        />
      </div>
    </footer>
    <div class="btm" @click="go">提交评价</div>
    <div class="mask" v-show="mask">
      <van-empty  class="image" image-size="80px"  image="http://customerapptest.eci-metro.cn:7070/image/mypj.png" description="评价成功">
        <div class="mb10">
          
        </div>
        <van-button round type="danger" @click="ok" class="bottom-button"
          >完成</van-button
        >
      </van-empty>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Empty } from "vant";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";  
Vue.use(Empty);
export default {
  data() {
    return {
      mask: false,
      pj: ["好评", "中评", "差评"],
      num: 0,
      value: 5,
      value1: 5,
      value2: 5,
      message: "",
      item: "",
    };
  },
  created() {
    this.item = JSON.parse(sessionStorage.getItem("serviceId"));
    this.item.status==6?this.mask=true:this.mask=false;
  },
  methods: {
    go() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/login" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {
        parentId: this.item.id,
        total: this.num + 1,
        responseSpeed: this.value,
        repairQuality: this.value1,
        serviceAttitude: this.value2,
        advice:this.message,
      };
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/repair/serviceEvaluation",
      }).then((res) => {
        if(res.data.errcode==0){
        }
        this.mask=true;
      });
      
    },
    ok(){
      this.$router.push("/index");
    },
    ad(e) {
      this.num = e;
    },
    back() {
      this.$router.push("/jdcx");
    },
  },
};
</script>

<style lang="less" scoped>
   .custom-image .van-empty__imae {
    width: 60px;
    height: 60px;
  }
#fwpj {
  .mask {
    padding-top: 150px;
    .bottom-button {
      width: 160px;
      background: #fccb00;
      border: #fccb00;
      color: #000;
      height: 40px;
    }
    position: absolute;
    top: 54px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }
  .btm {
    width: 90%;
    line-height: 40px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    bottom: 10%;
    left: 50%;
    font-weight: bold;
    transform: translate(-50%);
    background: #fccb00;
  }
  footer {
    p {
      font-size: 14px;
      font-weight: bold;
      text-indent: 20px;
    }
    padding: 10px;
    background: #fff;
    margin-top: 10px;
  }
  .main {
    .ys {
      div {
        display: flex;
        justify-content: start;
        align-items: flex-end;
        margin-top: 20px;
        span {
          margin-right: 20px;
        }
      }
    }
    .nav {
      display: flex;
      justify-content: start;
      .active {
        background: rgb(255, 205, 17);
        border: none;
      }
      div {
        text-align: center;
        width: 80px;
        border: 1px solid #eee;
        margin-left: 10px;
        line-height: 20px;
        margin-top: 20px;
        border-radius: 4px;
      }
    }
    margin-top: 10px;
    background: #fff;
    padding: 10px;
    p {
      text-indent: 20px;
    }
  }
  .con {
    img {
      height: 80px;
      width: 80px;
    }
    p {
      line-height: 30px;
      font-size: 12px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background: #fff;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  height: 100%;
  background: #f5f5f5;
}
.ml20 {
  margin-left: 20px;
}
.fwb {
  font-weight: bold;
}
.fz12 {
  font-size: 12px;
}
.fz14 {
  font-size: 14px;
}
</style>