<template>
  <div>
    <div class="yybg">
      <!-- <img class="kf" src="@/assets/img/kefu.png" alt /> -->
      <div class="header">
        <div class="back" @click="back">
          <img class="pic" src="@/assets/img/mback.png" alt />
        </div>
        运营报告
      </div>
      <div class="rl">
        <van-cell
          title="选择日期"
          :value="date"
          @click="show = true"
          style="display: none"
        />
        <div
          style="text-align: center; font-size: 14px; color: rgb(255, 205, 17)"
          @click="show = true"
        >
          <img
            style="margin-right: 10px; vertical-align: middle; height: 18px"
            src="../../assets/img/ll.png"
            alt=""
          />
          <span v-show="num == 0">{{ toDay }}</span>
          <span v-show="num == 1">{{ toDay2 }}</span>
          <img
            style="margin-right: 0px; vertical-align: middle; height: 18px"
            src="@/assets/img/down.png"
            alt=""
          />
        </div>
        <van-calendar
          v-model="show"
          :min-date="minDate"
          :max-date="maxDate"
          color="rgb(255, 205, 17) "
          type="range"
          @confirm="onConfirm"
          :allow-same-day="true"
          :max-range="7"
        />
      </div>
      <div class="main">
        <div class="ec1">
          <div class="yh">
            <div class="hd"></div>
            <div class="time">
              <p>SMU (周)</p>
              <p class="fz12 mt10 c99">最后截至日期{{ startDate1 }}</p>
            </div>
          </div>
          <div id="main"></div>
        </div>
        <div class="ec1 ma10">
          <div class="yh">
            <div class="hd"></div>
            <div class="time">
              <p>油耗曲线（周）</p>
              <p class="fz12 mt10 c99">最后截至日期{{ startDate1 }}</p>
            </div>
          </div>
          <!-- <Echarts1 v-if="isReloadData" /> -->
          <div id="ad"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { datestr } from "@/utils/times.js";
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
import echarts from "echarts";
import Echarts1 from "../../components/Echarts1.vue";
import Tips from "../../components/Echarts1.vue";
import moment from "moment";
import { Calendar } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(Calendar);
export default {
  data() {
    return {
      runtimeHours: [],
      calendarDayAssetLocalTime: [],
      item: [],
      runtimeFuelBurnedGallons: [],
      arr: [],
      isReloadData: true,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(),
      show: false,
      date: "",
      startDate1: " ",
      toDay: "",
      toDay2: "",
      num: 0,
    };
  },
  components: { Echarts1 },
  created() {
    this.toDay = this.$moment(new Date()).format("YYYY-MM-DD");
    let toDay=this.$moment(new Date()).format("YYYY-MM-DD");
    var now = new Date();
    var date = new Date(now.getTime() - 7 * 24 * 3600 * 1000);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    let getwe = year + "-" + month + "-" + day;
    this.toDay = getwe+"-"+this.toDay;
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=10" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      url:
        this.reurl +
        "/app/device/runningInfoOfDay/" +
        this.$route.query.item +
        "/" +
        getwe +
        "/" +
        toDay,
    })
      .then((res) => {
        for (let i = 0; i < res.data.item.length; i++) {
          //第一个纵向数据
          this.runtimeHours.push(
            Math.floor(res.data.item[i].runtimeHours * 100) / 100
          );
          //添加时间
          this.calendarDayAssetLocalTime.push(
            this.$moment(res.data.item[i].calendarDayAssetLocalTime).format(
              "DD"
            )
          );
          //第二个纵向数据
          this.runtimeFuelBurnedGallons.push(
            Math.floor(res.data.item[i].runtimeFuelBurnedGallons * 100) / 100
          );
        }
        this.downEchart(
          this.calendarDayAssetLocalTime,
          this.runtimeHours,
          this.runtimeHours
        ); //拿到数据再调用图表绘制
        this.ec(
          this.calendarDayAssetLocalTime,
          this.runtimeFuelBurnedGallons,
          this.runtimeFuelBurnedGallons
        );
        // this.runtimeFuelBurnedGallons);
        // localStorage.setItem(
        //   "ecry",
        //   JSON.stringify(this.runtimeFuelBurnedGallons)
        // );
        // localStorage.setItem(
        //   "ecsj",
        //   JSON.stringify(this.calendarDayAssetLocalTime)
        // );
        if (res.data.errcode == 40000) {
          this.$router.push("/login");
          Toast("登录失效请重新登陆");
        }
      })
      .catch((error) => {});
  },
  methods: {
    //返回上个页面`
    formatDate(date) {
      //return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      // this.runtimeHours = [];
      localStorage.removeItem("ecry");
      localStorage.removeItem("ecsj");
      this.calendarDayAssetLocalTime = [];
      this.runtimeFuelBurnedGallons = [];
      this.runtimeHours = [];
      this.show = false;
      this.num = 1;
      this.date = this.formatDate(date);
      let startDate = moment(date[0]).format("YYYY-MM-DD");
      let startDate1 = moment(date[1]).format("YYYY-MM-DD");
      this.startDate1 = moment(date[1]).format("YYYY-MM-DD");
      this.toDay2 = startDate + "-" + startDate1;
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=10" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        url:
          this.reurl +
          "/app/device/runningInfoOfDay/" +
          this.$route.query.item +
          "/" +
          startDate +
          "/" +
          startDate1,
      })
        .then((res) => {
          localStorage.removeItem("ecry");
          localStorage.removeItem("ecsj");
          for (let i = 0; i < res.data.item.length; i++) {
            this.runtimeHours.push(
              Math.floor(res.data.item[i].runtimeHours * 100) / 100
            );
            this.calendarDayAssetLocalTime.push(
              this.$moment(res.data.item[i].calendarDayAssetLocalTime).format(
                "DD"
              )
            );
            this.runtimeFuelBurnedGallons.push(
              Math.floor(res.data.item[i].runtimeFuelBurnedGallons * 100) / 100
            );
          }
          this.downEchart(
            this.calendarDayAssetLocalTime,
            this.runtimeHours,
            this.runtimeHours
          ); //拿到数据再调用图表绘制
          this.ec(
            this.calendarDayAssetLocalTime,
            this.runtimeFuelBurnedGallons,
            this.runtimeFuelBurnedGallons
          );
          // localStorage.setItem(
          //   "ecry",
          //   JSON.stringify(this.runtimeFuelBurnedGallons)
          // );
          // localStorage.setItem(
          //   "ecsj",
          //   JSON.stringify(this.calendarDayAssetLocalTime)
          // );
          if (res.data.errcode == 40000) {
            this.$router.push("/login");
            Toast("登录失效请重新登陆");
          }
        })
        .catch((error) => {});
    },
    reload() {
      this.isReloadData = false;
      this.$nextTick(() => {
        this.isReloadData = true;
      });
    },
    back() {
      this.$router.push("/wdsb");
    },
    //图表
    downEchart(a, b, c) {
      var echarts = require("echarts");
      var chartDom = document.getElementById("main");

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("main")).setOption({
        color: ["#FE6AAC"],
        xAxis: {
          type: "category",
          data: a,
        },
        yAxis: {
          type: "value",
          splitNumber: "2",
          max: (Math.max(...c) + 5).toFixed(0),
          min: "0",
        },
        label: { show: true },
        series: [
          {
            data: b,
            type: "line",
            smooth: true,
          },
        ],
      });
    },
    //ec
    ec(a, b, c) {
      var echarts = require("echarts");
      var chartDom = document.getElementById("ad");

      // 使用刚指定的配置项和数据显示图表。
      echarts.init(document.getElementById("ad")).setOption({
        color: ["#fccc00"],
        xAxis: {
          type: "category",
          data: a,
        },
        label: { show: true },
        yAxis: {
          type: "value",
          splitNumber: "2",
          max: (Math.max(...c) + 30).toFixed(0),
          min: "0",
        },

        series: [
          {
            data: b,
            type: "line",
            smooth: true,
          },
        ],
      });
    },
  },
  mounted() {
    if (location.href.indexOf("#yybg") == -1) {
      location.href = location.href + "#yybg";
      location.reload();
    }
  },
};
</script>

<style lang="less" scoped>
#ad {
  width: 100%;
  height: 300px;
}
.rl {
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.yybg {
  .kf {
    position: fixed;
    bottom: 50px;
    right: 20px;
  }
  .ma10 {
    margin: 10px 0;
  }
  #main {
    width: 100%;
    height: 300px;
  }
  height: 100%;
  .main {
    .ec1 {
      .yh {
        padding: 10px 20px;
        .time {
          margin-left: 20px;
        }
        .hd {
          height: 10px;
          width: 10px;
          border-radius: 50%;
          border: none;
          background: #fccc00;
        }
        display: flex;
        justify-content: flex-start;
        justify-items: center;
      }
      background: #fff;
      border-radius: 10px;
    }
    padding: 10px;
    background: #f5f5f5;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #f5f5f5;
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  .fz12 {
    font-size: 12px;
  }
  .mt10 {
    margin-top: 10px;
  }
  .c99 {
    color: #999999;
  }
}
</style>