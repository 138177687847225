import { render, staticRenderFns } from "./Start.vue?vue&type=template&id=50a3eed7&scoped=true&"
import script from "./Start.vue?vue&type=script&lang=js&"
export * from "./Start.vue?vue&type=script&lang=js&"
import style0 from "./Start.vue?vue&type=style&index=0&id=50a3eed7&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a3eed7",
  null
  
)

export default component.exports