<template>
  <div class="my">
    <div class="mask" v-show="num1">
      <!-- 头部 -->
      <div class="tit">
        <div class="back" @click="index">
          <img src="@/assets/img/tm.png" />
        </div>
        <!-- 头像 -->
        <div class="tx">
          <img :src="avatar" class="tpic" @click="change" />
          <div class="nic">
            <div class="name fwb">{{ nickName }}</div>
            <div class="id fz12 fwb">
              <div class="mr20">DBSID:</div>
              <div class="mr20">{{ dbsId }}</div>
              <div class="bcol" @click="changes" v-show="dbsInfo.length">
                选择dbsId
              </div>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <div class="lb">
          <div class="sb" @click="sblb">
            <img src="@/assets/img/sblb.png" alt />
            <p class="fz12">设备列表</p>
          </div>
          <div class="sb" @click="kf(dbsId)">
            <img src="@/assets/img/zskf.png" alt />
            <p class="fz12">客户经理</p>
          </div>
          <div class="sb" @click="zlsz">
            <img src="@/assets/img/zlsz.png" alt />
            <p class="fz12">资料设置</p>
          </div>
          <!-- 个性化设置 -->
          <div class="sb" @click="gxsz">
            <img src="@/assets/img/gxsz.png" alt />
            <p class="fz12">个性设置</p>
          </div>
        </div>
      </div>
      <!-- 会员卡 -->
      <!-- <div class="vip" v-show="usermasg.topUser==true"> -->
      <div class="vip" v-show="false">
        <div class="vbox">
          <div class="vbtit">
            <div>
              <img src="../../assets/img/top.png" alt="" /><span
                style="margin-left: 20px"
                >{{ usermasg.topUserTitle }}
              </span>
            </div>
            <div class="vbr" v-show="false">专属权益</div>
          </div>
          <div class="vbtm">
            {{ usermasg.topUserShowMsg }}
          </div>
        </div>
      </div>
      <!-- 我的订单 -->
      <div class="dingd" v-show="!permission.includes('我的订单')">
        <div class="dtit" @click="wddy">
          <div class="fwb2">我的订单</div>
          <img src="@/assets/img/backr.png" alt />
        </div>
        <div class="dbtm">
          <div @click="dfk">
            <div>
              <img src="@/assets/img/dfk.png" alt />
            </div>
            <div>待付款</div>
          </div>
          <div @click="dfh">
            <div>
              <img src="@/assets/img/dfh.png" alt />
            </div>
            <div>待发货</div>
          </div>
          <div @click="dsh">
            <div>
              <img src="@/assets/img/dsh.png" alt />
            </div>
            <div>待收货</div>
          </div>
          <div @click="ywc">
            <div>
              <img src="@/assets/img/ywc.png" alt />
            </div>
            <div>已完成</div>
          </div>
        </div>
      </div>
      <!-- 个性设置 -->
      <div class="box" v-show="wpz.length != 0">
        <div class="fltit">
          <div>常用功能</div>
        </div>
        <div class="content">
          <div
            @click="go(item.identification)"
            class="tb"
            v-for="(item, index) in wpz"
            :key="index"
            v-show="!permission.includes(item.name)"
          >
            <img :src="picurl + item.icon" class="img" />
            <div class="itemtit">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 信贷中心 -->
      <!-- <div class="main" v-show="test == 1" v-if="permission.includes('信贷中心')" > -->
      <div
        class="main"
        :class="test != 1 ? 'none' : ''"
        v-if="!permission.includes(CreditCenter)"
      >
        <div class="xdzx">
          <!-- 进度条 -->
          <p class="fwb xdtit">{{ CreditCenter }}</p>
          <div class="mt20" v-for="(item, index) in data" :key="index">
            <div class="jdt">
              <div class="jdtit">
                <div class="fz12">
                  {{ LoanContract }}：{{ item.contractNo }}
                </div>
                <div class="fz12 c99" style="margin-top: 10px">
                  {{ LoanAmount }}：
                  <span>
                    ￥
                    <!-- toLocaleString() -->
                    {{
                      Number(item.contractAmount)
                        .toFixed(2)
                        .toString()
                        .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
                    }}
                  </span>
                </div>
              </div>
              <!-- <van-progress :percentage="50" color="#F8DD58" /> -->
            </div>

            <!-- 还款 -->
            <div class="hkjh" @click="xdzx(item, 1)">
              <div class="hl">
                <div class="hl1">
                  <img src="@/assets/img/hk1.png" alt />
                </div>
                <div class="hl2 ml15">{{ RepaymentPlan }}</div>
              </div>
              <div class="hr">
                <img src="@/assets/img/backr.png" alt />
              </div>
            </div>
            <!-- 信贷记录 -->
            <div class="hkjh" @click="xdzx(item, 2)">
              <div class="hl mt10">
                <div class="hl1">
                  <img src="@/assets/img/hk2.png" alt />
                </div>
                <div class="hl2 ml15">{{ CreditRecord }}</div>
              </div>
              <div class="hr">
                <img src="@/assets/img/backr.png" alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btms">
      <!-- <a class="btmss" @click="ysxy">《用户协议及隐私协议》</a> -->
      <a class="btmss" @click="ysxy">《隐私协议》</a>
    </div>
    <van-action-sheet
      v-model="cshow"
      cancel-text="取消"
      description="选择您要切换的dbsId"
      :actions="dbsInfo"
      @select="onSelect"
    />
  </div>
</template>

<script>
import furl from "@/utils/req";
import { ActionSheet } from "vant";
import tx from "@/assets/img/cat.jpg";
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import { Progress } from "vant";
import { Loading } from "vant";
import { Toast } from "vant";
import Tips from "@/components/Tips.vue";
import { ref } from "vue";
import { DropdownMenu, DropdownItem } from "vant";

Vue.use(ActionSheet);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Progress);
export default {
  components: {
    Tips,
  },

  data() {
    return {
      wpz: [],
      CreditCenter: "",
      LoanContract: "",
      LoanAmount: "",
      RepaymentPlan: "",
      CreditRecord: "",
      cshow: false,
      dbsInfo: [],
      value1: 0,
      test: 1,
      loding: true,
      data: [],
      nickName: "",
      dbsId: "",
      avatar: tx,
      sydk: "",
      show: true,
      num1: true,
      isshow: false,
      isshow2: false,
      permission: [],
      usermasg: {},
    };
  },
  created() {
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/userInfo/v2" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    var dbs = "";
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/user/userInfo/v2",
    })
      .then((res) => {
        this.usermasg = res.data.item;
        let arr = res.data.item.dbsInfo;
        this.CreditCenter = res.data.item.creditCenter;
        this.LoanContract = res.data.item.loanContract;
        this.LoanAmount = res.data.item.loanAmount;
        this.RepaymentPlan = res.data.item.repaymentPlan;
        this.CreditRecord = res.data.item.creditRecord;
        arr = arr.map((item) => {
          return { name: item.dbsId, subname: item.company };
        });
        this.dbsInfo = arr;
        localStorage.setItem(
          "permission",
          JSON.stringify(res.data.item.permission)
        );
        this.permission = res.data.item.permission;
        if (res.data.errcode == 40000) {
          this.change();
          localStorage.clear();
          Toast("登录失效请重新登陆");
          this.$router.push("/login");
        }
        if (res.data.item.nickName == null || res.data.item.nickName == "") {
          this.nickName = res.data.item.phone;
          localStorage.setItem("phonenum", res.data.item.phone);
        } else {
          this.nickName = res.data.item.nickName;
          localStorage.setItem("nickname", res.data.item.nickName);
        }

        if (res.data.item.avatar.length > 3) {
          this.avatar = this.picurl + res.data.item.avatar;
        } else {
        }
        this.dbsId = res.data.item.dbsId;
        //贷款合同请求
        axios({
          headers: {
            "Content-Type": "multipart/form-data;",
            token: token,
            timestamp: timestamp,
            nonce: nonce,
            sign: sj,
          },
          body: {
            params: datas,
          },
          method: "get",
          url: this.reurl + "/app/loan/contracts/" + this.dbsId,
        })
          .then((res) => {
            // this.show = false;
            this.data = res.data.item;
            if (this.data.length == 0) {
              this.test = 0;
            }
          })
          .catch((error) => {
            // this.show = false;
            this.test = 0;
          });
      })
      .catch((error) => {});
    this.action();
  },
  updated() {},
  methods: {
    go(e) {
      if (e == "/jdcx") {
        // Toast("该功能暂未开放");
        this.$router.push("/jdcx");
      } else if (e == "/yjbx") {
       // Toast("该功能暂未开放");
       this.$router.push(e);
      } else if (e == "dbsid") {
        this.$router.push("/zskf?item=" + this.infodbs);
      } else if (e == "/my") {
        if (this.infodbs != "") {
          this.$router.push(e);
        } else {
          Toast("您还暂无信贷信息");
        }
      } else if (e == "/ljgm" || e=="/rmxj") {
        // this.$router.push("/vx");
        Toast("暂未开放");
      }else if(e=="/rmxj"){
        this.ticket();
      } else {
        this.$router.push(e);
      }
    },
    //获取tick
     //获取ticket
    ticket() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        url: this.reurl + "/user/weimob/appTicket",
      }).then((res) => {
        localStorage.setItem("sxsc", res.data.item.appTicket);
        this.$router.push(e);
      });
    },
    //获取常用功能
    action() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/userPersonalise/getNoPickPersonaliseList",
      }).then((res) => {
        this.wpz = res.data.item;
      });
    },
    ysxy() {
      this.$router.push("/ysxy");
    },
    //个人信息
    info() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/userInfo/v2",
      }).then((res) => {
        this.usermasg = res.data.item;
        let arr = res.data.item.dbsInfo;
        arr = arr.map((item) => {
          return { name: item.dbsId, subname: item.company };
        });
        this.dbsInfo = arr;
        localStorage.setItem(
          "permission",
          JSON.stringify(res.data.item.permission)
        );
        this.permission = res.data.item.permission;
        if (res.data.errcode == 40000) {
          this.change();
          localStorage.clear();
          Toast("登录失效请重新登陆");
          this.$router.push("/login");
        }
        if (res.data.item.nickName == null || res.data.item.nickName == "") {
          this.nickName = res.data.item.phone;
          localStorage.setItem("phonenum", res.data.item.phone);
        } else {
          this.nickName = res.data.item.nickName;
        }

        if (res.data.item.avatar.length > 3) {
          this.avatar = this.picurl + res.data.item.avatar;
        } else {
        }
        this.dbsId = res.data.item.dbsId;
        axios({
          headers: {
            "Content-Type": "multipart/form-data;",
            token: token,
            timestamp: timestamp,
            nonce: nonce,
            sign: sj,
          },
          body: {
            params: datas,
          },
          method: "get",
          url: this.reurl + "/app/loan/contracts/" + res.data.item.dbsId,
        }).then((res) => {
          // this.show = false;
          this.data = res.data.item;
          if (this.data.length == 0) {
            this.test = 0;
          } else {
            this.test = 1;
          }
        });
      });
    },
    //上传dbs
    updateUserDbs(a) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {
        dbsId: a,
      };
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/updateUserDbs",
      }).then((res) => {
        if (res.data.errmsg == "成功") {
          Toast("更换成功");
          this.info();
        } else {
          Toast("更换失败");
        }
      });
    },
    //面板
    onSelect(item) {
      this.cshow = false;
      this.updateUserDbs(item.name);
    },
    //选择dbsIdc
    changes() {
      this.cshow = true;
    },
    //我的订单
    wddy() {
      this.$router.push("/wddy");
      localStorage.setItem("wddy", 0);
    },
    //待付款
    dfk() {
      this.$router.push("/wddy");
      localStorage.setItem("wddy", 0);
    },
    //待发货
    dfh() {
      this.$router.push("/wddy");
      localStorage.setItem("wddy", 1);
    },
    //待收货
    dsh() {
      this.$router.push("/wddy");
      localStorage.setItem("wddy", 2);
    },
    //已完成
    ywc() {
      this.$router.push("/wddy");
      localStorage.setItem("wddy", 3);
    },
    //资料设置
    zlsz() {
      this.$router.push("/xgzl");
    },
    //个性设置
    gxsz() {
      this.$router.push("/gxsz");
      //Toast("系统连接中，即将开放");
    },
    //专属客服
    kf(e) {
      this.$router.push({
        path: "/zskf",
        query: {
          item: e,
        },
      });
    },
    //回到首页
    index() {
      this.$router.push("/index");
    },
    //设备列表
    sblb() {
      this.$router.push("/wdsb");
    },
    //修改资料
    change() {
      this.$router.push("/txxg");
    },
    //信贷中心
    xdzx(e, b) {
      localStorage.setItem("b", b);
      this.$router.push({
        path: "/xdzx",
        query: {
          item: e,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .itemtit {
    font-size: 12px;
    margin-top: 6px;
  }
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  margin: 10px;
  .content {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .tb {
      width: 80px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        background: red;
        font-weight: bold;
        color: #fff;
        padding: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        position: absolute;
        text-align: center;
        line-height: 16px;
        margin: 0;
        right: -5px;
        top: -3px;
      }
      position: relative;
      .img {
        width: 36px;
        height: 36px;
      }
    }
  }
  h4 {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
  }
}
.vip {
  .vbox {
    .vbtm {
      font-size: 13px;
      line-height: 18px;
      color: #999999;
      margin-top: 10px;
      text-indent: 18px;
    }

    .vbtit {
      .vbr {
        background: #fff;
        border-radius: 10px;
        padding: 5px 10px;
        font-weight: 500;
        color: #000;
      }

      color: #fff;
      font-size: 14px;

      img {
        width: 40px;
        vertical-align: middle;
      }

      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px dashed #4e4e4e;
    }

    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }

  // background: #000;
  height: 140px;
  // margin-top: 20px;
  // border-radius: 10px 10px 10% 10%;
  // z-index: -99;
  padding: 10px;
  background: url(../../assets/img/mybg2.png);
  background-size: 100% 100%;
}

.btms {
  width: 100%;
  background: #f5f5f5;
  height: 60px;
  text-align: center;
  position: fixed;
  bottom: 0;

  .btmss {
    font-size: 12px;
    color: #2676d0;
    line-height: 60px;
  }
}

.bcol {
  border-radius: 3px;
  border: 1px solid black;
  color: #000;
  padding: 3px 5px;
}

.mr20 {
  margin-right: 20px;
}

.id {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/deep/.van-dropdown-menu__bar {
  width: 100%;
  height: 20px;
  margin-left: 20px;
}

option {
  border: none;
}

.none {
  display: none;
}

.newmask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .mmaskpic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
  }
}

.dingd {
  .dbtm {
    margin-top: 10px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }

  .dtit {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  margin: 0 10px;
  margin-top: 10px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
}

.jzz {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  margin-left: -20px;
}

.my {
  img {
    vertical-align: text-bottom;
  }

  .mask {
    background: #f5f5f5;
    overflow: auto;

    .main {
      .hkjh {
        margin-top: 20px;
        font-size: 14px;

        .hl {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        display: flex;
        justify-content: space-between;
      }

      .xdzx {
        .jdt {
          .jdtit {
            margin-bottom: 10px;
          }

          background: #fffdf5;
          padding-bottom: 1px;
          padding-top: 10px;
          border-radius: 5px;
        }

        .xdtit {
          font-weight: 540;
          margin-bottom: 10px;
        }

        border-radius: 10px;
        background: #fff;
        padding: 10px;
      }

      padding: 10px;
      padding-bottom: 60px;
    }

    .tit {
      .lb {
        margin-top: 20px;

        .sb {
          line-height: 25px;
          display: flex;
          align-items: center;
          flex-direction: column;
        }

        display: flex;
        justify-content: space-around;
      }

      .tx {
        .nic {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        display: flex;
        justify-content: flex-start;
        margin-top: 10px;

        .tpic {
          margin-right: 20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }

      padding: 10px;
      background: rgb(255, 205, 17);
    }

    .fwb {
      font-weight: bold;
    }

    .fz12 {
      font-size: 12px;
    }

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f5f5f5;
  }

  .c99 {
    color: #999999;
  }

  .mt10 {
    margin-top: 10px;
  }

  .ml15 {
    margin-left: 15px;
  }

  .van-uploader__wrapper {
    width: 100% !important;
  }

  .mt20 {
    margin-top: 20px;
  }

  .fwb {
    font-weight: 540;
  }
}
</style>