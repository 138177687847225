<template>
  <div class="wdsb" style="background: #f5f5f5; height: 100%">
    <Tips />
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      我的设备
    </div>
    <!-- 设备详细 -->
    <div class="content">
      <img class="kf" @click="kef" src="@/assets/img/kefu.png" alt />
      <div class="box" v-for="(item, index) in data" :key="index">
        <div class="mysb mb10" @click="tolist(item)">
          <p>{{ item.makeCode }} {{ item.model }} {{ item.name }}</p>
          <p class="fz18">></p>
        </div>
        <p class="tit">
          型号: {{ item.model }}｜序列号：{{ item.serialNumber }}
        </p>
        <p class="tit">是否租赁: {{ item.isRental == 1 ? "是" : "否" }}</p>
        <p class="tit" v-show="item.rentalEquipmentList.length > 0">
          购买时期:{{ item.purchaseTime }}
        </p>
        <div class="tit" v-show="item.isRental == 1">
          <div class="htshow">
            <p>查看合同信息</p>
            <p v-show="htshow != index" @click="htshow = index">&#9660;</p>
            <p v-show="htshow == index" @click="htshow = -1">&#9650;</p>
          </div>
          <div
            v-show="htshow == index"
            v-for="(val, i) in item.rentalEquipmentList"
            :key="i"
          >
            <p class="tit">联系人:{{ val.contacts }}</p>
            <p class="tit">租户名称:{{ val.customerName }}</p>
            <p class="tit">合同日期:{{ val.startTime }}至{{val.endTime}}</p>
          </div>
        </div>
        <div class="bcon">
          <img :src="picurl + item.imgUrl" class="bcleft" />
          <div class="bcright">
            <div class="rtop">
              <div class="rtnav tcenter" @click="yybg(item.serialNumber)">
                <img class="mb5" src="@/assets/img/yx.png" alt />
                <p class="fz12 fwb">运行报告</p>
              </div>
              <div class="rtnav tcenter" @click="cva(item)">
                <img class="mb5" src="@/assets/img/cva.png" alt />
                <p class="fz12 fwb">CVA&EPP</p>
              </div>
              <div class="rtnav tcenter" @click="yjtx(item)">
                <img class="mb5" src="@/assets/img/yj.png" alt />
                <p class="fz12 fwb">预警提醒</p>
                <div
                  class="isHaveWarning"
                  :class="item.isHaveWarning != 0 ? 'bred' : ''"
                >
                  {{ item.isHaveWarning }}
                </div>
              </div>
            </div>
            <!-- <div class="rbtm tcenter">
              <div class="by">保养包</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="newmask" v-if="false">
      <van-loading type="spinner" color="rgb(255, 205, 17)" class="mmaskpic" />
    </div>
    <img src="../../assets/img/zw.png" class="zw" v-show="isshow" />
  </div>
</template>

<script>
import tx from "@/assets/img/cs.jpg";
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
import { Toast } from "vant";
import Tips from "@/components/Tips.vue";
Vue.use(Toast);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      htshow: -1,
      sbName: "通用设备",
      pic: tx,
      data: [],
      show: true,
      isshow: false,
    };
  },
  created() {
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=100" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/app/device/deviceListV2?page=1&pageSize=100",
    })
      .then((res) => {
        res.data.item.data.length == 0
          ? (this.isshow = true)
          : (this.isshow = false);
        this.show = false;
        if (res.data.errcode == 40000) {
          localStorage.clear();
          Toast("登录失效请重新登陆");
          this.$router.push("/login");
        }

        this.data = res.data.item.data;
        for (var i = 0; i < this.data.length; i++) {
          if (this.data[i].name == null) {
            this.data[i].name = "通用设备";
          }
          if (this.data[i].imgUrl == null) {
            this.data[i].imgUrl = "/deviceImg/default.jpg";
          }
        }
      })
      .catch((error) => {});
  },
  methods: {
    //客服
    kef() {
      this.$router.push("/cssc");
    },
    //返回上页面
    back() {
      this.$router.push("/index");
    },
    //设备详情
    tolist(item) {
      localStorage.setItem("wdsb", JSON.stringify(item));
      this.$router.push({
        path: "/sblist",
        query: {
          item: item,
        },
      });
    },
    //运营报告
    yybg(e) {
      this.$router.push({
        path: "/yybg",
        query: {
          item: e,
        },
      });
    },
    //cva
    cva(item) {
      this.$router.push({
        path: "/cva",
        query: {
          item: item,
        },
      });
    },
    //预警体系
    yjtx(item) {
      this.$router.push({
        path: "/yjtx",
        query: {
          item: item.serialNumber,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.htshow {
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.zw {
  width: 100%;
  margin-top: 40px;
}
.newmask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .mmaskpic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
  }
}
.isHaveWarning {
  display: none;
}
.bred {
  display: block;
  border-radius: 9px;
  background: red;
  color: #fff;
  font-weight: bold;
  position: absolute;
  right: 2px;
  width: 18px;
  height: 18px;
  font-size: 1px;
  line-height: 18px;
  top: -5px;
}
.wdsb {
  height: 100%;
  .fz18 {
    font-size: 18px;
  }
  .kf {
    position: fixed;
    bottom: 50px;
    right: 20px;
  }
  .fz12 {
    font-size: 12px;
  }
  .fwb {
    font-weight: bold;
  }
  .tcenter {
    position: relative;
    text-align: center;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mb5 {
    margin-bottom: 5px;
  }
  height: 100%;
  .content {
    .box {
      .mysb {
        display: flex;
        justify-content: space-between;
      }
      margin-bottom: 15px;
      border-radius: 10px;
      .bcon {
        .bcright {
          width: 100%;
          .rbtm {
            display: flex;
            justify-content: center;
            .by {
              width: 190px;
              background: rgb(255, 205, 17);
              height: 30px;
              line-height: 30px;
              font-weight: bold;
              border-radius: 5px;
            }
          }
          .rtop {
            display: flex;
            justify-content: space-around;
          }
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        display: flex;
        justify-content: space-between;
        width: 100%;
        .bcleft {
          height: 90px;
          width: 90px;
        }
      }
      .tit {
        margin-bottom: 15px;
        color: #333333;
        font-size: 13px;
        font-weight: bold;
      }
      background: #fff;
      padding: 10px 20px;
    }
    background: #f5f5f5;
    padding: 10px;
  }
  .header {
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
}
</style>