<template>
  <div class="zxxq">
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" id="close_page" />
      </div>
      <span class="fwb">资讯详情</span>
    </div>
    <div class="main">
      <div class="tit">{{ data.newsTitle }}</div>
      <div class="name">
        <span>{{ data.authorName }}</span>
        <span class="ccc ml20">{{ data.releaseTime }}</span>
      </div>
      <div class="readtit">
        <div v-show="num == 1" @click="next" class="ck" style="fon-size: 12px">
          阅读原文
        </div>
        <div class="read">阅读量：{{ data.readTimes + 1 }}</div>
      </div>
      <div>
        <div v-html="data.newsContent" class="body"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "../assets/js/jquery-3.5.1.min"
import LoginVue from "./Login.vue";
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
export default {
  data() {
    return {
      num: "",
      data: "",
      a: "",
      url: "",
      furl: "http://customerapp.eci-metro.cn/ecim",
    };
  },
  created() {
    this.data = JSON.parse(localStorage.getItem("zx"));
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/sendSms" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      // transformRequest: [
      // function (data) {
      // 对 data 进行任意转换处理
      // return JSON.stringify(datas);
      // },
      // ],
      url: this.reurl + "/app/news/addReadTimes?id=" + this.data.id + "&type=1",
    }).then((res) => {});
  },
  methods: {
    back() {
      history.go(-1);
    },
    info() {
      var a = document.querySelectorAll(".body>p>a");
      this.a = a[0].href;
      a[0].href = "javascript:;";
      a[0].target = "_self";
      if (a[0].href == undefined) {
        this.num = 0;
      } else {
        if (this.a.length > 8) {
          this.num = 1;
        }
      }
    },
    next() {
      this.$router.push({
        path: "/zxh",
        query: {
          item: this.a,
        },
      });
    },
  },
  mounted() {
    $(".body").find("p").css({
      width: "100%",
      height: "100%",
    });
    $(".body").find("img").css({
      width: "100%",
      height: "100%",
    });
    $(".body").find("a").css({
      width: "100%",
      height: "100%",
      display:"none"
    });
    this.info();
  },
  updated() {},
};
</script>

<style lang="less" scoped>
.readtit {
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ck {
  text-align: right;
  color: #2676d0;
}

.read {
  font-size: 12px;
  color: #cccccc;
}
.zxxq {
  .main {
    .body {
      width: 100%;
      height: 100%;
    }
    .name {
      color: #2676d0;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 12px;
    }
    .tit {
      text-align: center;
      font-weight: bold;
      font-size: 20px;
    }
    padding: 10px;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: 500;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  .fwb {
    font-weight: bold;
  }
  .ccc {
    color: #cccccc;
  }
  .ml20 {
    margin-left: 20px;
  }
}
</style>