<template>
  <div class="tjcg">
    <Tips />
    <div class="zz">
      <div class="header">
        <div class="back" @click="back">
          <img class="pic" src="@/assets/img/mback.png" v-show="false" />
        </div>提交成功
      </div>
      <div class="main">
        <div class="tips">
          <img src="@/assets/img/cgwc.png" alt />
        </div>
        <div class="tit">
          <p class="fz18">订单提交成功</p>
          <p class="fz14 mt10 c99">稍后会有维修师傅联系您，请耐心等待！</p>
        </div>
        <!-- 进度条 -->
        <div class="prob">
        <van-steps direction="vertical" active-color="rgb(255, 205, 17)" :active="0">
          <van-step>
            <h3>操作成功</h3>
            <p>请保持电话畅通工作人员和你联系</p>
          </van-step>
          <van-step>
            <h3>维修师傅正在快马加鞭赶来！</h3>
            <p></p>
          </van-step>
          <van-step>
            <h3>完成</h3>
          </van-step>
          <van-step>
            <h3>待评价</h3>
            <p>请对本次服务作出评价，易初明通感谢您的支持。</p>
          </van-step>
        </van-steps>
        </div>
      </div>
      <div class="footer">
        <div class="jd" @click="cxjd">查询进度</div>
        <div class="index" @click="index">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";  
import { Step, Steps } from "vant";
import Tips from "@/components/Tips.vue";
import { Toast } from "vant";
Vue.use(Toast);
Vue.use(Step);
Vue.use(Steps);
export default {
  components: {
    Tips,
  },
  data() {
    return {};
  },
  methods: {
    //返回上个页面
    back() {
      this.$router.push("/Yjbx");
    },
    //回到首页
    index() {
      this.$router.push("/index");
    },
    //查询进度
    cxjd() {
      this.$router.push("/jdcx");
      // Toast("系统连接中，即将开放")
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-steps{
  background: none;
}
.tjcg {
  .zz {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    .main {
      height: 100%;
      flex: 1;
      overflow: hidden;
      overflow-y: scroll;
      .prob {
        padding: 10px;
        border-radius: 10px;
      }
      .tit {
        line-height: 20px;
        text-align: center;
        margin-top: 20px;
      }
      height: 100%;
      .tips {
        margin: auto;
        text-align: center;
        margin-top: 80px;
      }
    }
    .footer {
      background: #f5f5f5;
      // position: absolute;
      // bottom: 0;
      width: 100%;
      box-sizing: border-box;
      .index {
        height: 50px;
        border-radius: 10px;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
        background: #fff;
        margin-bottom: 10px;
      }
      .jd {
        height: 50px;
        border-radius: 10px;
        line-height: 50px;
        font-weight: bold;
        text-align: center;
        background: rgb(255, 205, 17) ;
        margin-bottom: 10px;
      }
      padding: 10px;
    }
    background: #f5f5f5;
    height: 100%;
    width: 100%;
    .header {
      background: #fff;
      line-height: 44px;
      font-weight: bold;
      height: 44px;
      width: 100%;
      text-align: center;
      .back {
        position: absolute;
        font-size: 24px;
        color: #fff;
        left: 15px;
        top: 3px;
        .pic {
          height: 22px;
          width: 22px;
        }
      }
    }
  }

  .mt10 {
    margin-top: 10px;
  }
  .c99 {
    color: #999999;
  }
  .c9b {
    color: #9b9b9b;
  }
  .fwb {
    font-weight: bold;
  }
  .fwber {
    font-weight: bolder;
  }
  .fz12 {
    font-size: 12px;
  }
  .fz14 {
    font-size: 14px;
  }
  .fz18 {
    font-size: 18px;
  }
  .fz20 {
    font-size: 20px;
  }
  .lh20 {
    line-height: 20px;
  }
  .mt20 {
    margin-top: 20px;
  }
}
</style>