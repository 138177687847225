<template>
  <div>
    <div id="ad"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sj: "",
      ry: "",
    };
  },
  created() {
  },
  mounted() {
    this.sj = JSON.parse(localStorage.getItem("ecsj"));
    this.ry = JSON.parse(localStorage.getItem("ecry"));
  this.ec(this.sj,this.ry)
  },
  methods: {
    ec(a, b) {
      var echarts = require("echarts");
      var chartDom = document.getElementById("ad");
      var myChart = echarts.init(chartDom);
      var option;
      //第一个图表
      option = {
        color: ["red"],
        xAxis: {
          type: "category",
          data: a,
          color: "red",
        },
        yAxis: {
          type: "value",
          splitNumber: "2",
          max: "150",
          min: "1",
          color: "red",
        },

        series: [
          {
            data: b,
            type: "line",
            smooth: true,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
#ad {
  width: 100%;
  height: 300px;
}
</style>