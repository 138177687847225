<template>
  <div class="xdzx">
    <div class="mask">
      <!-- 返回 -->
      <div class="header">
        <div class="back" @click="back">
          <img class="pic" src="@/assets/img/mback.png" alt />
        </div>
        <span style="font-weight: bold">{{ creditCenterName }}</span>
      </div>
      <!-- 剩余贷款总额 -->
      <div class="tit">
        <p class="fz14 ls1">{{ totalRemainingLoansName }}</p>
        <div class="tbtm">
          <!-- 剩余贷款总额度 -->
          <div class="left fz24">￥{{ sydk }}</div>
          <!-- <div class="right fz14">{{data.contractLoanAmount}}</div> -->
        </div>
      </div>
      <!-- 选项卡 -->
      <div class="main">
        <div class="mtit">
          <div @click="x1" class="m10" :class="active == 1 ? 'active' : ''">
            {{ repaymentPlanName }}
          </div>
          <div @click="x2" class="m10" :class="active == 2 ? 'active' : ''">
            {{ creditRecordName }}
          </div>
        </div>
        <div class="box" v-show="active == 1">
          <div class="top">
            <div class="ttit c66 fz14">
              <div>{{ contractNoName }}：{{ data.contractNo }}</div>
              <div @click="lszd"></div>
            </div>
            <div class="tcon">
              <p class="fz14">{{ currentBillAmountName }}</p>
              <p class="fz12 c99 ml10">
                还款日: {{ data.receiptDate | dateformat("YYYY/MM/DD") }}
              </p>
            </div>
            <div class="tfooter fz24 mt20">
              ￥
              {{
                  Number(bqzd)
                    .toFixed(2)
                    .toString()
                    .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
              }}
            </div>
          </div>
          <div class="bottom">
            <div class="btit">
              <p class="fz12">{{ currentRepaymentProgressName }}</p>
              <p class="fz12">
                {{ totalName }}：
                {{
                    Number(data.contractAmount)
                      .toFixed(2)
                      .toString()
                      .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
                }}
              </p>
            </div>
            <van-progress class="jd" color="#FCCC00" :percentage="percentage" v-if="active == 1" />
          </div>
        </div>
        <div class="bcf5" v-show="active == 2">
          <div class="gg"></div>
          <div class="cbox c2" v-for="(item, index) in data2" :key="index">
            <div class="flexs fwb">
              <div>{{contractNoName}}： {{ item.contractNo }}</div>
              <div style="margin-top: 10px" :class="
                Number(item.curReceivableMoney - item.actualMoney)
                  .toFixed(2)
                  .toString()
                  .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,') == 0
                  ? 'changec'
                  : 'changec1'
              ">
                {{
                    Number(item.curReceivableMoney - item.actualMoney)
                      .toFixed(2)
                      .toString()
                      .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,") == 0
                      ? "已还清"
                      : "未还清"
                }}
              </div>
            </div>
            <div class="flex">
              <div>本次账单</div>
              <div class="fz14 c99">
                ¥
                {{
                    Number(item.curReceivableMoney)
                      .toFixed(2)
                      .toString()
                      .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
                }}
              </div>
            </div>
            <div class="flex">
              <div>还款日期</div>
              <div class="fz14 c99">
                {{ item.receivableDate | dateformat("YYYY/MM/DD") }}
              </div>
            </div>
            <div class="flex">
              <div>本期已还</div>
              <div class="fz14 c99">
                ¥
                {{
                    Number(item.actualMoney)
                      .toFixed(2)
                      .toString()
                      .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
                }}
              </div>
            </div>
            <div class="flex">
              <div>本期剩余</div>
              <div class="fz14 c99">
                {{
                    Number(item.curReceivableMoney - item.actualMoney)
                      .toFixed(2)
                      .toString()
                      .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 隐私协议 -->
    </div>

  </div>
</template>

<script>
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import Vue from "vue";
import { Tab, Tabs } from "vant";
import Tips from "@/components/Tips.vue";
Vue.use(Tab);
Vue.use(Tabs);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      contractNoName: "",
      creditCenterName: "",
      creditRecordName: "",
      currentBillAmountName: "",
      currentRepaymentProgressName: "",
      repaymentPlanName: "",
      totalName: "",
      totalRemainingLoansName: "",
      contractNo: "",
      data: [],
      active: 1,
      item: {},
      sydk: "/",
      percentage: "0",
      bqzd: "/",
      data2: [],
      bqhk: "-",
      syhk: "-",
      yj: "-",
      yh: "-",
      zt: "还款中",
      a: "-",
    };
  },
  methods: {
    ysxy() {
      this.$router.push("/ysxy");
    },
    x1() {
      this.active = 1;
    },
    x2() {
      this.active = 2;
    },
    //回到上个页面
    back() {
      this.$router.push("/my");
    },
    //历史账单
    lszd() {
      this.$router.push("/lszd");
    },
  },
  created() {
    this.active = localStorage.getItem("b");
    this.item = this.$route.query.item;
    this.contractNo = this.$route.query.item.contractNo;
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/userInfo" +
      timestamp +
      Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    //还款计划
    axios({
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      url:
        "http://customerapp.eci-metro.cn/ecim/app/loan/receiptListStages?contractNo=" +
        this.contractNo,
    }).then((res) => {
      console.log(res.data.item);
      this.data = res.data.item;
      this.contractNoName = res.data.item.contractNoName;
      this.creditCenterName = res.data.item.creditCenterName;
      this.creditRecordName = res.data.item.creditRecordName;
      this.currentBillAmountName = res.data.item.currentBillAmountName;
      this.currentRepaymentProgressName = res.data.item.currentRepaymentProgressName;
      this.repaymentPlanName = res.data.item.repaymentPlanName;
      this.totalName = res.data.item.totalName;
      this.totalRemainingLoansName = res.data.item.totalRemainingLoansName;


      let yh = 0;
      for (let i = 0; i < this.data.repaid.length; i++) {
        yh = yh + this.data.repaid[i].actualAmountSum;
      }
      //当前合同已还款项
      this.yh = yh;
      //当前合同剩余贷款
      if (res.data.item.contractAmount - yh == 0) {
        this.sydk = "全部已还清";
      } else {
        this.sydk = res.data.item.contractAmount - yh;
      }
      //本期账单应还多少
      this.bqzd = res.data.item.repaid[0].receiptAmount;
      //当月已还金额
      let a = 0;
      for (let i = 0; i < this.data.repaid[0].actualDataList.length; i++) {
        a = a + this.data.repaid[0].actualDataList[i].actualMoney;
      }
      this.a = a;
      // dyyh);
      //当月剩余还款金额
      if (this.bqzd - a < 0) {
        this.syhk = 0;
      } else {
        this.syhk = this.bqzd - a;
      }
      //还款状态
      if (this.syhk == 0) {
        this.zt = "本期还清";
      }
      //还款进度条
      if (this.data.repaymentPercentage == 1) {
        this.percentage = 100;
      } else {
        this.percentage = yh / res.data.item.contractAmount;
      }
      // this.percentage);
    });
    //信贷记录
    axios({
      headers: {
        "Content-Type": "multipart/form-data",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      url:
        "http://customerapp.eci-metro.cn/ecim/app/loan/receiptDetailLine?contractNo=" +
        this.contractNo,
    })
      .then((res) => {
        this.data2 = res.data.item;
      })
      .catch((error) => { });
  },
};
</script>

<style lang="less" scoped>
#btms {
  position: absolute;
  z-index: 99999;
}

.mt10 {
  margin-top: 10px;
}

.flexs {
  font-size: 14px;
}

.changec {
  color: #1bbc9b;
}

.changec1 {
  color: #f25643;
}

.m10 {
  margin: 10px 0;
}

.mmt {
  margin-top: 40px;
  background: #fff;
}

.bcf5 {
  background: #fff;
  height: 100%;
}

.c2 {
  margin-top: 10px !important;
}

.cbox {
  margin: 0 10px;

  .flex {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
  }

  background: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
}

.active {
  border-bottom: 2px solid rgb(255, 205, 17);
  padding-bottom: 5px;
}

.box {
  .bottom {
    background: #fffdf5;

    .jd {
      margin-bottom: 10px;
    }

    .btit {
      padding: 0 10px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }

  .top {
    background-image: url(../..//assets/img/xdbcg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px;

    .tcon {
      display: flex;
      justify-content: flex-start;
      margin-top: 20px;
      align-items: center;
    }

    .ttit {
      display: flex;
      justify-content: space-between;
    }
  }

  margin-top: 20px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
}

.hq {
  color: #1bbc9b;
}

.zt {
  color: #f25643;
}

.xdzx {
  .mask {
    .main {
      .mtit {
        display: flex;
        justify-content: space-around;
      }

      margin-top: 10px;
    }

    .tit {
      background: #f5f5f5 !important;

      .tbtm {
        .right {
          color: #999999;
        }

        display: flex;
        line-height: 60px;
        justify-content: space-between;
      }

      margin: 0 10px;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
    }

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #f5f5f5;

    .header {
      margin-bottom: 20px;
      background: #fff;
      line-height: 44px;
      font-weight: 500;
      height: 44px;
      width: 100%;
      text-align: center;

      .back {
        position: absolute;
        font-size: 24px;
        color: #fff;
        left: 15px;
        top: 3px;

        .pic {
          height: 22px;
          width: 22px;
        }
      }
    }

    height: 100%;
    background: #fff;
  }

  width: 100%;
  height: 100%;

  .fz14 {
    font-size: 14px;
  }

  .fz24 {
    font-size: 24px;
    font-weight: 500;
  }

  .van-tabs__wrap {
    background: #f5f5f5;
  }

  .c66 {
    color: #666666;
  }

  .fz12 {
    font-size: 12px;
  }

  .c99 {
    color: #999999;
  }

  .ml10 {
    margin-left: 10px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .mt10 {
    margin-top: 10px;
  }

  .gg {
    background: #fff;
    height: 10px;
  }

  .c2 {
    margin-top: 10px !important;
  }

  .ls1 {
    letter-spacing: 1px;
  }

  .fwb {
    font-weight: bold;
  }
}
</style>