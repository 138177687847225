<template>
  <div>
    <!-- <input type="text" v-model="srk" placeholder="请输入手机号码" />
    <div class="sanchu" @click="sanchu">删除</div>

    <div class="btn" @click="btn">确定手机号码饼登录</div> -->
    <div>
      我是姓<input type="text" v-model="xing" />
    </div>
    我是名<input type="text" v-model="ming" />
    <div>我是姓名: {{xingming}}</div>
  </div>
</template>

<script>
export default {
  data(){
      return {
        // srk:"",
       xing:"",
       ming:"",
       xingming:""
      }
      
  },
  computed:{
    
  },
  updated(){
    this.xingming=this.xing+this.ming
  }
//   methods{
//       sanchu(){
//       this.srk=""
//       },
//       btn(){
          
//            this.srk);

//       },
//   }
};
</script>

<style lang="less" scoped>
</style>