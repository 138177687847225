<template>
  <div class="wddy">
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      我的订单
    </div>
    <div class="nav">
      <div :class="active == 0 ? 'active' : ''" @click="dzf">待支付</div>
      <div :class="active == 1 ? 'active' : ''" @click="dfh">待发货</div>
      <div :class="active == 2 ? 'active' : ''" @click="yfh">已发货</div>
      <div :class="active == 3 ? 'active' : ''" @click="ywc">已完成</div>
    </div>
    <div class="main" v-show="show">
      <div
        class="con"
        v-for="(item, index) in orders"
        :key="index"
        @click="go(item)"
      >
        <div class="ctit">
          <div style="font-size: 12px">
            下单时间:{{ item.createTime | dateformat("YYYY/MM/DD日 HH点") }}
          </div>
          <div style="color: #f25643; font-size: 14px">
            {{ item.orderStatusName }}
          </div>
        </div>
        <div class="cmain">
          <img :src="item.itemList[0].imageUrl" class="pic" />
          <div class="cright">
            <div class="crtit">
              <div class="name">{{ item.itemList[0].goodsTitle }}</div>
              <p>￥{{ item.paymentAmount }}</p>
            </div>
            <div style="margin-top: 20px">x{{ item.itemList[0].skuNum }}</div>
            <div style="font-size: 14px; margin-top: 10px">
              实付：
              <span style="color: #fccc00">￥{{ item.paymentAmount }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/img/zw.png" class="zw" v-show="pic" />
  </div>
</template>

<script>
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
export default {
  data() {
    return {
      isshow: true,
      pic: false,
      active: 0,
      img: "http://customerapp.eci-metro.cn/ecim", // "http://10.208.120.107:",
      data: [],
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100",
      orders: [],
      ticks: "",
      show:false
    };
  },
  methods: {
    //获取商城tick
    tick() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/weimob/appTicket",
      }).then((res) => {
        this.ticks = res.data.item.appTicket;
        localStorage.setItem("sxsc",this.ticks)
      });
    },
    //查看详情
    go(e) {
      this.$router.push({
        path: "/ddzx",
        query: {
          item: e,
        },
      });
    },
    //已完成
    ywc() {
      this.active = 3;
      this.wddy(this.active);
    },
    //已发货
    yfh() {
      this.active = 2;
      this.wddy(this.active);
    },
    //代发货
    dfh() {
      this.active = 1;
      this.wddy(this.active);
    },
    //待支付
    dzf() {
      this.active = 0;
      this.wddy(this.active);
    },
    //返回我的页面
    back() {
      this.$router.push("/my");
    },
    //请求数据
    wddy(e) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=10" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {
        page: 1,
        pageSize: 20,
        status: [e],
      };
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/order/list",
      })
        .then((res) => {
          this.orders = res.data.item.orders;
          if (res.data.item.total == 0) {
            this.pic = true;
            this.show=false
            this.isshow = false;
          } else {
            this.pic = false;
            this.show=true
          }
        })
        .catch((error) => {});
    },
  },
  created() {
    this.tick();
    this.active = localStorage.getItem("wddy");
    this.wddy(this.active);
    // if(this.$router.query.item=="undefined"){
    //  11);
    // }else{
    //  // this.active=1
    // }
  },
};
</script>

<style lang="less" scoped>

.name {
  margin-right: 20px;
  text-align: justify;
  text-justify: newspaper;
  word-break: break-all;
}
.wddy {
  .main {
    box-sizing: border-box;
    .con {
      .cmain {
        .cright {
          .crtit {
            p {
              font-size: 12;
            }
            div {
              font-weight: bold;
              font-size: 14px;
            }
            display: flex;
            justify-content: space-between;
            margin-top: 5px;
          }
          text-align: right;
          width: 100%;
        }
        .pic {
          margin-right: 15px;
          width: 90px;
          height: 90px;
        }
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
      }
      .ctit {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      background: #fff;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    padding: 10px;
    flex: 1;
    height: 100%;
    background: #f5f5f5;
  }
  .nav {
    .active {
      border-bottom: 2px solid #fccc00;
      padding: 0 10px;
      font-weight: bold;
    }
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    padding: 10px;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>