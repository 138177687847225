<template>
  <div id="code">
    <!-- <div class="back" @click="next">✖</div> -->
    <div class="back" @click="back">
      <img class="pic" src="@/assets/img/mback.png" alt />
    </div>
    <div class="tit">
      <img src="@/assets/img/ecim.png" alt />
    </div>
    <div>
      <!-- 验证码框 -->
      <!-- <van-password-input
        class="ipt"
        :value="value"
        :mask="false"
        :focused="false"
        :length="6"
      /> -->
      <div class="codebox">
        <input
          type="number"
          oninput="if(value.length>6)value=value.slice(0,6)"
          placeholder="请输入验证码"
          v-model="value"
          class="codenum"
        />
      </div>
    </div>
    <p class="rz" v-if="isshow"></p>
    <div class="dx" @click="btn1">确认登陆</div>
    <!-- <div class="vbtn">
      <van-button
        class="bjnum"
        disabled
        :type="btn"
        v-if="isshow"
        color="#FCCC00"
      >{{totalTime}}秒后可重新获取</van-button>

    </div> -->
    <!-- <div class="ls" @click="ls">临时跳转页面</div> -->
    <!-- <van-number-keyboard
      v-model="value"
      :show="showKeyboard"
      @blur="showKeyboard = false"
    /> -->
  </div>
</template>

<script>
//测试

import Vue from "vue";
import { PasswordInput, NumberKeyboard } from "vant";
Vue.use(PasswordInput);
Vue.use(NumberKeyboard);
import { Toast } from "vant";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import { Button } from "vant";
import { format } from "echarts";
Vue.use(Button);
export default {
  data() {
    return {
      showKeyboard: true,
      value: "",
      mask: "",
      focused: "",
      btn: "primary",
      isshow: false,
      totalTime: 60,
      isshow2: true,
      url: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100",
    };
  },
  created() {},
  methods: {
    //获取验证码
    btn1() {
      //清楚原有验证码
      this.showKeyboard = true;
      if (this.value.length == 6) {
        let token = "";
        let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
        let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
        let nonce = md5(
          "http://10.208.110.178:9100/user/login" +
            timestamp +
            Math.round(Math.random() * 8)
        );
        let sign = [
          "token=" + token,
          "timestamp=" + timestamp,
          "nonce=" + nonce,
          "secret=" + secret,
        ];
        function fn(sign) {
          return Array.from(new Set(sign)).sort();
        }
        let sj = md5(fn(sign).join("&"));
        let datas = {
          phone: this.$route.query.phone,
          smsCode: this.value,
          type: 1, //手机传1，微信传2
        };
        axios({
          headers: {
            "Content-Type": "application/json;",
            token: token,
            timestamp: timestamp,
            nonce: nonce,
            sign: sj,
          },
          body: {
            params: datas,
          },
          method: "post",
          transformRequest: [
            function (data) {
              // 对 data 进行任意转换处理
              return JSON.stringify(datas);
            },
          ],
          url: this.reurl + "/user/login",
        })
          .then((res) => {
            if (res.data.errcode == 0) {
              Toast("验证成功，正在跳转");
              setTimeout(() => {
                localStorage.setItem("token", res.data.item.token);
                //localStorage.setItem("phone", this.value);
                this.$router.push("/index");
              }, 2000);
            } else {
              Toast(res.data.errmsg);
            }
          })
          .catch((error) => {});
      } else {
        Toast("请输入验证码");
      }
      //设置定时器
      this.totalTime = 60;
      //更改隐藏显示
      (this.isshow = true), (this.isshow2 = false);
      setInterval(() => {
        this.totalTime--;
        if (this.totalTime == 0) {
          (this.isshow = false), (this.isshow2 = true);
        }
      }, 1000);
    },
    //返回上一页面
    back() {
      this.$router.push("/register");
    },
    //接口测试
  },
};
</script>

<style lang="less" scoped>
.codebox {
  padding: 10px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
input::-webkit-input-placeholder {
  font-size: 18px;
  letter-spacing: 10px;
}
.codenum {
  outline: none;
  border: none;
  border-bottom: 1px solid #fccc00;
  // border: 1px solid #ccc;
  height: 40px;
  width: 200px;
  font-size: 30px;
  text-align: center;
  letter-spacing: 10px;
  color: #fccc00;
}
#code {
  .ls {
    text-align: center;
    width: 200px;
    line-height: 40px;
    background: #000000;
    color: #fff;
  }
  .dx {
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    margin: 20px 40px;
    background: #fccc00;
    font-weight: bold;
    border-radius: 5px;
  }
  .bjnum {
    color: #010005;
  }
  .vbtn {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  .pic {
    width: 22px;
    height: 22px;
  }
  .ipt {
    margin-top: 90px;
    width: 100%;
    height: 50px;
  }
  width: 100%;
  height: 100%;
  .mask1 {
    .gg {
      background: #f5f5f5;
      height: 40px;
      width: 100%;
      bottom: 0;
      position: absolute;
    }
    height: 100%;
    .content {
      .tst {
        height: 80px;
      }
      .ct {
        margin-bottom: 20px;
      }
      .last {
        padding: 0 0 20px 0;
      }
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      font-weight: bold;
      color: #333333;
      p {
        line-height: 30px;
      }
      padding: 20px;
    }
    .mtit {
      height: 44px;
      line-height: 44px;
      text-align: center;
      position: relative;
      font-weight: bold;
      font-size: 18px;
      background: #fff;
      .mback {
        position: absolute;
        left: 20px;
        img {
          vertical-align: text-bottom;
          width: 22px;
          height: 22px;
        }
      }
    }
    background: #f5f5f5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .rz {
    color: #999999;
    text-align: center;
    margin-top: 30px;
  }
  .tel {
    margin-left: 40px;
    font-size: 24px;
    font-weight: bold;
    margin-top: 100px;
  }
  .footer {
    position: absolute;
    bottom: 30px;
    margin: auto;
    width: 100%;
    .vx {
      text-align: center;
    }
    .btm {
      a {
        color: #2676d0;
      }
      margin-top: 20px;
      text-align: center;
      color: #999999;
      font-size: 12px;
    }
  }
  .anther {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  .tit {
    text-align: left;
    margin-top: 70px;
    margin-left: 40px;
  }
  .back {
    font-size: 24px;
    color: #fff;
    margin: 20px;
  }
}
</style>