<template>
  <div class="start">
    <div class="img">
      <img :src="img" alt="" />
    </div>
    <div class="rg">
      <div class="time">
        <span style="margin-right: 5px" v-show="num == 0">{{ time }}</span>
        <span @click="go">跳过</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { Lazyload } from "vant";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem); 
export default {
  data() {
    return {
      num: 0,
      time: 5,
      images: [],
      img: "",
    };
  },
  created() {
    this.stImg();
    var log = setInterval(() => {
      this.time--;
      if (this.time == -1) {  
        this.$router.push("/login");
        this.num = 1;
        clearInterval(log);
      }
    }, 1000);
  },
  methods: {
    //获取开平页面
    stImg() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        url: this.reurl + "/app/index/getStartPage",
      }).then((res) => {
        this.img=this.picurl+res.data.item;
      });
    },
    go() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="less" scoped>
.start {
  .rg {
    .time {
      height: 20px;
      padding: 0 10px;
      border-radius: 10px;
      line-height: 20px;
      border: 1px solid #ccc;
      // color: #fff;
      font-size: 12px;
      display: flex;
    }
    position: fixed;
    top: 10px;
    right: 10px;
  }
  width: 100%;
  height: 100%;
  .img {
    img {
      width: 100%;
      height: 100%;
    }
    width: 100%;
    height: 100%;
  }
}
</style>