<template>
  <div id="zskf">
    <div class="header">
      <div class="back2" @click="back">
        <img class="pic" src="@/assets/img/mback.png" />
      </div>
      <span style="font-weight: bold">客户经理</span>
    </div>
    <div class="tit">
      <div class="top">
        <img :src="avatar" class="pic" />
        <div class="right">
          <div>{{ info.nickName }}</div>
          <div class="rb">
            <img src="../assets/img/nav.png" alt="" />
            <span class="sl">{{ map }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="time">{{ time | dateformat("HH:MM") }}</div>
    <div class="main">
      <div class="box" v-for="(item, i) in data" :key="i">
        <div class="btit">
          <span style="margin-right: 5px">{{ item.name }}</span
          >|
          <span>{{ item.businessType + item.positionName }}</span>
        </div>
        <div class="con">
          <img src="../assets/img/mail.png" class="img" />
          <div class="mail">{{ item.email }}</div>
        </div>

        <div class="telnum">
          <img
            src="../assets/img/phone.png"
            style="height: 18px"
            class="phonepic"
          />{{ item.phone }}
        </div>
        <div class="footer">
          <div></div>
          <div class="bq">
            {{ item.businessType == "零件" ? "易初明通" : ""
            }}{{ item.department
            }}{{ item.businessType == "零件" ? "分公司" : "" }}
          </div>
          <!-- <div class="bq">
            易初明通<span>{{ item.branchName }}</span
            >分公司
          </div> -->
        </div>
        <div class="call" @click="tel(item.phone)">
          <span class="tel">拨打电话</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import axios from "axios";
import tx from "@/assets/img/cat.jpg";
import qs from "qs";
import { Toast } from "vant";
export default {
  data() {
    return {
      data: "",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100",
      map: "",
      info: "",
      imgUrl: "http://customerapp.eci-metro.cn/",
      time: "",
      avatar: "",
      tx: tx,
    };
  },
  created() {
    if (localStorage.getItem("themap")) {
      this.map = localStorage.getItem("themap");
    } else {
      this.dqwz();
      Toast("地址获取失败，稍后在试");
    }
    this.time = new Date();
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/userInfo" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    var dbs = "";
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {},
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url:
        this.reurl +
        "/app/device/exclusiveCustomerServiceV2?dbsId=" +
        this.$route.query.item,
    }).then((res) => {
      this.data = res.data.item;
      console.log(this.data);
      console.log(this.$route.query.item);
    });
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/user/userInfo",
    }).then((res) => {
      this.info = res.data.item;
      if (res.data.item.avatar.length > 2) {
        this.avatar = this.picurl + res.data.item.avatar;
      } else {
        this.avatar = this.tx;
      }
    });
  },
  methods: {
    back() {
      this.$router.push("/my");
    },
    tel(e) {
      window.location.href = "tel://" + e;
    },
    //地图当前定位
    dqwz() {
      //获取当前经纬度
      let that = this;
      var map = new AMap.Map("container", {
        resizeEnable: true,
      });
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
        });
        map.addControl(geolocation);
        // geolocation.getCurrentPosition(function (status, result) {
        //   if (status == "complete") {
        //     onComplete(result);
        //   } else {
        //     console.log(1);
        //   }
        // });
      });
      //解析定位结果
      function onComplete(data) {
        localStorage.setItem("themap", data.formattedAddress);
        console.log('我是地址：'+data.formattedAddress);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.phonepic {
  margin-left: 5px;
  vertical-align: middle;
}
.telnum {
  margin-top: 10px;
  font-size: 14px;
  color: #999999;
}
.time {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #cccccc;
}
#zskf {
  .main {
    .box {
      margin-bottom: 10px;
      .call {
        margin: 20px 10px 10px 0;
        .tel {
          font-weight: bold;
          line-height: 20px;
          font-size: 14px;
          background: #fccc00;
          border-radius: 5px;
          padding: 5px 15px;
        }
        text-align: right;
      }
      .footer {
        .bq {
          border-radius: 10px;
          padding: 0 10px;
          background: #f1f5f9;
          text-align: center;
          margin-left: 10px;
        }
        line-height: 20px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        color: #999999;
        padding-bottom: 10px;
        border-bottom: 2px solid #f1f5f9;
      }
      .con {
        .img {
          width: 22px;
          height: 22px;
        }
        .mail {
          font-size: 14px;
          color: #999999;
          margin-left: 3px;
        }
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        align-items: center;
      }
      .btit {
        font-weight: bold;
        color: #666666;
        font-size: 14px;
        //padding: 0 0 0 20px;
      }
      padding: 10px;
      border-radius: 10px;
      background: #fff;
    }
    margin-top: 10px;
    padding: 10px;
  }
  .tit {
    .top {
      .pic {
        margin-right: 20px;
        width: 64px;
        height: 64px;
        border: 50%;
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .rb {
          .sl {
            // width: 200px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            box-sizing: border-box;
          }
          margin-top: 20px;
          display: flex;
          align-items: center;
        }
      }
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    padding: 10px;
    border-radius: 0 0px 20px 20px;
    width: 100%;
    background: #fff;
    box-sizing: border-box;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: 500;
    height: 44px;
    width: 100%;
    text-align: center;
    .back2 {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  width: 100%;

  background: #f5f5f5;
  height: 100%;
}
</style>