<template>
  <div id="Register">
    <!-- <van-number-keyboard v-model="phone" :show="showKeyboard" @blur="showKeyboard = false" /> -->
    <!-- <div class="back" @click="back">
      <img class="pic" src="@/assets/img/mback.png" alt />
    </div> -->
    <div class="tit">
      <!-- <img src="@/assets/img/ecim.png" alt /> -->
    </div>
    <div class="plogin">验证码登录</div>
    <div class="phone">
      <input
        type="phone"
        class="ipt"
        v-model="phone"
        placeholder="请输入手机号码"
        maxlength="13"
      />
      <img src="@/assets/img/tb.png" class="ppic" @click="ppic" />
      <!-- <van-field v-model="tel" type="tel" label="手机号" /> -->
    </div>
    <div class="codebox">
      <div class="code">
        <input
          type="number"
          oninput="if(value.length>6)value=value.slice(0,6)"
          class="ipt"
          style="width: 170px"
          v-model="codel"
          placeholder="请输入验证码"
        />
        <span
          @click="reg"
          style="line-height: 40px"
          :id="ccc != 1 ? 'ccc' : ''"
          v-if="num == 1"
          >获取验证码</span
        >
        <span v-if="num == 0" style="font-size: 12px; color: #ccc"
          >{{ mins }}S后再次获取</span
        >
      </div>
    </div>
    <p class="rz">未注册的手机号验证后自动创建账户</p>
    <div class="bjnum" @click="go"><span>登</span> 录</div>
    <!-- 尾巴 -->
    <div class="footer">
      <div class="vx">
        <img src="@/assets/img/login2.png" class="img" style="display: none" />
      </div>
      <div class="btm">
        <input type="checkbox" @click="ipt($event)" />
        登录即表明同意
        <a @click="ysxy">《隐私协议》</a>
        <!-- <a @click="ysxy">《用户协议及隐私协议》</a> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { NumberKeyboard } from "vant";

Vue.use(NumberKeyboard);
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
//样式引入
import { Toast } from "vant";
export default {
  data() {
    return {
      // showKeyboard: true,
      isshow: false,
      isshow2: false,
      phone: "",
      codel: "",
      num: 1,
      mins: 60,
      ccc: "",
      ipts: false,
    };
  },
  methods: {
    ysxy() {
      this.$router.push("/ysxy");
    },
    ipt(e) {
      this.ipts = e.target.checked;
    },
    go() {
      if (this.codel.length == 6) {
        if (this.ipts === true) {
          let token = "";
          let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
          let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
          let nonce = md5(
            "http://10.208.110.178:9100/user/login" +
              timestamp +
              Math.round(Math.random() * 8)
          );
          let sign = [
            "token=" + token,
            "timestamp=" + timestamp,
            "nonce=" + nonce,
            "secret=" + secret,
          ];
          function fn(sign) {
            return Array.from(new Set(sign)).sort();
          }
          let sj = md5(fn(sign).join("&"));
          let datas = {
            phone: this.phone.replace(/\s*/g, ""),
            smsCode: this.codel,
            type: 1, //手机传1，微信传2
          };
          axios({
            headers: {
              "Content-Type": "application/json;",
              token: token,
              timestamp: timestamp,
              nonce: nonce,
              sign: sj,
            },
            body: {
              params: datas,
            },
            method: "post",
            transformRequest: [
              function (data) {
                // 对 data 进行任意转换处理
                return JSON.stringify(datas);
              },
            ],
            url: this.reurl + "/user/login",
          })
            .then((res) => {
              if (res.data.errcode == "0") {
                Toast("验证成功，正在跳转");
                setTimeout(() => {
                  localStorage.setItem("token", res.data.item.token);
                  localStorage.setItem(
                    "permission",
                    JSON.stringify(res.data.item.permission)
                  );
                  this.$router.push("/index");
                  this.$router.push({
                    path: "/index",
                    query: {
                      item: res.data.item.isNewUser,
                    },
                  });
                }, 2000);
              } else {
                Toast(res.data.errmsg);
              }
            })
            .catch((error) => {});
        } else {
          Toast("请先同意用户协议");
        }
      } else {
        Toast("请输入验证码");
      }
    },
    reg() {
      this.btn1();
    },
    ppic() {
      this.phone = "";
    },
    //返回上一页面
    back() {
      this.$router.push("/login");
    },
    //获取短信验证码
    btn1() {
      let phone = this.phone.replace(/\s*/g, "");
      let token = "";
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/sendSms" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      if (phone.length != 11) {
        // Toast("请输入正确的手机号码");
      } else {
        let datas = {
          phone: phone,
        };
        axios({
          headers: {
            "Content-Type": "application/json;",
            token: token,
            timestamp: timestamp,
            nonce: nonce,
            sign: sj,
          },
          body: {
            params: datas,
          },
          method: "post",
          transformRequest: [
            function (data) {
              // 对 data 进行任意转换处理
              return JSON.stringify(datas);
            },
          ],
          url: this.reurl + "/user/sendSms",
        }).then((res) => {
          if (res.data.errcode == 0) {
            this.num = 0;
            let test = setInterval(() => {
              this.mins--;
              if (this.mins <= 0) {
                this.mins = 60;
                clearInterval(test);
                this.num = 1;
              }
            }, 1000);
            Toast("验证码获取成功");
          } else {
            Toast("验证码获取失败");
          }
        });
      }
    },
  },
  watch: {
    // 通过watch来设置空格
    phone(newValue, oldValue) {
      if (newValue.length > oldValue.length) {
        // 文本框中输入
        if (newValue.length === 3 || newValue.length === 8) {
          this.phone += " ";
        }
      } else {
        // 文本框中删除
        if (newValue.length === 9 || newValue.length === 4) {
          this.phone = this.phone.trim();
        }
      }
    },
  },
  updated() {
    if (this.phone.length == 13) {
      this.ccc = 1;
    }
  },
};
</script>

<style lang="less" scoped>
#ccc {
  color: #ccc;
}

.codebox {
  margin-top: 40px;
  padding: 0 40px;
}
.code {
  span {
    color: #333;
  }
  input {
    text-indent: 10px;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 2px;
    border: none;
    height: 20px;
    width: 200px;
    color: #000000;
    font-weight: bold;
  }
  input::-webkit-input-placeholder {
    font-weight: normal;
  }
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
}
.plogin {
  font-size: 20px;
  margin-left: 40px;
  font-weight: 700;
  margin-bottom: 40px;
  //display: none;
}
#Register {
  .ppic {
    width: 16px;
    height: 16px;
  }
  .pic {
    width: 22px;
    height: 22px;
  }
  .bjnum {
    span {
      margin-right: 20px;
    }
    color: #010005;
    font-size: 20px;
    text-align: center;
    line-height: 50px;
    margin: 20px 40px;
    background: rgb(255, 205, 17);
    //background: #bbe2da;
    font-weight: bold;
    color: #fff;
    border-radius: 10px;
    margin-top: 50px;
  }
  .phone {
    .ipt {
      line-height: 20px;
      letter-spacing: 1px;
      width: 100%;
      border: none;
      height: 20px;
      color: #000000;
      font-weight: bold;
    }
    .ipt::-webkit-input-placeholder {
      font-weight: normal;
    }
    padding: 10px;
    display: flex;
    margin: 30px 50px 20px 40px;
    border-bottom: 1px solid #f5f5f5;
  }
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  height: 100%;
  width: 100%;
  .tit {
    text-align: left;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 40px;
  }
  .rz {
    color: #ccc;
    font-size: 12px;
    margin-left: 53px;
    margin-top: 15px;
  }
  .footer {
    padding-top: 90px;
    margin: auto;
    width: 100%;
    .vx {
      .img {
        width: 44px;
        height: 44px;
      }
      text-align: center;
    }
    .btm {
      a {
        color: #2676d0;
      }
      width: 100%;
      margin: auto;
      position: fixed;
      left: 50%;
      margin-left: -50%;
      bottom: 40px;
      text-align: center;
      color: #999999;
      font-size: 12px;
    }
  }
  .mask1 {
    .gg {
      background: #f5f5f5;
      height: 40px;
      width: 100%;
      bottom: 0;
      position: absolute;
    }
    height: 100%;
    .content {
      .tst {
        height: 80px;
      }
      .ct {
        margin-bottom: 20px;
      }
      .last {
        padding: 0 0 20px 0;
      }
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      font-weight: bold;
      color: #333333;
      p {
        line-height: 30px;
      }
      padding: 20px;
    }
    .mtit {
      height: 44px;
      line-height: 44px;
      text-align: center;
      position: relative;
      font-weight: bold;
      font-size: 18px;
      background: #fff;
      .mback {
        position: absolute;
        left: 20px;
        img {
          vertical-align: text-bottom;
          width: 22px;
          height: 22px;
        }
      }
    }
    background: #f5f5f5;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .back {
    font-size: 24px;
    color: #0000 !important;
    margin: 20px;
  }
}
</style>