<template>
  <div>
    <div class="tips" v-show="tips">
      <!-- <van-loading v-show="tips" type="spinner" vertical color="#FCCC00">加载中...</van-loading> -->
      <img class="timg" src="@/assets/img/loading.gif" alt />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tips: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.tips = false;
    });
  },
};
</script>

<style lang="less" scoped>
.tips {
  .timg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
 // background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
</style>