<template>
  <div id="xxzx">
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" id="close_page" />
      </div>
      <h1 id="close_page" style="font-weight: bold">消息</h1>
    </div>
    <div class="main">
      <!-- 1 -->
      <div class="con" v-for="(val,i) in item" :key="i" @click="go(val)">
        <div class="tit">{{val.createTime | dateformat('HH:MM')}}</div>
        <div class="box">
          <div class="btit">{{val.title}}</div>
          <div class="bcon">
           {{val.content}}
          </div>
          <div class="bottom">{{ val.createTime |  dateformat('YYYY/MM/DD')}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
export default {
  
  data(){
    return {
      furl: "http://customerapp.eci-metro.cn/ecim",
      item:[],
    }
  },
  methods: {
    go(e){
       if (e.link != null) {
        this.$router.push({
          path: "/banner",
          query: {
            item: e.link,
          },
        });
      }
    },
    back() {
      this.$router.push("/index");
    },
  },
  created(){
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/userInfo" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    var dbs = "";
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {
    };
   axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/push/updateRead",
      }).then((res) => {
    });
     axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/push/list",
      }).then((res) => {
        
        this.item=res.data.item.item
    });
  }
};
</script>

<style lang="less" scoped>
#xxzx {
  display: flex;
  flex-direction: column;
  .main {
    .con {
      .box {
        .bottom {
          color: #999999;
          font-size: 12px;
          margin-bottom: 10px;
          margin-left: 15px;
        }
        .bcon {word-wrap:break-word; 
          color: #333333;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .btit {
          margin-left: 15px;
          color: #333333;
          font-weight: 550;
          margin-bottom: 10px;
        }
        border-radius: 10px;
        background: #fff;
        padding: 10px;
      }
      .tit {
        text-align: center;
        color: #cccccc;
        font-size: 14px;
        margin: 20px 0;
      }
    }
    background: #f5f5f5;
    padding: 15px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: 500;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  height: 100%;
  width: 100%;
}
</style>