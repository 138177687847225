<template>
  <div class="zxzx">
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" id="close_page" />
      </div>
      <span class="fwb">资讯中心</span>
    </div>
    <div class="content">
      <div class="main" v-for="(item,i) in data" :key="i">
        <div class="tit ccc fz14">{{item.releaseTime}}</div>
        <!-- 内容 -->
        <div class="con">
          <div class="first" @click="first(item)">
            <img :src="item.newsImageUrl" :class="item.newsChildList.length==0?'txt':'txt2'" />
            <span class="name fz14">{{item.newsTitle}}</span>
          </div>
          <div class="two" @click="two(e)" v-for="(e,k) in item.newsChildList" :key="k">
            <div class="twotit c333 fz14 fw5">{{e.title}}</div>
            <img :src="e.imageUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
export default {
  mounted() {
    $(".body").find("p").css({
      width: "100%",
      height: "100%",
    });
    $(".body").find("img").css({
      width: "100%",
      height: "100%",
    });
  },
  data() {
    return {
      data: "",
    };
  },
  created() {
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/sendSms" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {
      page: 1,
      pageSize: 999,
    };
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "post",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/app/news/listPageNews?newsTitle=",
    }).then((res) => {
      this.data = res.data.item.data;
      if (res.data.errcode == 0) {
        axios({
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
            timestamp: timestamp,
            nonce: nonce,
            sign: sj,
          },
          body: {},
          method: "get",
          url: this.reurl + "/app/news/readAll",
        }).then((res) => {
        });
      }
    });
  },
  methods: {
    back() {
      this.$router.push("/index");
    },
    first(e) {
      localStorage.setItem('zx',JSON.stringify(e))
      this.$router.push({
        path: "/zxxq",
        query: {
          item: e,
        },
      });
    },
    two(e) {
      localStorage.setItem('zx2',JSON.stringify(e))
      this.$router.push({
        path: "/zxq",
        query: {
          item: e,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.twotit{

overflow: hidden;}
.txt {
  border-radius: 10px;
}
.txt2 {
  border-radius: 10px 10px 0 0;
}
.zxzx {
  .content {
    background: #f5f5f5;
    overflow: hidden;
    .main {
      .con {
        .two {
          border-bottom: 1px solid #EEEEEE;
          padding: 5px;
          background: #ffffff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            border-radius: 5px;
            width: 40px;
            height: 40px;
            margin-left: 20px;
          }
        }
        .first {
          .name {
            text-align: center;
            width: 300px;
            color: #ffffff;
            position: absolute;
            left: 50%;
            bottom: 20px;
            font-weight: 500;
            transform: translate(-50%);
          }
          img {
            width: 100%;
            height: 180px;
          }
          position: relative;
        }
        padding: 10px;
        border-radius: 10px;
        line-height: 20px;
      }
      .tit {
        text-align: center;
      }
      height: 100%;
      padding: 10px;
    }
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: 500;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
}
.fw5 {
  font-weight: 500;
}
.fwb {
  font-weight: bold;
}
.fz14 {
  font-size: 14px;
}
.fz12 {
  font-size: 12px;
}
.ccc {
  color: #cccccc;
}
.c333 {
  color: #333333;
}
.brt {
  border-radius: 0 0 10px 10px;
}
.bt {
  border-top: #eeeeee solid 1px;
}
</style>