import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import moment from 'moment'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import Mui from 'vue-awesome-mui';
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.prototype.$moment = moment
Vue.prototype.$axios = axios //把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求
Vue.use(Mui);

// AMap.initAMapApiLoader({
//   // 高德key
//   key: 'e1dedc6bdd765d46693986ff7ff969f4',
//   // 插件集合 （插件按需引入）
//   plugin: ['AMap.Geolocation','MarkerClusterer','AMap.Heatmap','AMap.Geocoder']
// });
//正式环境
// let picurl = "http://customerapp.eci-metro.cn";
// let reurl = "http://customerapp.eci-metro.cn/ecim";  
//测试环境
let picurl = "http://customerapptest.eci-metro.cn:7070/";
let reurl = "http://customerapptest.eci-metro.cn:7070/ecim";
Vue.prototype.picurl = picurl
Vue.prototype.reurl = reurl

Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

// 引入重置样式
import "@/assets/css/resset.css"
// 引入字体样式
import "@/assets/css/font.css"
//引入vant框架，就
import Vant from 'vant';
//引入vant样式
import 'vant/lib/index.css';

import VueTouch from "vue-touch";
Vue.use(VueTouch, {
  name: "v-touch"
});
Vue.use(Vant);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')