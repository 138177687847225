<template>
  <div class="yjbx">
    <Tips />
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      一键报修
    </div>
    <!-- 内容 -->
    <van-loading color="#fccc00" class="loding" vertical v-show="show"
      >上传中..</van-loading
    >

    <div class="main">
      <!-- 搜索框 -->
      <!-- <div class="serch">
        <van-search class="lserch" v-model="value" shape="round" placeholder="搜索您要保养的物品机编号/型号" />
      </div>-->
      <!-- 全部设备 -->
      <div class="cbsb">
        <p class="fwber">全部设备</p>
        <div>
          <div
            class="cbcon"
            v-for="(item, index) in data"
            :key="index"
            :class="yjtj.includes(item.serialNumber) ? 'yjtj' : ''"
          >
            <div class="cleft">
              <img :src="picurl + item.imgUrl" class="cimg" />
            </div>
            <div class="cright">
              <p class="fwber">
                {{ item.makeCode }} {{ item.model }} {{ item.name }}
              </p>
              <div class="cbtm">
                <div class="cr">
                  <p class="c9b fz14 lh20 mt20">型号: {{ item.model }}</p>
                  <p class="c9b fz14">设备号：{{ item.serialNumber }}</p>
                </div>
                <label class="cl">
                  <input type="radio" class="ipt" :value="item" v-model="sb" />
                  <span></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fwlx">
        <div class="fz14 fwb mb10">服务类型</div>
        <div>
          <van-dropdown-menu direction="down" active-color="#fccc00">
            <van-dropdown-item v-model="value1" :options="option1" />
          </van-dropdown-menu>
        </div>
      </div>
      <!-- 故障照片 -->
      <div class="gzpic">
        <p class="fz14 fwb">故障照片</p>
        <van-uploader
          class="mt20"
          v-model="fileList"
          preview-size="90px"
          capture="camera"
          :multiple="true"
          :max-count="4"
          :after-read="afterRead"
          :disabled="no"
        />
        <span class="fz12">最多添加四张</span>
        <div>
          <!-- 故障视频 -->
          <!-- 视频照片 -->
          <p class="fz14 fwb" style="margin-bottom: 20px">故障视频</p>
          <div class="upLoad">
            <img
              src="../../assets/img/tb.png"
              v-show="sp != 0"
              class="spic"
              @click="del"
            />
            <label for="pop_video" id="labelr">
              <video id="videoId" controls v-show="sp != 0"></video>
              <input
                capture="camera"
                style="display: none"
                id="pop_video"
                type="file"
                accept="video/*"
                v-on:change="getVideo"
                name="fileTrans"
                ref="file"
                :disabled="no"
                value=""
              />
              <div class="inputVideo">
                <img
                  src="../../assets/img/cc.png"
                  style="height: 90px; weight: 90px"
                  v-show="sp == 0"
                />
              </div>
            </label>
          </div>
          <!-- 输入任意文本 -->
          <div :class="name.length != 0 ? 'dw' : ''">
            <img
              src="@/assets/img/tb.png"
              class="tb"
              @click="dellx"
              v-show="name.length != 0"
            />
            <van-field
              v-model="name"
              label="联系人"
              input-align="right"
              placeholder="请输入联系人"
            />
          </div>
          <!-- 输入手机号，调起手机号键盘 -->
          <div :class="tel.length != 0 ? 'dw' : ''">
            <img
              src="@/assets/img/tb.png"
              class="tb"
              @click="deldh"
              v-show="tel.length != 0"
            />
            <van-field
              v-model="tel"
              label="联系号码"
              input-align="right"
              type="number"
              placeholder="请输入电话号码"
              :maxlength="11"
            />
          </div>
          <!-- 三级联动 -->
          <div>
            <van-field
              v-model="fieldValue"
              is-link
              readonly
              label="地区"
              placeholder="请选择所在地区"
              @click="shows = true"
            />
            <van-popup v-model="shows" round position="bottom">
              <van-cascader
                v-model="cascaderValue"
                title="请选择所在地区"
                :options="data1"
                @close="shows = false"
                @change="onChange"
                @finish="onFinish"
              />
            </van-popup>
          </div>
          <div class="dw">
            <!-- <img src="@/assets/img/dzg.png" class="tb2" @click="dqwz" /> -->
            <van-field
              v-model="text"
              label="服务地址"
              input-align="right"
              :disabled="fieldValue.length == 0"
              placeholder="请输入具体地址"
            />
          </div>

          <textarea
            v-model="ly"
            placeholder="故障描述(最多400字)"
            maxlength="400"
            class="ly"
          ></textarea>
        </div>
      </div>
      <div class="subbtn" @click="subbtn">提交一键报修单</div>
    </div>
    <!-- 提示框插件 -->
    <van-cell is-link @click="subbtn" style="display: none"></van-cell>
    <div class="mask" v-show="tk">
      <div id="tk" class="tk">
        <h4>请确认您的联系方式</h4>
        <div class="sure">
          <div>
            联系号码: <span>{{ tel }}</span>
          </div>
          <div>
            服务地址: <span>{{ text }}</span>
          </div>
        </div>
        <p class="tips">!!注意错误的联系方式会导致工程师无法联系上您</p>
        <div class="sbtn">
          <div class="frist" @click="tk = false">返回修改</div>
          <div class="two" @click="subbtn1">确认提交</div>
        </div> 
      </div>
    </div>

    <div id="container"></div>
    <div class="loadding" v-show="loadding">
      <van-loading color="#fccc00" size="24px">提交中...</van-loading>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Search } from "vant";
import { Toast } from "vant";
import { Uploader } from "vant";
import { Field } from "vant";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import { RadioGroup, Radio } from "vant";
import { time } from "echarts";
import Tips from "@/components/Tips.vue";
import { Loading } from "vant";
import { Popup } from "vant";
import { DropdownMenu, DropdownItem } from "vant";

Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Popup);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Search);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      cascaderValue: "",
      fieldValue: "",
      data1: [],
      data2: [],
      data3: [],
      province: 110000,
      result: "",
      city: "",
      region: "",
      value1: 2,
      loadding: false,
      option1: [
        { text: "选择服务类型", value: 2 },
        { text: "一键报修", value: 0 },
        { text: "一键保养 ", value: 1 },
      ],
      tk: false,
      no: false,
      show: false,
      shows: false,
      sp: 0,
      sb: [],
      yjtj: [],
      num: 1,
      sure: true,
      data: [],
      fileList: [],
      lnglat: "",
      //value: "",
      name: "",
      tel: "",
      text: "",
      ly: "",
      item: {},
      cname: "",
      pic: "",
      lng: "",
      lat: "",
      video: [],
      tp: [],
      ccc: "",
      glat: "",
      glnt: "",
      gaodemap: "",
    };
  },
  created() {
    this.regions();
    if (localStorage.getItem("themap")) {
      //this.text = localStorage.getItem("themap");
    } else {
      //this.dqwz();
      // Toast("网络请求失败，无法获取当前定位");
    }
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=10" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/app/device/deviceListV2?page=1&pageSize=100",
    })
      .then((res) => {
        if (res.data.errcode == 40000) {
          this.$router.push("/login");
          Toast("登录失效请重新登陆");
        }
        this.data = res.data.item.data;
        for (var i = 0; i < this.data.length; i++) {
          if (this.data[i].name == null) {
            this.data[i].name = "通用设备";
          }
          if (this.data[i].imgUrl == null) {
            this.data[i].imgUrl = "/deviceImg/default.jpg";
          }
        }
      })
      .catch((error) => {});
  },
  methods: {
    //经纬度转化
    gaode(a) {
      let data = {
        key: "f2abb3e2569d214a897f3f2ddba8aa97",
        address: a,
      };
      $.ajax({
        url: "https://restapi.amap.com/v3/geocode/geo",
        type: "get",
        dataType: "jsonp",
        data,
        success: function (data) {
          this.gaodemap = data.geocodes[0];
          sessionStorage.setItem(
            "lnt",
            data.geocodes[0].location.match(/(\S*),/)[1]
          );
          sessionStorage.setItem(
            "lat",
            data.geocodes[0].location.match(/,(\S*)/)[1]
          );
        },
      });
    },
    onChange({ value, selectedOptions, tabIndex }) {},
    onFinish({ selectedOptions }) {
      this.region = selectedOptions[2].value;
      this.shows = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
      this.text = selectedOptions.map((option) => option.text).join("");
      this.ccc = selectedOptions.map((option) => option.text).join("");
      this.data2 = selectedOptions.map((option) => option.text);
    },
    //提交报修
    subbtn1() {
      //照片转化自己的数据
      this.loadding = true;
      const formdata = new FormData();
      let pic = this.fileList.map(function (item) {
        //return item.file
        return formdata.append("file", item.file);
      });
      var regex = /^1[3|4|5|7|8]\d{9}$/;
      if (!this.name) {
        Toast("联系人不能为空");
        this.loadding = false;
      } else {
        if (!regex.test(this.tel)) {
          Toast("请输入正确的手机号码");
          this.loadding = false;
        } else if (this.region == "") {
          Toast("请先选择区域");
          this.loadding = false;
        } else {
          if (!this.text) {
            Toast("地址不能为空");
            this.loadding = false;
          } else if (this.text == this.ccc) {
            Toast("服务地址后需填写详细地址");
            this.loadding = false;
          } else if (this.sb.length == 0) {
            Toast("请先选择设备");
            this.loadding = false;
          } else {
            let token = localStorage.getItem("token");
            let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
            let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
            let nonce = md5(
              "http://10.208.120.107:9100/app/services/saveServiceRecord" +
                timestamp +
                Math.round(Math.random() * 8)
            );
            let sign = [
              "token=" + token,
              "timestamp=" + timestamp,
              "nonce=" + nonce,
              "secret=" + secret,
            ];
            function fn(sign) {
              return Array.from(new Set(sign)).sort();
            }
            let sj = md5(fn(sign).join("&"));
            let datas = {
              serialNumber: this.sb.serialNumber,
              model: this.sb.model,
              dbsId: sessionStorage.getItem("dbsId"),
              serviceType: this.value1 + 1,
              contactNumber: this.tel,
              contacts: this.name,
              province: this.data2[0],
              city: this.data2[1],
              district: this.data2[2],
              address: this.text,
              // picUrls: formdata,
              // video_url: this.video,
              picUrls: this.tp == undefined ? [] : this.tp,
              video_url: this.video[0] == undefined ? "" : this.video[0],
              latitude: sessionStorage.getItem("lat"),
              longitude: sessionStorage.getItem("lnt"),
              remark: this.ly,
              districtId: this.region,
              dbsSerialNumber: this.sb.dbsSerialNumber,
            };
            axios({
              headers: {
                "Content-Type": "application/json",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              data: datas,
              method: "post",
              transformRequest: [
                function (data) {
                  // 对 data 进行任意转换处理
                  return JSON.stringify(datas);
                },
              ],
              url: this.reurl + "/app/repair/submitRequest",
            })
              .then((res) => {
                this.loadding = false;
                if (res.data.errcode == 30000) {
                  Toast(res.data.errmsg);
                  this.loadding = false;
                  this.yjtj = res.data.item;
                } else if (res.data.errcode == 40000) {
                  this.$router.push("/login");
                  Toast("登录失效请重新登陆");
                } else if (res.data.errcode == 0) {
                  this.loadding = false;
                  this.$router.push("/Tjcg");
                } else if (res.data.errcode == 40019) {
                  this.loadding = false;
                  Toast(res.data.errmsg);
                }
              })
              .catch((error) => {
                // error.data);
              });
          }
        }
      }
    },
    provinces(value) {
      this.regions(2, value);
      this.data3 = [];
      this.text = "";
    },
    citys(value) {
      this.regions(3, value);
    },
    regionss() {
      this.text =
        this.$refs.provinces.displayTitle +
        this.$refs.citys.displayTitle +
        this.$refs.regions.displayTitle;
    },
    //获取基础地图接口
    regions() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=10" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/repair/region",
      }).then((res) => {
        this.data1 = res.data.item;
        // let result = res.data.item.map((item) => ({
        //   text: item.areaName,
        //   value: item.id,
        // }));
        // if (a == 1) {
        //   this.data1 = result;
        // } else if (a == 2) {
        //   this.data2 = result;
        // } else {
        //   this.data3 = result;
        // }
      });
    },
    //图片上传
    afterRead(file) {
      this.show = true;
      this.no = true;
      // 此时可以自行将文件上传至服务器
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/upload/uploadAvatarImg" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      const formdata = new FormData();
      formdata.append("file", file.file);
      axios({
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        data: formdata,
        method: "post",
        url: this.reurl + "/app/upload/uploadTroubleImg",
      }).then((res) => {
        this.show = false;
        this.tp.push(res.data.item);
        this.no = false;
      });
    },
    del() {
      this.sp = 0;
    },
    //视频上传
    getVideo() {
      this.no = true;
      this.video = [];
      this.show = true;
      let taht = this;
      //获取上传文件标签
      let filesId = document.getElementById("pop_video");
      //获取音频标签
      let videoId = document.getElementById("videoId");
      //把当前files[0]传给getFileURL方法， getFileURL方法对其做一处理
      let url = this.getFileURL(filesId.files[0]);
      if (url) {
        //给video标签设置src
        videoId.src = url;
      }
      let formData = new FormData();
      formData.append("file", filesId.files);
      this.sp = 1;
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/upload/uploadAvatarImg" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      const formdata = new FormData();
      formdata.append("file", filesId.files[0]);
      axios({
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        data: formdata,
        method: "post",
        url: this.reurl + "/app/upload/uploadTroubleVideo",
      }).then((res) => {
        taht.show = false;
        taht.no = false;
        this.video.push(res.data.item);
      });
    },
    getFileURL(file) {
      let getUrl = null;

      if (window.createObjectURL != undefined) {
        //basic
        getUrl = window.createObjectURL(file);
      } else if (window.URL != undefined) {
        //window.URL 标准定义
        //mozilla(firefox)
        //获取一个http格式的url路径，这个时候就可以设置<img>中的显示
        getUrl = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) {
        //window.webkitURL是webkit的内核
        //webkit or chrome
        getUrl = window.webkitURL.createObjectURL(file);
      }
      return getUrl;
      //video 标签的 duration属性，获取当前视频的长度
      // let duration = videoId.duration
      // if (Math.floor(duration) > 60) {
      // that.layer.msg('视频不能大于60秒')
      // }
    },
    //图片删除
    beforeDelete(file) {
      // if(file.file.name.includes(this.tp)){
      //  111);
      // }
      // if (this.tp.indexOf(file.file.name)) {
      //  1);
      // this.tp.remove(file.file.name);
      // }
      //  this.tp);
    },
    //地图当前定位
    dqwz() {
      // //获取当前经纬度
      // let that = this;
      // var map = new AMap.Map("container", {
      //   resizeEnable: true,
      // });
      // AMap.plugin("AMap.Geolocation", function () {
      //   var geolocation = new AMap.Geolocation({
      //     enableHighAccuracy: false, //是否使用高精度定位，默认:true
      //     timeout: 1000, //超过10秒后停止定位，默认：5s
      //     buttonPosition: "RB", //定位按钮的停靠位置
      //     buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
      //     zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
      //   });
      //   map.addControl(geolocation);
      //   geolocation.getCurrentPosition(function (status, result) {
      //     if (status == "complete") {
      //     } else {
      //     }
      //   });
      // });
      // //解析定位结果
      // function onComplete(data) {
      //   localStorage.setItem("newmap", data.formattedAddress);
      //   that.lng = data.position.lng;
      //   that.lat = data.position.lat;
      // }
    },
    //地图坐标转换
    // zbzh() {
    //   let that = this;
    //   var map = new AMap.Map("container", {
    //     resizeEnable: true,

    //   });

    // var  geocoder = new AMap.Geocoder({
    //     city: "", //城市设为北京，默认：“全国”
    //   })

    //   // var marker = new AMap.Marker();

    //   function geoCode() {
    //     var address = that.text;
    //     geocoder.getLocation(address, function (status, result) {
    //       if (status === "complete" && result.geocodes.length) {
    //         that.lnglat = result.geocodes[0].location;
    //         that.result = result.geocodes[0].addressComponent;
    //       } else {
    //         log.error("根据地址查询位置失败");
    //       }
    //     });
    //   }
    //   geoCode();
    // },
    //删除电话
    deldh() {
      this.tel = "";
    },
    //删除地址
    deldz() {
      this.zbzh();
    },
    //删除联系
    dellx() {
      this.name = "";
    },
    //返回上一级
    back() {
      //this.$router.push("/sblist");
      this.$router.go(-1);
    },

    subbtn() {
      if (this.value1 == 2) {
        Toast("请选择服务类型");
      } else {
        this.tk = true;
        this.gaode(this.text);
      }
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.zbzh();
    // }, 2000);
  },
};
</script>

<style lang="less" scoped>
  .mask{
    #tk{
      h4{
        margin-bottom: 24px;
      }
      border-radius: 5px;
      .sbtn{
        text-align: center;
        div{
          line-height: 32px;
          border-radius: 5px;
          width: 120px;
        }
        .two{
          background: #fccc00;
        }
        .frist{
          border: 1px solid #E5E5E5;
        }
        display: flex;
        justify-content: space-around;
        margin-top: 10px;
      }
      .tips{
        font-size: 14px;
      }
      padding: 15px;
      line-height: 26px;
      border-radius: none;
      .sure{
        font-size: 16px;
      }
      h4{
        text-align: center;
        font-weight: bold;
        font-size: 18px;
      }
      position: absolute;
      background: #f5f5f5;
      width: 300px;
      left: 50%;
      transform: translate(-50%,100%);
    }
    position: fixed;
    top: 0;left:0; bottom: 0;right: 0;;
    background: rgba(0, 0, 0, 0.4);
  }
.loadding {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2022;
}
.fwlx {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 20px;
}
.tsk {
  box-sizing: border-box;
}
.loding {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.spic {
  position: absolute;
  right: 5px;
  top: 15px;
  z-index: 99;
}
.upLoad {
  position: relative;
  box-sizing: border-box;
}
#videoId {
  height: 90px;
  width: 90px;
}
.tb2 {
  width: 22px;
  height: 23px;
  position: absolute;
  z-index: 999;
  right: -8px;
  top: 12px;
}
.tb {
  width: 12px;
  height: 13px;
  position: absolute;
  z-index: 999;
  right: -5px;
  top: 15px;
}
.dw {
  position: relative;
}
.yjtj {
  border: 2px solid #fccc00;
  padding: 10px;
}
.cl {
  .ipt {
    display: none;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
  }
  .ipt + span {
    width: 18px;
    height: 18px;
    border: 1px solid #fccc00;
    display: inline-block;
    border-radius: 50%;
  }
  .ipt:checked + span {
    background-color: #fccc00;
  }
  .ipt:checked + span:after {
    content: "\2714"; /*这是html特殊字符的css编码*/
    color: #fff !important;
    text-align: center;
    line-height: 18px;
    font-size: 13px; /*用字号来调整对勾的大小*/
  }
  .ipt:checked + span {
    position: relative;
  }
}
.yjbx {
  .subbtn {
    height: 50px;
    background: #fccc00;
    border-radius: 10px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    margin-top: 10px;
  }
  .gzpic {
    .ly {
      box-sizing: border-box;
      width: 100%;
      height: 100px;
      border-radius: 10px;
      background: #f5f5f5;
      border: none;
      padding: 10px;
      font-size: 14px;
      text-indent: 10px;
    }
    margin-top: 20px;
    border-radius: 10px;
    background: #fff;
    padding: 10px;
  }
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  .main {
    background: #f5f5f5;
    .cbsb {
      max-height: 360px;
      overflow: auto;
      margin-top: 10px;
      background: #fff;
      border-radius: 10px;
      padding: 10px;
      .cbcon {
        margin-top: 20px;
        display: flex;
        .cright {
          flex: 1;
          .cbtm {
            display: flex;
            justify-content: space-between;
            .cl {
              margin-top: 23px;
            }
          }
        }
        .cleft {
          margin-right: 10px;
          .cimg {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
    padding: 0px 10px;
    .serch {
      .lserch {
        border-radius: 5px;
        background: rgba(0, 0, 0, 0.04);
        border-radius: 10px;
      }
    }
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .c9b {
    color: #9b9b9b;
  }
  .fwb {
    font-weight: bold;
  }
  .fwber {
    font-weight: bolder;
  }
  .fz12 {
    font-size: 12px;
  }
  .fz14 {
    font-size: 14px;
  }
  .fz18 {
    font-size: 18px;
  }
  .fz20 {
    font-size: 20px;
  }
  .lh20 {
    line-height: 20px;
  }
  .mt20 {
    margin-top: 20px;
  }
  /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    //border: 1px solid #eee;
    border-radius: 10px;
  }
  /deep/.van-dropdown-menu__item {
    height: 30px;
    border: 1px solid #eee;
    border-radius: 10px;
  }
}
</style>