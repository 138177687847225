<template>
  <div id="xges">
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" id="close_page" />
      </div>
      <h1 id="close_page" style="font-weight: bold">选购二手</h1>
    </div>
    
        <iframe src="https://www.eci-metro.cn/list/index/used-equipment" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      history.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
#xges{
    width: 100%;
    height: 100%;
}
iframe{
height: 100%;
    width: 100%;
}
.header {
  margin-bottom: 20px;
  background: #fff;
  line-height: 44px;
  font-weight: 500;
  height: 44px;
  width: 100%;
  text-align: center;
  .back {
    position: absolute;
    font-size: 24px;
    color: #fff;
    left: 15px;
    top: 3px;
    .pic {
      height: 22px;
      width: 22px;
    }
  }
}
</style>