<template>
  <div>
    <div class="main">
      <div class="waterfall">
        <div class="wal">
          <div
            v-for="(item, index) in firstHalf"
            :key="index"
            :class="index % 2 == 0 ? 'fal' : 'far'"
            @click="rmsp(item)"
          >
            <img
              :src="item.defaultImageUrl"
              :class="index % 2 == 0 ? 'fallimgl' : 'fallimgr'"
            />
            <p>{{ item.title }}</p>
            <div class="cred">￥{{ item.maxPrice }}</div>
          </div>
        </div>
        <div class="war">
          <div
            v-for="(item, index) in secondHalf"
            :key="index"
            :class="index % 2 != 0 ? 'fal' : 'far'"
            @click="rmsp(item)"
          >
            <img
              :src="item.defaultImageUrl"
              :class="index % 2 != 0 ? 'fallimgl' : 'fallimgr'"
            />
            <p>{{ item.title }}</p>
            <div class="cred">￥{{ item.maxPrice }}</div>
          </div>
        </div>
      </div>
       <div class="scrollH" >{{ tip }}</div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import axios from "axios";
export default {
  data() {
    return {
      furl: "http://customerapp.eci-metro.cn/ecim",
      firstHalf: [],
      secondHalf: [],
      tick: "",
      tip: "正在加载中...",
    };
  },
  methods: {
    watercon(a, b, c) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        method: "get",
        url: this.furl + "/app/index/waterfallList/" + a + "/" + b + "/" + c,
      }).then((res) => {
        // 请求的列表数据
        let data = res.data.item;
        if (data.length == 0) {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.tip = "已没有更多商品...";
        } else {
          var result = [];
          this.page += 1;
          for (var i = 0; i < data.length; i += 10) {
            result.push(data.slice(i, i + 10));
          }
          let arr = result[0];
          let arr1 = result[1];
          // // 如果是第一页需手动置空列表
          if (this.page.num === 1) {
            this.secondHalf = [];
            this.firstHalf = [];
          }
          // // 把请求到的数据添加到列表
          this.firstHalf = this.firstHalf.concat(arr);
          this.secondHalf = this.secondHalf.concat(arr1);
        }
      });
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        url: this.furl + "/user/weimob/appTicket",
      }).then((res) => {
        this.tick = res.data.item.appTicket;
        localStorage.setItem("sxsc", res.data.item.appTicket);
      });
    },
    //最新到货
    rmsp(e) {
      let shop =
        "https://100001527049.retail.n.weimob.com/saas/retail/100001527049/0/goods/detail/vwif?id=" +
        e.goodsId +
        "&recommendType=-1&storeId=2507353049&appClientId=F485C8EB319A1D768A5212A3DEE30ADA&appTicket=" +
        this.tick;
      //this.$router.push("/zxsc");
      this.$router.push({
        path: "/zxsc",
        query: {
          item: shop,
        },
      });
    },
  },
  created() {
    this.watercon(-2, 1, 20);
  },
};
</script>

<style lang="less" scoped>
.scrollH{
  text-align: center;
  line-height: 60px;
  font-size: 12px;
}
.cred {
  color: red;
  text-indent: 20px;
}
.fal {
  width: 100%;
  height: 220px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
  p {
    font-size: 14px;
    text-indent: 15px;
    line-height: 30px;
    overflow: hidden;
  }
  box-sizing: border-box;
}
.far {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  height: 260px;
  margin-bottom: 10px;
  p {
    font-size: 14px;
    text-indent: 15px;
    line-height: 40px;
    overflow: hidden;
  }
}
.fallimgl {
  width: 100%;
  height: 158px;
  border-radius: 10px 10px 0 0;
}
.fallimgr {
  width: 100%;
  height: 188px;
  border-radius: 10px 10px 0 0;
}

.main {
  .waterfall {
    .wal {
      margin-right: 10px;
      width: 50%;
    }
    .war {
      width: 50%;
    }
    display: flex;
    justify-content: space-between;
  }
  padding: 10px;
  margin-top: 10px;
}
.edition {
  .tips {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    flex-direction: column;
    width: 70%;
    margin-left: 15%;
    border-radius: 15px;
    height: 350px;
    position: absolute;
    top: 130px;
    padding: 40px;
    box-sizing: border-box;
    background-image: url(../assets/img/tip.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  z-index: 9999;
  box-sizing: border-box;
}
</style>