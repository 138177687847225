 <template>
  <div class="wxsp">
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>
      <span class="v4">帮助视频</span>
      <!-- <input class="search" placeholder="请输入关键词" /> -->
    </div>
    <!-- 猜你喜欢 -->
    <div class="like">
      <img src="@/assets/img/fi.png" class="fi" />
      猜你喜欢
    </div>
    <!-- 轮播图 -->
    <div class="tit">
      <div class="about">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <!-- 一下循环 -->
            <div class="swiper-slide">
              <img
                :src="picurl + ban[0].picUrl"
                alt=""
                @click="next(ban[0].videoUrl)"
              />
              <div class="abs">{{ ban[0].videoName }}</div>
            </div>
            <div class="swiper-slide">
              <img
                :src="picurl + ban[1].picUrl"
                alt=""
                @click="next(ban[1].videoUrl)"
              />
              <div class="abs">{{ ban[1].videoName }}</div>
            </div>
            <div class="swiper-slide">
              <img
                :src="picurl + ban[2].picUrl"
                alt=""
                @click="next(ban[2].videoUrl)"
              />
              <div class="abs">{{ ban[2].videoName }}</div>
            </div>
            <div class="swiper-slide">
              <img
                :src="picurl + ban[3].picUrl"
                alt=""
                @click="next(ban[3].videoUrl)"
              />
              <div class="abs">{{ ban[3].videoName }}</div>
            </div>
            <div class="swiper-slide">
              <img
                :src="picurl + ban[4].picUrl"
                alt=""
                @click="next(ban[4].videoUrl)"
              />
              <div class="abs">{{ ban[4].videoName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="mtit" @click="btn">
        <div class="pad" @click="czsp" :class="num == 1 ? 'bor' : ''">
          操作视频
        </div>
        <div class="pad" @click="bysp" :class="num == 2 ? 'bor' : ''">
          维修视频
        </div>
        <div class="pad" @click="wxsp" :class="num == 3 ? 'bor' : ''">
          保养视频
        </div>
      </div>
    </div>
    <!-- 操作视频 -->
    <div class="con">
      <div
        class="video"
        v-for="(item, index) in cz"
        :key="index"
        @click="look(item)"
      >
        <img :src="item.picUrl == null ? videoImg : picurl + item.picUrl" />
        <div class="tst">{{ item.videoName }}</div>
      </div>
    </div>
    <div class="newmask" v-if="show">
      <van-loading type="spinner" color="rgb(255, 205, 17)" class="mmaskpic" />
    </div>
  </div>
</template>

<script>
import qxpng from "../../assets/img/qx.png";
import Swiper from "swiper";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import Vue from "vue";
import { Loading } from "vant";
import Tips from "@/components/Tips.vue";
Vue.use(Loading);
export default {
  name: "About",
  components: {
    Tips,
  },
  data() {
    return {
      videoImg: qxpng,
      loding: true,
      pic: false,
      con: true,
      wxs: false,
      bys: false,
      czs: true,
      num: 1,
      img: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:",
      url: "http://10.208.109.253:9100",
      cz: [],
      wx: [],
      by: [],
      ban: [],
      show: false,
    };
  },
  created() {
    this.jcsj(1);
    this.ban = JSON.parse(sessionStorage.getItem("liks"));
  },
  methods: {
    //点击发送
    save(a) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        url:
          this.reurl +
          "/app/services/saveUserVisitVideo?deviceVideoId="+a,
      }).then((res) => {
        console.log(res);
      });
    },
    jcsj(a) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100/app/services/saveServiceRecord" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          //"Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        url:
          this.reurl +
          "/app/services/listPageDeviceVideo?name=&type=" +
          a +
          "&page=1&pageSize=100",
      }).then((res) => {
        this.cz = res.data.item.data;
      });
    },
    //保养视频
    bysp() {
      this.num = 2;
      this.jcsj(2);
    },
    //维修视频
    wxsp() {
      this.num = 3;
      this.jcsj(3);
    },
    //操作水平
    czsp() {
      this.num = 1;
      this.jcsj(1);
    },
    back() {
      this.$router.push("/index");
    },
    next(e) {
      this.$router.push({
        path: "/look",
        query: {
          item: e,
        },
      });
    },
    look(e) {
      this.save(e.id);
      this.$router.push({
        path: "/look",
        query: {
          item: e.videoUrl,
        },
      });
    },
    btn() {
      if (this.num == 1) {
        this.czs = true;
        this.bys = false;
        this.wxs = false;
      } else if (this.num == 2) {
        this.czs = false;
        this.bys = true;
        this.wxs = false;
      } else {
        this.czs = false;
        this.bys = false;
        this.wxs = true;
      }
    },
    onSwiper(swiper) {},
    onSlideChange() {},
  },
  mounted() {
    var mySwiper = new Swiper(".swiper-container", {
      effect: "coverflow",
      slidesPerView: 3,
      centeredSlides: true,
      coverflowEffect: {
        rotate: 30,
        stretch: 10,
        depth: 60,
        modifier: 2,
        slideShadows: true,
      },
      initialSlide: 2,
    });
    // if (location.href.indexOf("#wxsp") == -1) {
    //   location.href = location.href + "#wxsp";
    //   location.reload();
    // }
    //this.$router.go(0)
    //window.location.reload()
  },
};
</script>

<style lang="less" scoped>
.swiper-slide {
  position: relative;
  .abs {
    width: 150px;
    text-indent: 10px;
    position: absolute;
    bottom: 0;
    line-height: 20px;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
  }
  img {
    height: 180px;
    width: 150px;
  }
}
.con {
  flex: 1;
  background: #eee;
  display: flex;
  text-align: center;
  line-height: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  .video {
    width: 40%;
    height: 170px;
    padding: 10px;
    border-radius: 10px;
    background: #fff;
    .tst {
      text-align: left;
      overflow: hidden;
    }
    margin-bottom: 10px;
  }
  img {
    height: 130px;
    width: 130px;
  }
}
.newmask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .mmaskpic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
  }
}
.jzz {
  position: absolute;
  left: 50%;
  top: 50%;
}
.sub {
  font-size: 13px;
  font-weight: bold;
}
.fi {
  vertical-align: text-bottom;
}
.like {
  margin: 0 10px;
  // background: #fccb00;
  border-radius: 10px;
  margin-bottom: 10px;
  line-height: 30px;
  padding-left: 20px;
  font-weight: bold;
  font-size: 15px;
}
.search {
  width: 240px;
  height: 30px;
  font-size: 14px;
  color: #999999;
  border-radius: 5px;
  background: rgba(216, 216, 216, 0.45);
  font-weight: 500;
  line-height: 30px;
  padding-left: 15px;
  margin: 10px;
  outline: none;
  border: none;
}
.vname {
  font-size: 14px;
  text-align: center;
  padding-top: 135px;
}
.acc {
  position: relative;
  height: 170px;
  width: 100%;
  background-image: url(../../assets/img/qx.png);
  background-size: 100% 100%;
}
.wxsp {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  box-sizing: border-box;
  .wx {
    background: #fff;
  }
  height: 100%;
  .zw {
    width: 100%;
    height: 375px;
  }
  .ctit {
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .main {
    .mtit {
      // background: #FFF6CE;
      // border-radius: 10px;
      .pad {
        padding: 10px;
      }
      .bor {
        // background: #fccb00;
        // border-radius: 10px;
        line-height: 30px;
        font-size: 14px;
        font-weight: bold;
        padding: 0 20px;
        border-bottom: 2px solid #fccb00;
      }
      display: flex;
      justify-content: space-around;
    }
    padding: 10px;
  }
  .tit {
    height: 180px;
    width: 100%;
  }
  .header {
    background: #fff;
    text-align: center;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    padding-bottom: 10px;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 8px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
}
.ctit {
  background: #fccc00;
  line-height: 25px;
  border-radius: 5px;
  font-weight: 500;
  padding: 0 15px;
}
.fz14 {
  font-size: 12px;
  color: #fff;
}
.mr10 {
  margin-right: 10px;
}
.go {
  margin-top: 6px;
}
.simg {
  width: 100%;
  height: 120px;
}
.name {
  color: black;
}

.v4 {
  font-size: 16px;
}
</style>