import { render, staticRenderFns } from "./Nchen.vue?vue&type=template&id=50b77491&scoped=true&"
import script from "./Nchen.vue?vue&type=script&lang=js&"
export * from "./Nchen.vue?vue&type=script&lang=js&"
import style0 from "./Nchen.vue?vue&type=style&index=0&id=50b77491&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b77491",
  null
  
)

export default component.exports