<template>
  <div>
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" id="close_page" />
      </div>
      <h1 id="close_page" style="font-weight: bold">最新活动</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
  created() {
    this.url = this.$route.query.item;
    this.create();
    this.setStorage();
  },
  methods: {
    create() {
      //创建窗口对象
      var shop = plus.webview.create(this.url, "shop", {
        top: "44px",
        bottom: 0,
      });
      //显示窗口对象
      shop.show();
    },
    back() {
      // this.$router.push("/index");
      //获取id对象窗口页面并销毁
      var ac = plus.webview.getWebviewById("shop");
      plus.webview.close(ac);
      //销毁获取到的窗口对象
      setTimeout(() => {
        this.$router.push("/index");
      }, 500);
      //获取所有窗口页面并且给进行销毁处理
    },
    setStorage() {
      plus.storage.setItem("stoken", localStorage.getItem("token"));
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 20px;
  background: #fff;
  line-height: 44px;
  font-weight: 500;
  height: 44px;
  width: 100%;
  text-align: center;
  .back {
    position: absolute;
    font-size: 24px;
    color: #fff;
    left: 15px;
    top: 3px;
    .pic {
      height: 22px;
      width: 22px;
    }
  }
}
</style>