<template>
  <div class="yjtx">
          <Tips />
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" alt />
      </div>预警提示
    </div>

    <img src="@/assets/img/zw.png" :class="data.length==0?'show':'none'" />
    <!-- <img class="kf" src="@/assets/img/kefu.png" alt /> -->
   <div class="fmin">
      <div class="con" v-for="(item,i) in data" :key="i">
      <div></div>
      <div class="ctit fwb">{{item.type}}预警</div>
      <div class="cmain fz14">{{item.cnDescription}}</div>
      <div class="cfooter fz12 c99">预警时间： {{item.timeStamp | dateformat('MM月DD日 HH:MM')}}</div>
    </div>
   </div>
  </div>
</template>

<script>
import moment from "moment";
import md5 from "js-md5";
import axios from "axios";
import Tips from "@/components/Tips.vue";
export default {
   components: {
    Tips,
  },
  data() {
    return {
      img: "http://customerapp.eci-metro.cn/ecim", // "http://10.208.120.107:",
      data: [],
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100",
      show: false,
      yj: [],
    };
  },
  created() {
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    this.toDay = this.$moment(new Date()).format("YYYY-MM-DD");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.120.107:9100//app/device/catDiagnostic/FJM00503?page=1&pageSize=10&isRead=2" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "multipart/form-data;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      url:
        this.reurl +
        "/app/device/catDiagnostic/"+this.$route.query.item.replace(/\s*/g,"")+"?page=1&pageSize=10&isRead=2",
    })
      .then((res) => {
        this.data = res.data.item.data;
        if (res.data.errcode == 40000) {
          this.$router.push("/login");
          Toast("登录失效请重新登陆");
        }
      })
      .catch((error) => {
        // error.data);
      });
  },
  methods: {
    //预警提醒
    yd() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      this.toDay = this.$moment(new Date()).format("YYYY-MM-DD");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.120.107:9100//app/device/catDiagnostic/FJM00503?page=1&pageSize=10&isRead=2" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      //CVA
      axios({
        headers: {
          "Content-Type": "multipart/form-data;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        url:
          this.reurl +
          "/app/device/readCatDiagnostic/"+this.data[0].serialNumber
      })
        .then((res) => {
          if (res.data.errcode == 40000) {
            this.$router.push("/login");
            Toast("登录失效请重新登陆");
          }
        })
        .catch((error) => {
          // error.data);
        });
    },
    //返回我的设备
    back() {
      this.$router.push("/wdsb");
    },
  },
  destroyed(){
    this.yd();
  }
};
</script>

<style lang="less" scoped>
.none {
  display: none;
}
.y {
  position: absolute;
  right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
}
.con {
  position: relative;
  margin: 10px 10px;
  margin-bottom: 10px;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  line-height: 30px;
}
.yjtx {
  display: flex;
  flex-direction: column;
  .fmin{
    flex: 1;
  }
  height: 100%;
  background: #fff;
  .red {
    color: red;
    font-weight: bold;
  }
  .time {
    font-size: 14px;
  }
  .h1 {
    display: flex;
    justify-content: space-between;
  }
  .htit {
    margin-left: 10px;
    font-size: 12px;
    color: #999999;
    font-weight: 500;
  }
  .show {
    width: 100%;
    width: 100%;
    margin-top: 20px;
  }
  .box {
    margin-top: 10px;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
  }
  .mask {
    position: absolute;
    top: 44px;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f5f5f5;
    padding: 10px;
  }
  .header {
    background: #fff;
    line-height: 44px;
    font-weight: bold;
    height: 44px;
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #f5f5f5;
    .back {
      position: absolute;
      font-size: 24px;
      color: #fff;
      left: 15px;
      top: 3px;
      .pic {
        height: 22px;
        width: 22px;
      }
    }
  }
  .kf {
    position: fixed;
    bottom: 50px;
    right: 20px;
  }
  .fz14 {
    font-size: 14px;
  }
  .fz12 {
    font-size: 12px;
  }
  .fwb {
    font-weight: bold;
  }
  .lh25 {
    line-height: 25px;
  }
  .mr10 {
    margin: 10px 0;
  }
  .c99 {
    color: #999999;
  }
  .mt10 {
    margin-top: 10px;
  }
}
</style>