<template>
  <div class="index">
    <!-- 搜索 -->
    <div class="search2">
      <div class="search" @click="search">
        <div style="margin-right: 10px; weight: 100%">
          <van-icon name="search" />搜索关键字
        </div>
      </div>
      <!-- 小铃铛 -->
      <div class="ld" v-show="true">
        <img
          src="@/assets/img/xztx.png"
          @click="xxzx"
          style="height: 24px; margin-right: 10px"
        />
        <span v-show="num == 1">{{ xxtx }}</span>
      </div>
    </div>
    <!-- 轮播内容 -->
    <div class="header" @scroll="getScroll($event)" ref="main">
      <!--  -->
      <!-- 轮播图 -->
      <div>
        <van-swipe :autoplay="10000">
          <van-swipe-item v-for="(image, index) in banner" :key="index">
            <img
              v-lazy="picurl + image.url"
              :style="pm"
              class="simg"
              @click="add(image)"
            />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 快捷导航 -->
      <div class="nav">
        <div
          class="box"
          @click="gxh(item.identification)"
          v-for="(item, index) in box"
          :key="index"
        >
          <img :src="picurl + item.icon" alt />
          <p class="btxt">{{ item.name }}</p>
        </div>
      </div>
      <!-- 资讯中心轮播 -->
      <div class="real">
        <div
          @click="read(image)"
          class="realbox"
          v-for="(image, index) in real"
          :key="index"
        >
          <img :src="image.newsImageUrl" class="realimg" />
          <p class="newsTitle">{{ image.newsTitle }}</p>
        </div>
        <!-- <van-swipe :loop="false" :autoplay="3000">
          <van-swipe-item  style="width:300px" v-for="(image, index) in real" :key="index">
            <img
              v-lazy="image.newsImageUrl"
              style="height: 140px"
              class="simgs"
              @click="add(image)"
            />
          </van-swipe-item>
        </van-swipe> -->
      </div>
      <!-- 故障提示 -->
      <div class="tips" @click="gzts" v-show="item == '1'">
        <van-notice-bar
          left-icon="volume-o"
          background="rgba(252, 204, 0, 0.04)"
          color="red"
          text="设备预警有您未查看讯息，请前往设备提醒模块查看"
        />
      </div>
      <!-- 内容导航 -->
      <div class="con" :class="scrollnum == true ? 'scrool' : ''" ref="con">
        <div
          :class="conactive == index ? 'conactive' : ''"
          @click="water(item, index)"
          v-for="(item, index) in waterfallCategory"
          :key="index"
        >
          {{ item.name }}
        </div>
        <!-- <div>新品到货</div>
        <div>热销商品</div> -->
      </div>
      <!-- 内容 -->
      <div class="main" v-show="conactive == 0">
        <div class="waterfall">
          <div class="wal">
            <div
              v-for="(item, index) in firstHalf"
              :key="index"
              :class="index % 2 == 0 ? 'fal' : 'far'"
              @click="rmsp(item)"
            >
              <img
                :src="item.defaultImageUrl"
                :class="index % 2 == 0 ? 'fallimgl' : 'fallimgr'"
              />
              <p class="watetit">{{ item.title }}</p>
              <div class="cred">￥{{ item.maxPrice }}</div>
            </div>
          </div>
          <div class="war">
            <div
              v-for="(item, index) in secondHalf"
              :key="index"
              :class="index % 2 != 0 ? 'fal' : 'far'"
              @click="rmsp(item)"
            >
              <img
                :src="item.defaultImageUrl"
                :class="index % 2 != 0 ? 'fallimgl' : 'fallimgr'"
              />
              <p class="watetit">{{ item.title }}</p>
              <div class="cred">￥{{ item.maxPrice }}</div>
            </div>
          </div>
        </div>
        <div class="scrollH" v-show="scrollH">{{ tip }}</div>
      </div>
      <!-- 内容 -->
      <div class="main" v-show="conactive == 1">
        <div class="waterfall">
          <div class="wal">
            <div
              v-for="(item, index) in firstHalf1"
              :key="index"
              :class="index % 2 == 0 ? 'fal' : 'far'"
              @click="rmsp(item)"
            >
              <img
                :src="item.defaultImageUrl"
                :class="index % 2 == 0 ? 'fallimgl' : 'fallimgr'"
              />
              <p class="watetit">{{ item.title }}</p>
              <div class="cred">￥{{ item.maxPrice }}</div>
            </div>
          </div>
          <div class="war">
            <div
              v-for="(item, index) in secondHalf1"
              :key="index"
              :class="index % 2 != 0 ? 'fal' : 'far'"
              @click="rmsp(item)"
            >
              <img
                :src="item.defaultImageUrl"
                :class="index % 2 != 0 ? 'fallimgl' : 'fallimgr'"
              />
              <p class="watetit">{{ item.title }}</p>
              <div class="cred">￥{{ item.maxPrice }}</div>
            </div>
          </div>
        </div>
        <div class="scrollH" v-show="scrollH">{{ tip }}</div>
      </div>
      <!-- <keep-alive>
        <components :is="conName" ref="child"></components>
      </keep-alive> -->
    </div>
    <!-- 底部导航 -->
    <div class="footer">
      <div class="lbox" style="margin-bottom: 10px">
        <div class="sbox">
          <img src="@/assets/img/n2.png" alt />
          <p class="fz12">首页</p>
        </div>
        <div class="sbox" @click="zxsc" v-show="true">
          <img src="@/assets/img/n5.png" alt />
          <p class="fz12">在线商城</p>
        </div>
        <div class="sbox" @click="zxkf" v-show="true">
          <img src="@/assets/img/n4.png" alt />
          <p class="fz12">在线客服</p>
        </div>
        <div
          class="sbox"
          :class="zxtx.length == 0 ? 'zxzx' : ''"
          @click="zxzx"
          v-show="true"
        >
          <img src="@/assets/img/n1.png" alt />
          <p class="fz12">资讯中心</p>
          <div class="zxtx"></div>
        </div>
        <div class="sbox" @click="my">
          <img src="@/assets/img/n3.png" alt />
          <p class="fz12">我的</p>
        </div>
      </div>
    </div>
    <!-- 检测更新 -->
    <div class="edition" v-show="edition">
      <div class="tips">
        <div class="tiptit">
          <span class="fz20 snum">{{ editions }}</span> 全新版本升级
        </div>
        <!-- <div class="mbox">{{ describe }}</div> -->
        <div class="mbox" v-html="describe"></div>
        <div style="text-align: center">
          <div class="leve" @click="leve">立即升级</div>
          <div class="mt20" v-show="forceUpdate == 0" @click="wait">
            下次再说
          </div>
        </div>
        <!-- <div class="dell">
          <img src="../assets/img/deell.png" @click="edition = false />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import MescrollVue from "mescroll.js/mescroll.vue";
import ban1 from "@/assets/img/ban1.png";
import ban2 from "@/assets/img/ban2.png";
import ban3 from "@/assets/img/APPr.png";
import Vue from "vue";
import md5 from "js-md5";
import axios from "axios";
import qs from "qs";
import { Swipe, SwipeItem } from "vant";
import { Lazyload } from "vant";
import { Icon } from "vant";
import { NoticeBar } from "vant";
import { CountDown } from "vant";
import { Toast } from "vant";
import Left from "../components/Left.vue";
import Right from "../components/Right.vue";
Vue.use(Toast);
Vue.use(CountDown);
Vue.use(NoticeBar);
Vue.use(Icon);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
export default {
  components: {
    MescrollVue, // 注册mescroll组件
    Left,
    Right,
  },
  data() {
    return {
      platform: "",
      real: [],
      pm: "height:200px",
      conName: "Left",
      isFixed: false,
      editions: "",
      conactive: 0,
      waterfallCategory: [],
      edition: false,
      xxtx: "11",
      info: "",
      images: [ban3],
      item: "",
      zxdh: [],
      rxsp: [],
      tick: "",
      zxtx: [],
      num: 0,
      banner: [],
      show: true,
      box: [],
      permission: [],
      fileUrl: "",
      describe: "",
      infodbs: "",
      mescroll: null, // mescroll实例对象
      mescrollUp: {
        // 上拉加载的配置.
        callback: this.upCallback, // 上拉回调,此处简写; 相当于 callback: function(page, mescroll) { }
        //以下是一些常用的配置,当然不写也可以的.
        page: {
          num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
          size: 8, //每页数据条数,默认10
        },
        htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        noMoreSize: 5,
      },
      dataList: [], // 列表数据
      pblNum: 0,
      firstHalf: [],
      secondHalf: [],
      firstHalf1: [],
      secondHalf1: [],
      scrollnum: false,
      scrollH: false,
      page: 1,
      bg:0,
      tip: "已没有更多商品...",
      scrollTop: 0,
      forceUpdate: 1,
      upgrade: "",
    };
  },
  methods: {
    wait() {
      localStorage.setItem("upgrade", this.upgrade);
      this.edition = false;
       sessionStorage.setItem("biaog",1);
      //存储值 更具值来促发判断
    }, //点击资讯中心
    read(e) {
      localStorage.setItem("zx", JSON.stringify(e));
      this.$router.push({
        path: "/zxxq",
        query: {
          item: e,
        },
      });
    },
    //资讯中心
    zxzxs() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/sendSms" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {
        page: 1,
        pageSize: 4,
      };
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/news/listPageNews?newsTitle=",
      }).then((res) => {
        this.real = res.data.item.data;
      });
    },
    //猜你喜欢
    likes() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        url:
          this.reurl +
          "/app/services/guessLikeItPageDeviceVideo?type=0&page=1&pageSize=5",
      }).then((res) => {
        sessionStorage.setItem("liks", JSON.stringify(res.data.item.data));
      });
    },
    //上翻
    getScroll(event) {
      // this.scrollTop = event.target.scrollTop;
      let scrollBottom =
        event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight;
      if (event.target.scrollTop >= 288) {
        this.scrollnum = true;
      } else {
        this.scrollnum = false;
      }
      if (scrollBottom == 0) {
        this.tip = "已没有更多商品...";
      }
      // if (scrollBottom <= 277 && scrollBottom != 0) {
      // } else if (scrollBottom == 0 ) {
      //   // this.watercon(this.pblNum, this.page, 8);
      // } else if(event.target.scrollTop<=260) {
      //    this.scrollnum = false;
      // }
    },
    //点击下载
    leve() {
      this.edition = false;
      setTimeout(() => {}, 1000);
      if (this.platform == "ios") {
        window.location.href =
          "https://apps.apple.com/us/app/%E6%98%93%E5%88%9D%E6%98%8E%E9%80%9A-%E5%AE%A2%E6%88%B7%E8%BD%AF%E4%BB%B6/id1598843356";
      } else {
        var dtask = plus.downloader.createDownload(
          this.fileUrl,
          {},
          function (d, status) {
            // 下载完成
            if (status == 200) {
              plus.runtime.install(d.filename);
            } else {
              alert("Download failed: " + status);
            }
          }
        );
        dtask.start();
      }
    },
    //获取ticket
    ticket(a,b) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        url: this.reurl + "/user/weimob/appTicket?province=" + a + "&city=" + b,
      }).then((res) => {
        this.tick = res.data.item.appTicket;
        localStorage.setItem("sxsc", res.data.item.appTicket);
      });
    },
    //版本获取接口
    version(a) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        url: this.reurl + "/app/version/" + a,
      }).then((res) => {
        this.upgrade = res.data.item.version;
        this.upgrade== localStorage.getItem("upgrade")?this.edition=false:this.edition=true;
        this.describe = res.data.item.describe;
        this.fileUrl = res.data.item.fileUrl;
        this.forceUpdate = res.data.item.forceUpdate;
        this.platform = res.data.item.platform;
      });
    },
    //上传省市区
    province(a, b) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        method: "get",
        url: this.reurl + "/app/index?province=" + a + "&city=" + b,
      }).then((res) => {});
    },
    //banner
    bannerList() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        // transformRequest: [
        //   function (data) {
        //     // 对 data 进行任意转换处理
        //     return JSON.stringify(datas);
        //   },
        // ],
        url: this.reurl + "/app/index/banner",
      }).then((res) => {
        this.banner = res.data.item;
      });
    },
    //点击瀑布流
    water(a, b) {
      //   // let activeCard = this.$refs.main;
      //   // activeCard.parentElement.scrollTo(0, this.scrollTop);
      //   if (this.scrollTop >= 288) {
      //     this.scrollnum = true;
      //   } else {
      //     this.scrollnum = false;
      //   }
      // });
      // if (a.value == -1) {
      //   this.conName = "Left";
      // }
      // if (a.value == -2) {
      //   this.conName = "Right";
      // }
      // this.mescrollUp.page.num = 0;
      this.conactive = b;
      // this.$refs.main.scrollTop(0,this.scrollTop);
    },
    //获取内容
    watercon(a, b, c) {
      this.scrollH = true;
      this.scrollnum = false;
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        method: "get",
        url: this.reurl + "/app/index/waterfallList/" + a + "/" + b + "/" + c,
      }).then((res) => {
        // 请求的列表数据
        let data = res.data.item;
        // data.splice(0,2)
        if (data.length == 0) {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.tip = "正在加载中...";
        } else {
          var result = [];
          for (var i = 0; i < data.length; i += Math.floor(data.length / 2)) {
            result.push(data.slice(i, i + Math.floor(data.length / 2)));
          }
          let arr = result[0];
          let arr1 = result[1];
          // // 把请求到的数据添加到列表
          this.firstHalf = arr;
          this.secondHalf = arr1;
          this.tip = "已没有更多商品...";
        }
      });
    },
    //获取内容1
    watercon1(a, b, c) {
      this.scrollH = true;
      this.scrollnum = false;
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        method: "get",
        url: this.reurl + "/app/index/waterfallList/" + a + "/" + b + "/" + c,
      }).then((res) => {
        // 请求的列表数据
        let data = res.data.item;
        if (data.length == 0) {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.tip = "已没有更多商品...";
        } else {
          var result = [];
          for (var i = 0; i < data.length; i += Math.floor(data.length / 2)) {
            result.push(data.slice(i, i + Math.floor(data.length / 2)));
          }
          let arr = result[0];
          let arr1 = result[1];
          // 把请求到的数据添加到列表

          // this.firstHalf1 = this.firstHalf.concat(arr);
          this.firstHalf1 = arr;
          // this.secondHalf1 = this.secondHalf.concat(arr1);
          this.secondHalf1 = arr1;
        }
      });
    },
    //瀑布流分类
    pul() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        // body: {
        //   params: datas,
        // },
        method: "get",
        // transformRequest: [
        //   function (data) {
        //     // 对 data 进行任意转换处理
        //     return JSON.stringify(datas);
        //   },
        // ],
        url: this.reurl + "/app/index/waterfallCategory",
      }).then((res) => {
        for (let key in res.data.item) {
          //将遍历出的对象和属性转换成数组，push到数组中
          this.waterfallCategory.push({
            value: key,
            name: res.data.item[key],
          });
        }
        this.pblNum = this.waterfallCategory[0].value;
      });
    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll; // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },
    upCallback(page, mescroll) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        method: "get",
        url:
          this.reurl +
          "/app/index/waterfallList/" +
          this.pblNum +
          "/" +
          page.num +
          "/" +
          8,
      })
        .then((res) => {
          // 请求的列表数据
          let data = res.data.item;
          if (data.length == 0) {
            // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
            mescroll.endErr();
          } else {
            var result = [];
            for (var i = 0; i < data.length; i += 4) {
              result.push(data.slice(i, i + 4));
            }
            let arr = result[0];
            let arr1 = result[1];
            // // 如果是第一页需手动置空列表
            if (page.num === 1) {
              this.secondHalf = [];
              this.firstHalf = [];
            }
            // // 把请求到的数据添加到列表
            this.firstHalf = this.firstHalf.concat(arr);
            this.secondHalf = this.secondHalf.concat(arr1);
            // // 数据渲染成功后,隐藏下拉刷新的状态
            this.$nextTick(() => {
              // mescroll.endSuccess(this.secondHalf.length);
              // mescroll.endSuccess(this.firstHalf.length);
              mescroll.endSuccess();
              mescroll.endSuccess();
            });
          }
        })
        .catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          mescroll.endErr();
        });
    },
    //在线统计接口
    only(a, b) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/updateUserActive?province=" + a + "&city=" + b,
      }).then((res) => {});
    },
    //个推接口
    gtpush(a, b) {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = { pushId: a, platformType: b };
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "post",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/updateUserPushId",
      }).then((res) => {
        this.abc = res.data;
      });
    },
    //导航栏
    gxh(e) {
      if (e == "/jdcx") {
        //Toast("该功能暂未开放");
         this.$router.push(e);
      } else if (e == "/yjbx") {
        //Toast("该功能暂未开放");
        this.$router.push(e);
      } else if (e == "dbsid") {
        this.$router.push("/zskf?item=" + this.infodbs);
      } else if (e == "/my") {
        if (this.infodbs != "") {
          this.$router.push(e);
        } else {
          Toast("您还暂无信贷信息");
        }
      } else if (e == "/ljgm" || e =="/rmxj") {
        //this.$router.push("/vx");
        Toast("暂未开放");
      } else {
        this.$router.push(e);
      }
    },
    //info
    useinfo() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo/v2" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/user/userInfo/v2",
      }).then((res) => {
        this.infodbs = res.data.item.dbsId;
        sessionStorage.setItem("dbsId",res.data.item.dbsId)
      });
    },
    //banner
    add(e) {
      if (e.link != null) {
        this.$router.push({
          path: "/banner",
          query: {
            item: e.link,
          },
        });
      }
    },
    //消息中心
    xxzx() {
      this.$router.push("/xxzx");
    },
    //热门商品
    rmsp(e) {
      let shop =
        "https://100001527049.retail.n.weimob.com/saas/retail/100001527049/0/goods/detail/vwif?id=" +
        e.goodsId +
        "&recommendType=-1&storeId=2507353049&appClientId=F485C8EB319A1D768A5212A3DEE30ADA&appTicket=" +
        this.tick;
Toast("暂未开放");
      //this.$router.push("/zxsc");
      // this.$router.push({
      //   path: "/zxsc",
      //   query: {
      //     item: shop,
      //   },
      // });
    },
    //咨询中心
    zxzx() {
      this.$router.push("/zxzx");
    },
    //最新到货
    xpdh(e) {
      //this.$router.push("/zxsc");
      let shop =
        "https://100001527049.retail.n.weimob.com/saas/retail/100001527049/0/goods/detail/vwif?id=" +
        e.goodsId +
        "&recommendType=-1&storeId=2507353049&appClientId=F485C8EB319A1D768A5212A3DEE30ADA&appTicket=" +
        this.tick;
        Toast("暂未开放");
      //this.$router.push("/zxsc");
      // this.$router.push({
      //   path: "/zxsc",
      //   query: {
      //     item: shop,
      //   },
      // });
    },

    //在线商城
    zxsc() {
      // this.$router.push("/kefu");
      Toast("暂未开放");
    },
    //附近门店
    fjmd() {
      this.$router.push("/fjmd");
    },
    //点击搜索
    search() {
      this.$router.push("/search");
      // history.go(0);
    },
    //一键报修
    yjbx() {
      this.$router.push("/yjbx");
    },
    //点击我的设备
    wdsb() {
      this.$router.push("/wdsb");
    },
    //信贷中心
    xdzx() {
      this.$router.push("/my");
    },
    //点击我的
    my() {
      this.$router.push("/my");
    },
    //在线客服
    zxkf() {
      this.$router.push("/cssc");
    },

    //进度查询
    jdcx() {
      //this.$router.push("/jdcx");
      Toast("系统连接中，即将开放");
    },
    //维修视频
    wdsp() {
      this.$router.push("/wxsp");
    },
    //故障提示
    gzts() {
      //跳转我的设备
      this.$router.push("/wdsb");
    },
    //地图当前定位
    dqwz() {
      //获取当前经纬度
      let that = this;
      var map = new AMap.Map("container", {
        resizeEnable: true,
      });
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
        });
        map.addControl(geolocation);
        geolocation.getCurrentPosition(function (status, result) {
          if (status == "complete") {
            onComplete(result);
          } else {
          }
        });
      });
      //解析定位结果
      function onComplete(data) {
        localStorage.setItem("themap", data.formattedAddress);
        if (data.formattedAddress == undefined) {
          Toast("位置信息获取失败");
        } else {
          // that.ticket(data.addressComponent.province,
          //   data.addressComponent.city)
          that.only(data.addressComponent.province, data.addressComponent.city);
          localStorage.setItem("province",data.addressComponent.province);
          localStorage.setItem("city",data.addressComponent.city)
          that.province(
            data.addressComponent.province,
            data.addressComponent.city
          );
        }
      }
    },
    wdsz() {
      let token = localStorage.getItem("token");
      let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
      let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
      let nonce = md5(
        "http://10.208.110.178:9100/user/userInfo" +
          timestamp +
          Math.round(Math.random() * 8)
      );
      var dbs = "";
      let sign = [
        "token=" + token,
        "timestamp=" + timestamp,
        "nonce=" + nonce,
        "secret=" + secret,
      ];
      function fn(sign) {
        return Array.from(new Set(sign)).sort();
      }
      let sj = md5(fn(sign).join("&"));
      let datas = {};
      axios({
        headers: {
          "Content-Type": "application/json;",
          token: token,
          timestamp: timestamp,
          nonce: nonce,
          sign: sj,
        },
        body: {
          params: datas,
        },
        method: "get",
        transformRequest: [
          function (data) {
            // 对 data 进行任意转换处理
            return JSON.stringify(datas);
          },
        ],
        url: this.reurl + "/app/userPersonalise/getPickPersonaliseListV2",
      }).then((res) => {
        if (res.data.errcode != 0) {
          // Toast("请求失败，稍后重试");
        } else {
          //let b = [];
          // for (let index = 0; index <script res.data.item.length; index++) {
          //   b.push(res.data.item[index]);
          // }
          this.box = res.data.item;
        }
      });
    },
  },
  created() {
    if(sessionStorage.getItem("biaog")){
      
    }else{
      // 每次版本号更新
      localStorage.setItem("upgrade",2.23);
    };
    if (this.$route.query.item == 1) {
      setTimeout(() => {
        this.wdsz();
      }, 1000);
    }
    this.zxzxs();
    this.likes();
    this.pm = "height:" + document.body.clientWidth / 2 + "px";
    //获取商品数据
    this.watercon(-1, 1, 20);
    this.watercon1(-2, 1, 20);
    //ticked
    this.ticket(localStorage.getItem("province"),localStorage.getItem("city"));
    //获取版本号接口
    // this.version("ios");
    //获取banner接口
    this.bannerList();
    //获取瀑布流接口
    this.pul();
    //检测版本号更新
    //在线接口
    // this.only();
    setTimeout(() => {
      this.dqwz();
    }, 1000 * 60);
    //获取设备厂商
    setTimeout(() => {
      this.Iphopne = plus.device.vendor;
      this.con = plus.push.getClientInfo();
      if (this.Iphopne.length == 5) {
        this.gtpush(this.con.clientid, 2);
        this.version("ios");
      } else {
        this.gtpush(this.con.clientid, 1);
        this.version("android");
      }
    }, 1000);
    this.useinfo();
    this.permission = JSON.parse(localStorage.getItem("permission"));
    this.wdsz();

    localStorage.setItem("acc", localStorage.getItem("token"));

    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/userInfo" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    var dbs = "";
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/app/device/getWarningTips",
    }).then((res) => {
      if (res.data.errcode == 40000) {
        localStorage.clear();
        this.$router.push("/star");
      }
      this.item = res.data.item;
    });
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/app/news/countUnRead",
    }).then((res) => {
      if (res.data.item == 0) {
        this.zxtx = res.data.item;
      } else {
        //this.zxtx = res.item.data;
      }
    });
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/app/push/getUnreadCount",
    }).then((res) => {
      this.xxtx = res.data.item;
      if (this.xxtx > 0) {
        this.num = 1;
      }
    });
  },
  mounted() {
    this.dqwz();
  },
  update() {
    // if (this.upgrade == localStorage.getItem("upgrade")) {
    //   this.edition = false;
    //   console.log(this.upgrade == localStorage.getItem("upgrade"));
    // } else {
    //   this.edition = true;
    //   console.log(this.upgrade, 111);
    // }
  },
};
</script>

<style lang="less" scoped>
.watetit {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
}
.newsTitle {
  white-space: nowrap;
  text-align: left;
  text-indent: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
  line-height: 20px;
}
::-webkit-scrollbar {
  width: 0px;
}
.real {
  .realbox {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
  }
  .realimg {
    margin-bottom: 10px;
    border-radius: 10px;
    width: 230px;
    height: 115px;
  }
  padding: 10px;
  display: flex;
  overflow: auto;
}
.scrollH {
  line-height: 60px;
  text-align: center;
  font-size: 12px;
  background-attachment: red;
}
// .test {
//   .cbox {
//     height: 300px;
//     width: 100%;
//     background: #fccc00;
//     margin-bottom: 20px;
//   }
//   height: 100%;
//   background: #999999;
// }
.scrool {
  margin-bottom: 10px;
  position: absolute;
  top: 45px;
  width: 100%;
  div {
    margin-right: 20px;
  }
  display: flex;
  justify-content: space-around;
  line-height: 40px;
  background: #fff;
  .conactive {
    border-bottom: #fccc00 2px solid;
    font-weight: bold;
  }
}
.search2 {
  position: relative;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search {
  width: 100%;
  height: 30px;
  font-size: 14px;
  color: #999999;
  border-radius: 5px;
  background: rgba(216, 216, 216, 0.45);
  //background: red;
  line-height: 30px;
  padding-left: 10px;
  margin: 10px;
  // position: absolute;
  // top: 20px;
  // width: 320px;
  // left: 50%;
  // margin-left: -170px;
}
.snum {
  border-bottom: 3px solid black;
}
.mbox {
  height: 80px;
  overflow: auto;
  font-size: 14px;
  line-height: 24px;
}
.fz12 {
  font-size: 12px;
}
.dell {
  position: absolute;
  bottom: -70px;
  margin-left: 30%;
  img {
    width: 34px;
  }
}
.leve {
  line-height: 40px;
  background: #fff;
  border-radius: 20px;
  font-weight: bold;
}
.mt20 {
  margin-top: 20px;
}
.fz20 {
  font-size: 40px;
}
.con {
  div {
    margin-right: 20px;
  }
  display: flex;
  justify-content: space-around;
  line-height: 40px;
  background: #fff;
  .conactive {
    border-bottom: #fccc00 2px solid;
    font-weight: bold;
  }
  z-index: 10000;
}
.cred {
  color: red;
  text-indent: 20px;
  text-align: left;
  height: 20px !important;
}
.fal {
  text-align: center;
  width: 100%;
  height: 220px;
  margin-bottom: 10px;
  background: #fff;
  border-radius: 10px;
  p {
    // font-size: 14px;
    text-indent: 15px;
    line-height: 30px;
    overflow: hidden;
    text-align: left;
  }
  box-sizing: border-box;
}
.far {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  height: 260px;
  margin-bottom: 10px;
  p {
    text-align: left;
    // font-size: 14px;
    text-indent: 15px;
    line-height: 40px;
    overflow: hidden;
  }
}
.fallimgl {
  width: 138px;
  height: 138px;
  border-radius: 10px 10px 0 0;
}
.fallimgr {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 148px;
  height: 148px;
  border-radius: 10px 10px 0 0;
}

.main {
  .waterfall {
    .wal {
      width: 48.5%;
    }
    .war {
      width: 48.5%;
    }
    display: flex;
    justify-content: space-between;
  }
  padding: 10px;
  margin-top: 10px;
}
.edition {
  .tips {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    flex-direction: column;
    width: 70%;
    margin-left: 15%;
    border-radius: 15px;
    height: 350px;
    position: absolute;
    top: 130px;
    padding: 40px;
    box-sizing: border-box;
    background-image: url(../assets/img/tip.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  z-index: 9999;
  box-sizing: border-box;
}
.none {
  display: none;
}
.newmask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .mmaskpic {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
  }
}
.ld {
  position: relative;
  span {
    line-height: 16px;
    position: absolute;
    z-index: 99;
    background: red;
    color: rgb(255, 205, 17);
    top: 0;
    right: 5px;
    font-size: 12px;
    width: 16px;
    height: 16px;
    text-align: center;
    padding: 3px;
    padding: 0px;
    border-radius: 50%;
  }
}

.picmin {
  font-weight: bold;
  color: #f25643;
  margin-left: 10px;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}
.pictit {
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  width: 90px;
  height: 40px;
  white-space: normal;
  overflow: hidden;
}
.zxzx {
  .zxtx {
    position: absolute;
    border-radius: 50%;
    background: red;
    width: 10px;
    height: 10px;
    color: #fff;
    top: -3px;
    right: 8px;
  }
  position: relative;
}
.ddc {
  width: 80px !important;
  height: 80px !important;
}
.min {
  color: #f25643;
}
.mr20 {
  margin-left: 5px;
  font-weight: bold;
  font-size: 14px;
}
.price {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
}
.index {
  display: flex;
  flex-direction: column;
  height: 100%;
  .footer {
    background: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    .lbox {
      .sbox {
        text-align: center;
        img {
          width: 24px;
          height: 24px;
        }
      }
      display: flex;
      padding: 10px;
      justify-content: space-around;
      align-items: flex-end;
    }
  }

  .header {
    margin-bottom: 54px;
    white-space: nowrap;
    height: 100%;
    background: #f5f5f5;
    .tips {
      margin-top: 10px;
      border: 1px solid #fccc00;
      margin: 0 10px;
      border-radius: 20px;
    }
    .nav {
      .box {
        img {
          width: 44px;
          height: 44px;
        }
        .btxt {
          color: #333333;
          height: 20px;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 12px;
        margin: 10px 20px;
        align-items: center;
      }
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }

    .simg {
      height: 200px;
      width: 100%;
    }
    width: 100%;
    overflow: auto;
  }
  .fz12 {
    font-size: 12px;
  }
  .cf {
    color: #fff;
  }
  .my-swipe .van-swipe-item {
    text-align: center;
    background-color: #fff;
  }
  .gege {
    height: 10px;
    width: 100%;
    background: #fff;
  }
  .colon {
    display: inline-block;
    margin: 0 4px;
    color: rgb(255, 205, 17);
  }
  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: rgb(255, 205, 17);
  }
  .fwb {
    font-weight: bold;
  }
}
</style>