<template>
  <div>
    <div class="header">
      <div class="back" @click="back">
        <img class="pic" src="@/assets/img/mback.png" id="close_page" />
      </div>
      <h1 id="close_page">在线商城</h1>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.create(this.$route.query.item);
    this.setStorage();
  },
  methods: {
    create(shop) {
      //创建窗口对象
      var shop = plus.webview.create(
        shop ,
        "shop",
        {
          top: "44px",
          bottom: 0,
        }
      );
      //创建窗口对象
      shop.show();
    },
    back() {
      // this.$router.push("/index");

      //获取id相对应的BOM对象模型
      var ac = plus.webview.getWebviewById("shop");
      //强行销毁获取到的BOM对象模型
      plus.webview.close(ac);
      //销毁BOM后设置定时器进行路由跳转到index
      setTimeout(() => {
        this.$router.push("/index");
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  margin-bottom: 20px;
  background: #fff;
  line-height: 44px;
  font-weight: 500;
  height: 44px;
  width: 100%;
  text-align: center;
  .back {
    position: absolute;
    font-size: 24px;
    color: #fff;
    left: 15px;
    top: 3px;
    .pic {
      height: 22px;
      width: 22px;
    }
  }
}
</style>