<template>
  <div class="search">
    <Tips />
    <div class="mask">
      <div class="header">
        <img class="pic" src="@/assets/img/mback.png" @click="back" />
        <div class="ss">
          <img src="@/assets/img/ss.png" class="fdj" @click="search" />
          <input type="serch" class="ipt" v-model="ipt" placeholder="请输入关键词" />
        </div>
        <p @click="back">取消</p>
      </div>
      <div class="main">
        <!-- 搜索内容 -->
        <div class="scon" v-show="isshow">
          <!-- 搜索tit -->
          <div class="stit c99">
            <div class="pd" @click="num=1" :class="num==1?'brt':''" v-show="item.myDevice!=0">设备信息</div>
            <!-- <div -->
              <!-- class="pd" -->
              <!-- @click="num=2" -->
              <!-- :class="num==2?'brt':''" -->
              <!-- v-show="item.serviceProgress!=0" -->
            <!-- >服务进度</div> -->
            <div
              class="pd"
              @click="num=3"
              :class="num==3?'brt':''"
              v-show="item.creditCenter!=0"
            >信贷中心</div>
            <div
              class="pd"
              @click="num=4"
              :class="num==4?'brt':''"
              v-show="item.repairVideo!=0"
            >视频中心</div>
            <div class="pd" @click="num=5" :class="num==5?'brt':''" v-show="item.newsCount!=0">资讯中心</div>
            <div class="pd" @click="num=6" :class="num==6?'brt':''" v-show="item.goodsList!=0">产品中心</div>
          </div>
          <!-- 显示内容 -->
          <div class="xscon">
            <!-- 设备信息 -->
            <div class="smain" v-show="num==1">
              <!-- <img src="../assets/img/zw.png" class="ypic" v-if="item.myDevice==0" /> -->
              <div class="mb15" v-show="item.myDevice!=0" v-for="(item,index) in sblb" :key="index">
                <p class="lh30 fz12">设备名称：{{item.makeName}}{{item.model}}{{item.name}}</p>
                <p class="lh30 fz12">购买时间：{{item.purchaseTime}}</p>
                <div class="sb">
                  <div class="sbl">
                    <img src="@/assets/img/cs.jpg" class="pic" />
                  </div>
                  <div class="sbr">
                    <div class="sbrt">
                      <div class="sbbox" @click="yybg2(item.assetID)">
                        <img src="@/assets/img/yx.png" class="sbpic" />
                        <p>设备信息</p>
                      </div>
                      <div class="sbbox mr10" @click="cva2(item)">
                        <img src="@/assets/img/cva.png" class="sbpic" />
                        <p>CVA&EPP</p>
                      </div>
                      <div class="sbbox" @click="yjtx2(item)">
                        <img src="@/assets/img/yj.png" class="sbpic" />
                        <p>预警提醒</p>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- 信贷中心 -->
            <div class="smain" v-show="num==3">
              <!-- <img src="../assets/img/zw.png" class="ypic" v-if="item.creditCenter==0" /> -->
              <div
                class="mt20"
                v-show="item.creditCenter!=0"
                v-for="(item,index) in xdcx"
                :key="index"
              >
                <div class="jdt">
                  <div class="jdtit">
                    <div class="fz12">贷款合同：{{item.contractNo}}</div>
                    <div class="fz12">
                      贷款金额：
                      <span>
                        ￥
                        {{Number(item.contractAmount)
                        .toFixed(2)
                        .toString()
                        .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,")}}
                      </span>
                    </div>
                  </div>
                  <!-- <van-progress :percentage="50" color="#F8DD58" /> -->
                </div>

                <!-- 还款 -->
                <div class="hkjh" @click="xdzx2(item,1)">
                  <div class="hl">
                    <div class="hl1">
                      <img src="@/assets/img/hk1.png" alt />
                    </div>
                    <div class="hl2 ml15">还款计划</div>
                  </div>
                  <div class="hr">
                    <img src="@/assets/img/backr.png" alt />
                  </div>
                </div>
                <!-- 信贷记录 -->
                <div class="hkjh" @click="xdzx2(item,2)">
                  <div class="hl mt10">
                    <div class="hl1">
                      <img src="@/assets/img/hk2.png" alt />
                    </div>
                    <div class="hl2 ml15">信贷记录</div>
                  </div>
                  <div class="hr">
                    <img src="@/assets/img/backr.png" alt />
                  </div>
                </div>
              </div>
            </div>
            <!-- 视频中心 -->
            <div class="smain" v-show="num==4">
              <!-- <img src="../assets/img/zw.png" class="ypic" v-show="item.repairVideo==0" /> -->
              <div
                @click="look2(item)"
                class="ssp"
                v-show="item.repairVideo!=0"
                v-for="(item, index) in wxsp"
                :key="index"
              >
                <img src="@/assets/img/cs.jpg" class="vpic" />
                <div class="spr">
                  <div>{{item.videoName}}</div>
                  <div>播放{{item.num/1}}次</div>
                  <div>时长：{{item.videoTime/60}}分钟</div>
                </div>
              </div>
            </div>
            <!-- 资讯中心 -->
            <div class="smain" v-show="num==5">
              <div id="main" v-for="(item,index) in zxzx" :key="index">
                <div class="frist" @click="frist(item)">
                  <img
                    :src="item.newsImageUrl"
                    :class="item.newsChildList.length==0?'txt':'txt2'"
                    class="zximg"
                  />
                  <div class="ft">{{item.newsTitle}}</div>
                </div>
                <div class="two" @click="two(e)" v-for="(e,k) in item.newsChildList" :key="k">
                  <div>{{e.title}}</div>
                  <img :src="e.imageUrl" class="tpic" />
                </div>
              </div>
            </div>
            <!-- 产品中心 -->
            <div class="smain" v-show="num==6">
              <div class="cpzx" @click="shop(item)" v-for="(item,index) in goos" :key="index">
                <img :src="item.defaultImageUrl" />
                <div class="cpr">
                  <div>{{item.title}}</div>
                  <div class="cjg">¥{{item.maxPrice}}</div>
                </div>
              </div>
            </div>
          </div>
          <img src="../assets/img/zw.png" class="ypic" v-if="acc==0" />
        </div>
        <!-- 热门推荐 -->
        <div v-show="show">
          <p class="tit">热门搜索</p>
          <div class="con">
            <div class="box" @click="xgzl">
              <img src="@/assets/img/fire.png" />
              <p class="ml10 fz14">个人资料</p>
            </div>
            <div class="box" @click="wdsb">
              <img src="@/assets/img/fire.png" />
              <p class="ml10 fz14">我的设备</p>
            </div>
            <div class="box" @click="yjbx">
              <img src="@/assets/img/fire.png" />
              <p class="ml10 fz14">一键报修</p>
            </div>
          </div>
          <!-- <div class="con">
            <div class="box" @click="xdzx">
              <img src="@/assets/img/fire.png" />
              <p class="ml10 fz14">信贷中心</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5";
import axios from "axios";
import Vue from "vue";
import { Search } from "vant";
import { Tabs } from "vant";
import { Toast } from "vant";
import { Loading } from "vant";
import Tips from "@/components/Tips.vue";
Vue.use(Tabs);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(Search);
export default {
  components: {
    Tips,
  },
  data() {
    return {
      num: 3,
      img: "http://customerapp.eci-metro.cn/ecim", // "http://10.208.120.107:",
      url: "http://10.208.109.253:9100",
      furl: "http://customerapp.eci-metro.cn/ecim", //"http://10.208.120.107:9100",
      ipt: "",
      item: {},
      isshow: false,
      show: true,
      sblb: [],
      fwjd: [],
      wxsp: [],
      xdcx: [],
      dbsId: "",
      loding: false,
      acc: 0,
      goos: [],
      zxzx: [],
    };
  },
  methods: {
    //商品跳转
    shop(e) {
      localStorage.getItem('sxsc')
      let shop =
        "https://100001527049.retail.n.weimob.com/saas/retail/100001527049/0/goods/detail/vwif?id=" +
        e.goodsId +
        "&recommendType=-1&storeId=2507353049&appClientId=F485C8EB319A1D768A5212A3DEE30ADA&appTicket=" +
        localStorage.getItem('sxsc');
      //this.$router.push("/zxsc");
      this.$router.push({
        path: "/zxsc",
        query: {
          item: shop,
        },
      });
    },
    //资讯中心
    frist(e) {
      this.$router.push({
        path: "/zxxq",
        query: {
          item: e,
        },
      });
    },
    two(e) {
      this.$router.push({
        path: "/zxq",
        query: {
          item: e,
        },
      });
    },
    //视频跳转
    look2(e) {
      console.log(e);
      this.$router.push({
        path: "/look",
        query: {
          item: e.videoUrl,
        },
      });
    },
    //信贷中心
    xdzx2(e, b) {
      localStorage.setItem("b", b);
      this.$router.push({
        path: "/xdzx",
        query: {
          item: e,
        },
      });
      console.log(e, b);
    },
    //预警提醒
    yjtx2(item) {
      this.$router.push({
        path: "/yjtx",
        query: {
          item: item.assetID,
        },
      });
    },
    //cva
    cva2(item) {
      this.$router.push({
        path: "/cva",
        query: {
          item: item,
        },
      });
    },
    //设备信息
    yybg2(e) {
      this.$router.push({
        path: "/yybg",
        query: {
          item: e,
        },
      });
    },
    //搜索
    search() {
      // this.item = {
      //   myDevice: 1,
      //   repairVideo: 1,
      //   creditCenter: 1,
      //   serviceProgress: 1,
      // };
      (this.sblb = []), (this.fwjd = []), (this.xdcx = []), (this.wxsp = []);
      if (this.ipt.length != 0) { 
        this.loding = true;
        //this.num = 1;
        this.show = false;
        let token = localStorage.getItem("token");
        let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
        let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
        let nonce = md5(
          "http://10.208.120.107:9100/app/device/deviceListV2?page=1&pageSize=10" +
            timestamp +
            Math.round(Math.random() * 8)
        );
        let sign = [
          "token=" + token,
          "timestamp=" + timestamp,
          "nonce=" + nonce,
          "secret=" + secret,
        ];
        function fn(sign) {
          return Array.from(new Set(sign)).sort();
        }
        let sj = md5(fn(sign).join("&"));
        let datas = {};
        let acc = {
          page: 1,
          pageSize: 99,
        };
        axios({
          headers: {
            "Content-Type": "multipart/form-data",
            token: token,
            timestamp: timestamp,
            nonce: nonce,
            sign: sj,
          },
          method: "get",
          url:
            this.reurl +
            "/app/global/getGlobalSearchResult?keyWords=" +
            this.ipt,
        }).then((res) => {
          if (res.data.item.item.creditCenter != 0) {
            //信贷中心
            this.num = 3;
          } else if (res.data.item.item.myDevice != 0) {
            this.num = 1;
          } else if (res.data.item.item.repairVideo != 0) {
            this.num = 4;
          } else if (res.data.item.item.serviceProgress != 0) {
            this.num = 2;
          } else if (res.data.item.item.goodsList != 0) {
            this.num = 6;
          } else if (res.data.item.item.newsCount != 0) {
            this.num = 5;
          }
          this.isshow = true;
          this.acc =
            res.data.item.item.creditCenter +
            res.data.item.item.myDevice +
            res.data.item.item.repairVideo +
            res.data.item.item.newsCount +
            res.data.item.item.goodsList +
            res.data.item.item.serviceProgress;
          this.item = res.data.item.item;
          //设备列表接口
          if (this.item.myDevice != 0) {
            axios({
              headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              method: "get",
              url:
                this.reurl +
                "/app/device/deviceListV2?page=1&pageSize=20&keyWords=" +
                this.ipt,
            }).then((res) => {
              this.sblb = res.data.item.data;
            });
          }
          //服务进度接口
          if (this.item.serviceProgress != 0) {
            axios({
              headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              method: "get",
              url:
                this.reurl +
                "/app/services/listPageServiceRecord?page=1&pageSize=30&keyWords=" +
                this.ipt +
                "&serviceType=0",
            }).then((res) => {
              this.fwjd = res.data.item.data;
            });
          }
          //信贷查询接口
          if (this.item.creditCenter != 0) {
            axios({
              headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              method: "get",
              url:
                this.reurl +
                "/app/loan/contracts/" +
                this.dbsId +
                "?keyWords=" +
                this.ipt,
            }).then((res) => {
              this.xdcx = res.data.item;
            });
          }
          //视频中心
          if (this.item.repairVideo != 0) {
            axios({
              headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              method: "get",
              url:
                this.reurl +
                "/app/services/listPageDeviceVideo?page=1&pageSize=50&name=" +
                this.ipt +
                "&type=0",
            }).then((res) => {
              this.wxsp = res.data.item.data;
            });
          }
          //商品
          if (this.item.goodsList != 0) {
            axios({
              headers: {
                "Content-Type": "multipart/form-data",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              method: "get",
              url:
                this.reurl +
                "/app/goods/getGoodsList?keyWords=" +
                this.ipt +
                "&page=" +
                1 +
                "&pageSize=" +
                99 +
                "&type=0",
            }).then((res) => {
              //this.wxsp = res.data.item.data;
              this.goos = res.data.item.data;
              //console.log(this.goos);
            });
          }
          //资讯中心
          if (this.item.newsCount != 0) {
            axios({
              headers: {
                "Content-Type": "application/json;",
                token: token,
                timestamp: timestamp,
                nonce: nonce,
                sign: sj,
              },
              body: {
                params: acc,
              },
              method: "post",
              transformRequest: [
                function (data) {
                  // 对 data 进行任意转换处理
                  return JSON.stringify(acc);
                },
              ],
              url: this.reurl + "/app/news/listPageNews?newsTitle=" + this.ipt,
            }).then((res) => {
              this.zxzx = res.data.item.data;
              console.log(this.zxzx);
            });
          }
        });
      } else {
        Toast("请输入关键词");
      }
    },
    //回到首页
    back() {
      this.$router.push("/index");
    },
    //个人资料
    xgzl() {
      this.$router.push("/xgzl");
    },
    //我的设备
    wdsb() {
      this.$router.push("/wdsb");
    },
    //一键报修
    yjbx() {
      this.$router.push("/yjbx");
    },
    //信贷中心
    xdzx() {
      this.$router.push("/my");
    },
  },
  updated() {
    console.log(this.num);
  },
  created() {
    let token = localStorage.getItem("token");
    let timestamp = this.$moment(new Date()).format("YYYYMMDDHHmmss");
    let secret = "*kLMJ8CEnl$liB6!arCG2mL4*g6nXc29";
    let nonce = md5(
      "http://10.208.110.178:9100/user/userInfo" +
        timestamp +
        Math.round(Math.random() * 8)
    );
    var dbs = "";
    let sign = [
      "token=" + token,
      "timestamp=" + timestamp,
      "nonce=" + nonce,
      "secret=" + secret,
    ];
    function fn(sign) {
      return Array.from(new Set(sign)).sort();
    }
    let sj = md5(fn(sign).join("&"));
    let datas = {};
    axios({
      headers: {
        "Content-Type": "application/json;",
        token: token,
        timestamp: timestamp,
        nonce: nonce,
        sign: sj,
      },
      body: {
        params: datas,
      },
      method: "get",
      transformRequest: [
        function (data) {
          // 对 data 进行任意转换处理
          return JSON.stringify(datas);
        },
      ],
      url: this.reurl + "/user/userInfo",
    }).then((res) => {
      if (res.data.errcode == 40000) {
        Toast("登录失效请重新登陆");
        this.$router.push("/login");
      }
      this.dbsId = res.data.item.dbsId;
    });
  },
};
</script>

<style lang="less" scoped>
#main {
  box-sizing: border-box;
  .frist {
    .ft {
      text-align: center;
      width: 300px;
      color: #ffffff;
      position: absolute;
      left: 50%;
      bottom: 20px;
      font-weight: 500;
      transform: translate(-50%);
    }
    position: relative;
  }
  .two {
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    background: #fff;
    .tpic {
      margin-left: 15px;
      width: 40px;
      height: 40px;
    }
  }
  .zximg {
    width: 100%;
    height: 150px;
  }
  padding: 10px;
}
.txt {
  border-radius: 10px;
}
.txt2 {
  border-radius: 10px 10px 0 0;
}
.cpzx {
  margin-bottom: 10px;
  img {
    width: 90px;
    height: 90px;
  }
  .cpr {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    margin-left: 15px;
    .cjg {
      text-align: right;
      font-size: 18px;
      color: #f25643;
      margin-top: 20px;
    }
  }
  align-items: center;
  justify-content: space-between;
  display: flex;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
}
.jzz {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
}
.ypic {
  width: 100%;
  height: 100%;
}
.search {
  height: 100%;
  background: #f5f5f5;
  .main {
    .smain {
      box-sizing: border-box;
      .sb {
        .sbr {
          .sbrt {
            .sbbox {
              .sbpic {
                margin-bottom: 6px;
              }
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              text-align: center;
            }
            display: flex;
            justify-content: space-between;
            p {
              font-size: 12px;
            }
          }
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
        .byb {
          text-align: center;
          line-height: 40px;
          padding: 0 20px;
          background: rgb(255, 205, 17) ;
          border-radius: 10px;
          font-weight: 520;
        }
        display: flex;
        justify-content: space-between;
        .pic {
          width: 90px;
          height: 90px;
        }
        margin-top: 10px;
      }
      color: black;
      border-radius: 10px;
      margin-top: 20px;
    }
    .scon {
      margin-bottom: 40px;
      .stit {
        display: flex;
        justify-content: space-around;
      }
    }
    .con {
      .box {
        padding: 3px 10px;
        background: #fff;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 5px;
      }
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    margin-top: 20px;
    padding: 10px;
    .tit {
      font-size: 14px;
      color: #999999;
    }
    background: #f5f5f5;
  }
  .header {
    box-sizing: border-box;
    .ss {
      position: relative;
      .ipt {
        text-indent: 10px;
        background: #f5f5f5;
        border: none;
        height: 30px;
        border-radius: 7px;
        width: 240px;
      }
      .fdj {
        width: 20px;
        margin-left: 20px;
        height: 20px;
        position: absolute;
        top: 13px;
        right: 8px;
      }
    }
    .pic {
      width: 22px;
      height: 22px;
    }
    padding: 3px 10px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    align-items: center;
    height: 44px;
  }
  .ml10 {
    margin-left: 5px;
  }
  .fz12 {
    font-size: 14px;
  }
  .fz14 {
    font-size: 14px;
  }
  .c99 {
    color: #999999;
  }
  .pd {
    padding: 8px 0px;
    font-size: 14px;
  }
  .mb15 {
    margin-bottom: 15px;
  }
  .brt {
    border-bottom: 2px solid rgb(255, 205, 17) ;
    font-weight: 500;
    color: black;
    font-size: 16px;
  }
  .lh30 {
    line-height: 30px;
  }
  .mr10 {
    margin: 0 20px;
  }
  .mb15 {
    margin-bottom: 15px;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
  }
}
.block {
  width: 100%;
}
.xcon {
  .last {
    .lbtn {
      margin-top: 10px;
      background: #fccc00;
      padding: 5px 15px;
      border-radius: 5px;
    }
    display: flex;
    justify-content: flex-end;
  }
  margin-bottom: 10px;
  .xfooter {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .xjd {
      width: 100px;
    }
  }
  .xcmin {
    .mright {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 10px;
    }
    .mleft {
      img {
        width: 90px;
        height: 90px;
      }
    }
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }
  padding: 10px;
  border-radius: 10px;
  .xctit {
    display: flex;
    justify-content: space-between;
  }
  background: #fff;
}
.fz16 {
  font-size: 16px;
  font-weight: bold;
}
.hr {
  padding-top: 5px;
}
.jdt {
  .jdtit {
    margin-bottom: 10px;
    line-height: 30px;
  }
  background: #fffdf5;
  border-radius: 5px;
  padding: 0 10px;
}
.hkjh {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mt20 {
  .hl {
    display: flex;
    align-items: center;
  }
  background: #fff;
  border-radius: 10px;
  padding: 10px;
}
.xdtit {
  font-weight: 540;
  margin-bottom: 10px;
}
.cf2 {
  font-size: 12;
  color: #f25643;
}
.ml15 {
  margin-left: 15px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mt20 {
  margin-bottom: 15px;
}
.ssp {
  .spr {
    font-size: 12px !important;
    margin-left: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .vpic {
    width: 180px;
    height: 100px;
  }
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 15px;
}
</style>